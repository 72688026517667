.form {
  background-color: $light-color;
  max-width: 494px;
  width: 100%;
  z-index: 220;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 30px;
  display: none;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(1);
  animation: myAnim 1s ease 0s 1 normal forwards;
  max-height: 95vh;
  overflow-y: auto;
  overscroll-behavior: none;
  padding: 25px clamp(1.25rem, -0.08rem + 5.008vw, 3.125rem);
  @keyframes myAnim {
    0% {
      transform: translate(-50%, -50%) scale(0.4);
    }
    100% {
      transform: translate(-50%, -50%) scale(1);
    }
  }
  &.show {
    display: block;
  }
  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    border-bottom: 1.8px solid $primary-color;
    padding-bottom: 15px;
    margin-bottom: 24px;
    h3 {
      font-size: 18px;
      line-height: 1.56;
      font-weight: 700;
      text-transform: uppercase;
    }
  }
  &-group {
    display: flex;
    flex-direction: column;
    gap: 4px;
    margin-bottom: 24px;
  }
  &-field {
    border-radius: 10px;
    padding: 10px 16px;
    border: 1.5px solid #ced4da;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    input {
      width: 100%;
      border: none;
      outline: none;
      background-color: transparent;
      font-size: 16px;
      line-height: 1.5;
      font-weight: 400;
    }
  }
  &-message {
    color: red;
    font-size: 12px;
    display: none;
    letter-spacing: 0.5px;
  }
  &-link {
    font-size: 16px;
    line-height: 1.5;
    color: $primary-color;
    font-weight: 500;
    text-decoration: underline;
    transition: opacity 0.2s ease;
    &:hover {
      opacity: 0.8;
    }
  }
  &-button {
    border-radius: 10px;
    padding: 9px 16px;
    background-color: $primary-color;
    color: $light-color;
    font-size: 16px;
    line-height: 1.5;
    font-weight: 500;
    width: 100%;
    max-width: 338px;
    transition: background-color 0.2s ease;
    margin: 24px auto;
    &:hover {
      background-color: #f19866;
    }
  }
  &-divider {
    font-size: 16px;
    line-height: 1.5;
    font-weight: 400;
    display: flex;
    align-items: center;
    p {
      white-space: nowrap;
      padding: 0 10px;
    }
    &-line {
      width: 45px;
      height: 1.5px;
      background-color: #cccc;
    }
  }
  &-account {
    font-size: 16px;
    line-height: 1.5;
    font-weight: 400;
    display: flex;
    align-items: center;
    gap: 5px;
  }
  .form-check {
    font-size: 16px;
    line-height: 1.5;
    font-weight: 400;
  }
  @include maxWidth(767px) {
    max-width: 100%;
    border-radius: unset;
    max-height: 100%;
    height: 100%;
  }
}
