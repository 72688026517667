.search-col {
  border: 1.5px solid $primary-color;
  border-radius: 5px;
  padding: 24px 20px;
  &-section:not(:last-child) {
    margin-bottom: 24px;
  }
  span {
    font-size: 16px;
    font-weight: 400;
  }
  &-header {
    display: flex;
    align-items: center;
    gap: 15px;
    justify-content: space-between;
    margin-bottom: 15px;
    padding-bottom: 15px;
    border-bottom: 2.5px solid $primary-color;
    user-select: none;
    cursor: pointer;
    h4 {
      font-size: 18px;
      color: $primary-color;
      font-weight: bold;
    }
  }
  &-main {
    position: relative;
    width: 100%;
    padding-right: 34px;
  }
  &-icon {
    right: 0;
    @include blockCenter("top");
  }
  &-content {
    &.active {
      display: block;
    }
  }
  .stars {
    display: flex;
    flex-direction: column;
    gap: 11px;
    .stars-label {
      display: flex;
      align-items: center;
      gap: 8px;
      .fa-solid {
        font-size: 18px;
        color: #fadb14;
      }
      .fa-regular {
        font-size: 18px;
        color: #ccc;
      }
    }
  }
  .price-range {
    &-values {
      display: flex;
      align-items: center;
      gap: 10px;
      justify-content: space-between;
      font-weight: 400;
      font-size: 12px !important;
    }
    &-input {
      height: 15px;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      width: 100%;
    }
    &-input::-webkit-slider-runnable-track {
      width: 100%;
      height: 5px;
      cursor: pointer;
      animate: 0.2s;
      box-shadow: 0px 0px 0px #000000;
      background: #ededed;
      border-radius: 50px;
      border: 0px solid $light-color;
    }
    &-input::-webkit-slider-thumb {
      box-shadow: 0px 0px 0px #000000;
      border: 3px solid $primary-color;
      height: 18px;
      width: 18px;
      border-radius: 25px;
      background: $light-color;
      cursor: pointer;
      -webkit-appearance: none;
      margin-top: -7px;
    }
    &-input::-moz-range-track {
      width: 100%;
      height: 5px;
      cursor: pointer;
      animate: 0.2s;
      box-shadow: 0px 0px 0px #000000;
      background: #ededed;
      border-radius: 50px;
      border: 0px solid $light-color;
    }
    &-input::-moz-range-thumb {
      box-shadow: 0px 0px 0px #000000;
      border: 3px solid $primary-color;
      height: 18px;
      width: 18px;
      border-radius: 25px;
      background: $light-color;
      cursor: pointer;
    }
    &-input::-ms-track {
      width: 100%;
      height: 5px;
      cursor: pointer;
      animate: 0.2s;
      box-shadow: 0px 0px 0px #000000;
      background: transparent;
      border-color: transparent;
      color: transparent;
    }
    &-input::-ms-fill-lower {
      background: #ededed;
      border: 0px solid $light-color;
      border-radius: 50px;
    }
    &-input::-ms-fill-upper {
      background: #ededed;
      border: 0px solid $light-color;
      border-radius: 50px;
    }
    &-input::-ms-thumb {
      box-shadow: 0px 0px 0px #000000;
      border: 3px solid $primary-color;
      height: 18px;
      width: 18px;
      border-radius: 25px;
      background: $light-color;
      cursor: pointer;
    }
  }
}

.search-page {
  &-sort {
    display: flex;
    align-items: center;
    gap: 10px;
    &-text {
      font-size: 16px;
      color: $primary-text;
      font-weight: 500;
    }
    &-button {
      border: 1px solid $primary-color;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      border-radius: 6px;
      padding: 8px 16px;
      color: $primary-color;
      font-size: 14px;
      line-height: 1.57;
      font-weight: 400;
      position: relative;
      i {
        font-size: 16px;
      }
    }
    &-dropdown {
      position: absolute;
      z-index: 50;
      top: calc(100% + 6.5px);
      left: 0;
      width: 100%;
      width: 240px;
      border-radius: 10px;
      border: 1px solid $primary-color;
      background-color: $light-color;
      flex-direction: column;
      gap: 16px;
      padding: 20px;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
      display: none;
      &.active {
        display: flex;
      }
      li {
        text-align: left;
        font-size: 16px;
        color: $primary-color;
        font-weight: 400;
        cursor: pointer;
        &:hover {
          color: #ffc240;
        }
      }
    }
  }
  &-map {
    border-radius: 6px;
    padding: 8px 16px;
    background-color: #f5f5f5;
    display: inline-flex;
    align-items: center;
    gap: 8px;
    span {
      font-size: 14px;
      line-height: 1.57;
      color: $primary-color;
      font-weight: 400;
    }
  }
  &-result {
    font-size: 14px;
    line-height: 1.57;
    color: $primary-text;
    font-weight: 500;
    border-bottom: 2px solid $primary-color;
    padding-bottom: 20px;
    margin-bottom: 20px;
  }
  &-filter {
    padding-bottom: 24px;
    margin-bottom: 24px;
    border-bottom: 1px solid $primary-color;
    &-button {
      font-size: 16px;
      line-height: 1.5;
      color: $light-color;
      font-weight: 400;
      background-color: $primary-color;
      border-radius: 5px;
      padding: 8px 15px;
      text-align: center;
      transition: opacity 0.2s ease;
      width: 100%;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      transition: opacity 0.2s ease;
      &:hover {
        opacity: 0.8;
      }
    }
    &-modalButton {
      font-size: 16px;
      line-height: 1.5;
      color: $light-color;
      font-weight: 500;
      border-radius: 10px;
      padding: 8px 16px;
      transition: opacity 0.2s ease;
      &--result {
        background-color: $primary-color;
      }
      &--delete {
        background-color: #6c757d;
      }
      &:hover {
        opacity: 0.8;
      }
    }
  }
}

// Responsive all screen
@include maxWidth(991px) {
  .search-col {
    border: unset;
  }
  #search-page-filterMobile {
    position: fixed;
    left: 0;
    top: 0;
    max-width: 300px;
    @include size(100%);
    overflow-y: auto;
    transition: transform 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    transform: translateX(-100%);
    z-index: 400;
    background-color: $light-color;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    &.show {
      transform: translateX(0);
    }
  }
}
@include maxWidth(424px) {
  .search-page-sort-dropdown {
    right: 0;
    left: unset;
  }
}
