.features-section {
   background-color: #f6f9ff;
   padding: clamp(1.875rem, -2.679rem + 17.143vw, 3.75rem) 0;
   &-item {
     display: flex;
     flex-direction: column;
     align-items: center;
     text-align: center;
     padding: 20px;
     gap: 15px;
     h3 {
       font-size: 20px;
       line-height: 1.1;
       color: $primary-text;
       font-weight: bold;
     }
     p {
       font-size: 14px;
       line-height: 1.57;
       color: $primary-text;
       font-weight: 400;
     }
   }
 }