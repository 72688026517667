.topbar {
  padding: 24px 0;
  background-color: #fcf6f2;
  &-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
  }
  &-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px 0;
    li {
      a {
        font-size: 14px;
        font-weight: 600;
        line-height: calc(17 / 14);
        color: $primary-color;
        transition: opacity 0.2s linear;
        &:hover {
          opacity: 0.8;
        }
        &:before {
          content: "|";
          margin: 0 8px;
          color: $primary-color;
        }
      }
      &:first-child a {
        &:before {
          content: none;
        }
      }
    }
  }
  &-user {
    width: fit-content;
    margin-left: auto;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    button {
      font-size: 14px;
      font-weight: 600;
      line-height: calc(17 / 14);
      color: $primary-color;
      transition: opacity 0.2s linear;
      &:hover {
        opacity: 0.8;
      }
      &:before {
        content: "|";
        margin: 0 8px;
        color: $primary-color;
      }
      &:first-child {
        &:before {
          content: none;
        }
      }
    }
  }
  @include maxWidth(767px) {
    display: none;
    &-row {
      flex-direction: column;
    }
  }
}

.header {
  padding: 8px 0;
  z-index: 80;
  width: 100%;
  background-color: $light-color;
  animation: headerBefore 0.6s ease-in-out;
  &.fixed {
    position: fixed;
    top: 0;
    left: 0;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    animation: headerFixed 0.6s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  @keyframes headerBefore {
    from {
      opacity: 0;
      transform: scale(0.5);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }
  @keyframes headerFixed {
    from {
      opacity: 0;
      transform: translateY(-100%);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  &-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    &-nav {
      ul {
        display: flex;
        align-items: center;
        gap: 16px;
        li {
          position: relative;
          &:hover .submenu {
            display: block;
          }
          a {
            font-size: 16px;
            font-weight: 600;
            line-height: calc(22 / 20);
            color: $primary-text;
            display: inline-flex;
            align-items: center;
            gap: 8px;
            padding: 15px 16px;
            transition: color 0.2s linear;
            width: 100%;
            i {
              font-size: 12px;
            }
            &:hover {
              color: $primary-color;
            }
          }
        }
      }
      .submenu {
        display: none;
        position: absolute;
        top: calc(100%);
        left: 0;
        min-width: 150px;
        background-color: #f9f9f9;
        box-shadow: rgba(100, 100, 111, 0.3) 0px 7px 29px 0px;
        z-index: 1000;
        min-width: 200px;
        max-height: 300px;
        animation: effectSubMenu 0.3s ease;
        overflow-y: auto;
        overscroll-behavior: none;
        &::-webkit-scrollbar {
          width: 6px;
          background-color: transparent;
        }
        &::-webkit-scrollbar-thumb {
          background-color: $primary-color;
        }
        @keyframes effectSubMenu {
          from {
            transform: translateY(-20px);
            opacity: 0;
          }
          to {
            transform: translateY(0);
            opacity: 1;
          }
        }
        &::before {
          content: "";
          position: absolute;
          bottom: 100%;
          left: 0;
          height: 6px;
          width: 100%;
        }
        li {
          a {
            padding: 20px 16px;
          }
          &:not(:last-child) {
            border-bottom: 1px solid rgb(204 204 204 / 60%);
          }
        }
      }
    }
    &-bars {
      max-width: 38px;
      display: none;
      img {
        @include size(100%);
        object-fit: cover;
      }
    }
  }
  @include maxWidth(767px) {
    background-color: #fcf6f2;
    &-wrapper-nav {
      display: none;
    }
    &-wrapper-bars {
      display: block;
    }
  }
}
