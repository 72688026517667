.detail-member {
  &-profile {
    display: flex;
    align-items: center;
    gap: 25px;
    border-radius: 10px;
    border: 1.5px solid $primary-color;
    padding: 20px 24px;
    margin-bottom: 32px;
    position: relative;
    &-avatar {
      @include size(100px);
      img {
        object-fit: cover;
        border-radius: 50%;
        @include size(100%);
      }
    }
    &-info {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
    &-name {
      display: flex;
      align-items: center;
      gap: 15px;
      font-size: 32px;
      color: $primary-text;
      font-weight: bold;
    }
    &-list {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px 15px;
      flex-wrap: wrap;
      li {
        display: flex;
        align-items: center;
        gap: 8px;
        border-right: 1.5px solid #d9d9d9;
        padding-right: 15px;
        &:last-child {
          border-right: none;
          padding-right: 0;
        }
      }
    }
    &-pencil {
      cursor: pointer;
      position: absolute;
      bottom: 1.5rem;
      right: 1.5rem;
    }
  }
  &-post {
    font-size: 20px;
    color: $light-color;
    font-weight: 700;
    border-radius: 10px;
    padding: 8px 16px;
    background-color: $primary-color;
    display: inline-block;
    transition: opacity 0.2s ease;
    @media (hover: hover) {
      &:hover {
        opacity: 0.8;
      }
    }
  }
  &-intro {
    padding: 24px 20px;
    border: 1.5px solid $primary-color;
    border-radius: 10px;
    h1 {
      font-size: 20px;
      color: $primary-color;
    }
    ul {
      margin-top: 15px;
      display: flex;
      flex-direction: column;
      gap: 15px;
      li {
        display: flex;
        align-items: center;
        gap: 20px;
        a {
          font-size: 16px;
          color: $primary-text;
          font-weight: 400;
          transition: color 0.2s ease;
          display: inline-block;
          @media (hover: hover) {
            &:hover {
              color: $primary-color;
            }
          }
        }
      }
    }
  }
  &-modal-title {
    font-size: 24px;
    color: $primary-color;
    font-weight: 700;
  }
}

// Responsive all screen
@include maxWidth(1023px) {
  .detail-member-modal-title {
    font-size: 20px;
  }
  .detail-member-post {
    font-size: 18px;
  }
}

@include maxWidth(767px) {
  .detail-member {
    &-profile {
      flex-direction: column;
      justify-content: center;
      text-align: center;
      margin-bottom: 25px;
      &-info {
        align-items: center;
      }
      &-pencil {
        right: 1rem;
        bottom: 1rem;
      }
      &-name h1 {
        font-size: 20px;
      }
    }
  }
}
