.blog {
  margin-top: 40px;
  &-pane {
    margin-top: 24px;
    display: none;
    &.active {
      display: block;
    }
  }
  &-tab {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 32px;
    &-item {
      font-size: 14px;
      line-height: 1.57;
      color: $primary-text;
      font-weight: bold;
      transition: color 0.2s ease;
      user-select: none;
      &:hover:not(.active) {
        color: $primary-color;
      }
      &.active {
        color: $primary-color;
        position: relative;
        pointer-events: none;
        display: block;
        &::after {
          content: "";
          position: absolute;
          bottom: -100%;
          left: 0;
          height: 2.5px;
          width: 100%;
          background-color: $primary-color;
          margin-bottom: 12px;
        }
      }
    }
  }
  &-item {
    border-radius: 5px;
    border: 1px solid #ced4da;
    background-color: $light-color;
    padding: clamp(0.875rem, -1rem + 3.906vw, 1.5rem);
    display: flex;
    align-items: center;
    gap: 20px;
    &-image {
      height: 0;
      position: relative;
      padding-bottom: 34.15%;
      border-radius: 5px;
      flex-basis: 40.2%;
      flex-shrink: 0;
      flex-grow: 0;
      & > * {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    &-content {
      display: flex;
      flex-direction: column;
      gap: 16px;
      color: $primary-text;
      font-size: 14px;
      h2 {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-word;
        border-radius: 5px;
        padding: 6px;
        background-color: rgb(108 117 124 / 30%);
        font-weight: 400;
        color: rgb(0 0 0 / 50%);
        font-size: 14px;
        width: fit-content;
      }
      h1 {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-word;
        line-height: 1.4;
        font-weight: bold;
        text-transform: capitalize;
        font-size: 16px;
      }
      p {
        color: #858484;
      }
    }
  }
  &-readmore {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 15px;
    padding: 0 14px;
    background-color: $primary-color;
    max-width: 270px;
    width: 100%;
    height: 42px;
    font-size: 16px;
    color: $light-color;
    margin-top: 24px;
    font-weight: 500;
    transition: background-color 0.2s linear;
    &:hover {
      background-color: #be4c0d;
      color: $light-color;
    }
  }
  @include maxWidth(1023px) {
    &-tab {
      justify-content: center;
      gap:20px 24px;
    }
    &-item {
      display: block;
      &-image {
        padding-bottom: 62.5%;
        margin-bottom: 10px;
      }
      &-content {
        gap: 11px;
      }
    }
  }
}
