.tour {
  margin-bottom: 40px;
  &-item {
    border-radius: 5px;
    height: 0;
    position: relative;
    padding-bottom: 80.25%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 10px;
    overflow: hidden;
  }
  &-item-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -2;
  }
  &-item-content {
    position: absolute;
    top: 0;
    left: 0;
    padding: 24px;
    color: $light-color;
    font-weight: bold;
    line-height: 1.4;
    display: flex;
    flex-direction: column;
    gap: 15px;
    @include size(100%);
    h2 {
      font-size: 24px;
    }
    p {
      font-size: 14px;
    }
    a {
      border-radius: 5px;
      background-color: $light-color;
      color: $primary-color;
      font-weight: bold;
      display: inline-block;
      padding: 6.4px 15px;
      width: fit-content;
      margin-top: auto;
      transition: all 0.2s ease;
      &:hover {
        color: $light-color;
        background-color: $primary-color;
      }
    }
  }
  @include maxWidth(991px) {
    &-item {
      min-height: 180px;
      padding-bottom: unset;
    }
  }
  @include maxWidth(424px) {
    &-item-content {
      gap: 8px;
      h2 {
        font-size: 20px;
      }
    }
  }
}
