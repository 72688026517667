.order-info {
  border: 1.5px solid $primary-color;
  border-radius: 5px;
  padding: 18px 12px;
  background-color: $light-color;
  &:not(:last-child) {
    margin-bottom: 12px;
  }
  &-thumbnail {
    @include size(100px);
    border-radius: 5px;
    overflow: hidden;
    flex-shrink: 0;
    object-fit: cover;
  }
  &-content {
    display: flex;
    flex-direction: column;
    gap: 12px;
    h1 {
      font-weight: 600;
      font-size: 14px;
      color: $primary-text;
      line-height: 18px;
    }
    p {
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      color: $primary-text;
    }
  }
  &-quantity {
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 1fr;
    gap: 8px;
    label {
      color: $primary-text;
      font-weight: 400;
      font-size: 15px;
      line-height: 19px;
    }
    .filter-room-item-quantity {
      height: 30px;
      input,
      button {
        width: 35px;
      }
    }
  }
  &-image {
    height: 0;
    position: relative;
    padding-bottom: 46.15%;
    border-radius: 5px;
    flex-basis: 80.2%;
    flex-shrink: 0;
    flex-grow: 0;
    border-radius: 5px;
    overflow: hidden;
    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  @include maxWidth(575px) {
    &-checkbox {
      font-size: 24px !important;
    }
  }
}

#button-removeCheckbox {
  font-size: 14px;
  color: $primary-color;
  font-weight: 400;
  border-radius: 5px;
  padding: 8px 10px;
  background-color: $light-color;
  font-size: 14px;
  color: $primary-color;
  font-weight: 400;
  border: 1px solid $primary-color;
  transition: opacity 0.2s ease;
  &:hover {
    opacity: 0.8;
  }
}

.booking {
  &-container {
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 0.3fr 1fr;
    gap: 6px;
  }
  &-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 6px;
    border-radius: 8px;
    padding: 10px 5px;
    border: 1.5px solid $primary-color;
    &-title {
      font-size: 12px;
      color: #a9a6a6;
      font-weight: 400;
    }
    &-date {
      font-size: 12px;
      color: $primary-text;
      font-weight: bold;
    }
    &-time {
      font-size: 12px;
      color: $primary-text;
      font-weight: 400;
    }
  }
  &-divider {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    &-night {
      font-size: 11px;
      color: #a9a6a6;
      font-weight: 300;
    }
    &-line {
      width: 100%;
    }
  }
}
