@charset "UTF-8";
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  font-family: "Inter", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  font-size: 14px;
  color: #000000;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol,
li,
button,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
  padding: 0;
}

button {
  border: none;
  background-color: transparent;
  cursor: pointer;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}

main {
  margin-top: -3.2rem;
}
@media only screen and (max-width: 767px) {
  main {
    margin-top: 0;
  }
}

::selection {
  background-color: #FF5B00;
  color: #ffffff;
}

.no-scroll {
  overflow-y: hidden;
}

.disabled-click {
  pointer-events: none;
}

.overlay-form,
.overlay-hotel,
.overlay-menu,
.overlay-searchPage,
.overlay-servicesDetail {
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  display: none;
}

.text-color-primary {
  color: #FF5B00;
}

.close-btn {
  position: absolute;
  top: 1rem;
  right: 0.8rem;
  cursor: pointer;
  z-index: 85;
  color: #ffffff;
  font-size: 18px;
  text-transform: uppercase;
}
@media only screen and (max-width: 374px) {
  .close-btn {
    display: none;
  }
}

.logo {
  overflow: hidden;
  position: relative;
  text-decoration: none;
  display: inline-block;
  max-width: clamp(9rem, 4.821rem + 13.393vw, 16.875rem);
}
.logo img {
  width: 100%;
  height: auto;
}
.logo::before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.7), transparent);
  transform: skewX(-25deg);
  animation: lightRay 5s infinite;
}
@keyframes lightRay {
  0% {
    left: -100%;
  }
  100% {
    left: 100%;
  }
}
@media only screen and (max-width: 767px) {
  .logo {
    max-width: 144px !important;
  }
}

.home-title {
  margin-bottom: 24px;
  color: #FF5B00;
  font-size: 20px;
  font-weight: bold;
  text-transform: uppercase;
}
@media only screen and (max-width: 1023px) {
  .home-title {
    text-align: center;
    margin-bottom: 18px;
  }
}

.title {
  font-weight: 700;
  color: #FF5B00;
  text-transform: uppercase;
}
@media only screen and (max-width: 599px) {
  .title {
    font-size: 18px !important;
  }
}

.read-more {
  font-size: 16px;
  line-height: 1.5;
  color: #FF5B00;
  font-weight: bold;
  transition: color 0.2s linear;
  width: fit-content;
  flex-shrink: 0;
}
.read-more:hover {
  color: #ca6c3a;
}

.price {
  font-size: 14px;
  font-weight: bold;
  width: fit-content;
  margin-left: auto;
  color: #FF5B00;
}

.rating .fa-star {
  font-size: 16px;
}
.rating .fa-solid {
  color: #ffc240;
}
.rating .fa-regular {
  color: #807a78;
}

.location-info {
  display: flex;
  align-items: center;
}
.location-info-item {
  display: flex;
  align-items: center;
  gap: 8px;
}
.location-info-item img {
  width: 18px !important;
  height: 18px !important;
}
.location-info-item span {
  line-height: 1.57;
  font-size: 14px;
  color: #FF5B00;
  font-weight: 400;
}
.location-info-separator {
  height: 15px;
  background-color: #FF5B00;
  width: 1px;
  margin: 0 10px;
}

.container {
  max-width: 1164px;
  padding: 0 12px;
  margin: 0 auto;
}

.google-map {
  position: relative;
  padding-bottom: 51%;
  height: 0;
  overflow: hidden;
}
.google-map iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
}

.introDuction {
  background-color: #f9f9f9;
  border-left: 4px solid #FF5B00;
}
.introDuction p {
  padding: 24px;
  font-size: clamp(1rem, 0.646rem + 0.943vw, 1.25rem);
  color: #000000;
  font-weight: 400;
}

.readmore-button {
  font-size: 16px;
  line-height: 1.5;
  color: #ffffff;
  font-weight: 400;
  background-color: #FF5B00;
  border-radius: 5px;
  padding: 8px 15px;
  text-align: center;
  transition: opacity 0.2s ease;
  width: 100%;
  max-width: 270px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
@media (hover: hover) {
  .readmore-button:hover {
    opacity: 0.8;
  }
}

.owl-carousel .owl-dot {
  margin-top: 0.5rem !important;
}
.owl-carousel .owl-dot.active span {
  background-color: #FF5B00 !important;
}
.owl-carousel .owl-nav button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: #ffffff !important;
  width: 40px;
  height: 40px;
  border-radius: 100rem !important;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border: none;
  outline: none;
  display: inline-flex !important;
  justify-content: center;
  align-items: center;
}
.owl-carousel .owl-nav button:active {
  opacity: 0.6;
}
.owl-carousel .owl-nav button:hover, .owl-carousel .owl-nav button:hover i {
  transition: all 0.2s ease;
  background-color: #FF5B00 !important;
  color: #ffffff !important;
}
.owl-carousel .owl-nav .owl-prev {
  left: -55px;
}
.owl-carousel .owl-nav .owl-next {
  right: -55px;
}
.owl-carousel .owl-nav i {
  color: #333;
  font-size: 18px;
}

.daterangepicker .calendar-table th {
  font-size: 14.5px !important;
  color: #FF5B00;
}
.daterangepicker .drp-buttons .btn {
  padding-block: 5px !important;
  font-size: 14px !important;
}
.daterangepicker .drp-selected {
  font-size: 15px !important;
  font-weight: bold;
  color: #FF5B00;
}
.daterangepicker .calendar-table td {
  font-size: 15.8px !important;
  width: 35px !important;
  height: 28px !important;
}
.daterangepicker td.active,
.daterangepicker td.active:hover,
.daterangepicker .ranges li.active,
.daterangepicker .ranges li.active:hover {
  background-color: #FF5B00 !important;
  color: #ffffff !important;
}
.daterangepicker td.in-range {
  background-color: rgba(254, 91, 0, 0.2) !important;
  color: #000000 !important;
}
.daterangepicker .drp-buttons .applyBtn {
  background-color: #FF5B00 !important;
  border-color: #FF5B00 !important;
  color: #ffffff !important;
}
.daterangepicker .drp-buttons .applyBtn:hover {
  background-color: #ff9a64 !important;
  color: #ffffff !important;
}
@media (max-width: 599px) {
  .daterangepicker .drp-calendar.left {
    display: block !important;
    float: none !important;
    width: 100% !important;
  }
  .daterangepicker .drp-calendar.right {
    display: none !important;
  }
}

.carousel-button-prev,
.carousel-button-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  background-color: #ffffff;
  width: 45px;
  height: 45px;
  border-radius: 100rem;
  border: none;
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.2s ease;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.carousel-button-prev:active,
.carousel-button-next:active {
  opacity: 0.6 !important;
}
.carousel-button-prev:hover,
.carousel-button-next:hover {
  background-color: #e7e7e7;
}
.carousel-button-prev i,
.carousel-button-next i {
  font-size: 16.5px;
}

.carousel-button-prev {
  left: 10px;
}

.carousel-button-next {
  right: 10px;
}

/* Custom CSS for thumbnails */
.f-carousel__thumbs {
  --f-thumb-width: 150px !important;
  --f-thumb-height: 90px !important;
}

.f-carousel__slide img {
  height: clamp(15rem, 5.434rem + 40.816vw, 25rem) !important;
}

.line-section {
  border-top: 1.5px solid #d9d9d9;
}

.explore-more-title {
  font-weight: 700;
  color: #FF5B00;
  line-height: 24.2px;
  font-size: 20px;
  margin-top: 30px;
  margin-bottom: 20px;
}
.explore-more-tag {
  border: 1.5px solid #FF5B00;
  border-radius: 5px;
  padding: 8px;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #000000;
  transition: all 0.2s ease;
}
.explore-more-tag:hover {
  background-color: #FF5B00;
  color: #ffffff;
}

.readmore-all {
  width: 100%;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  border: 1px solid #383737;
  color: #383737;
  border-radius: 5px;
  padding: 14px 20px;
  text-align: center;
  transition: all 0.2s ease;
}
.readmore-all:hover {
  background-color: #FF5B00;
  border-color: #FF5B00;
  color: #ffffff;
}

.ticket-list {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.ticket-list .filter-room-item-quantity {
  height: 40px;
}
.ticket-list .filter-room-item-quantity input {
  width: 55px;
  height: 40px;
  font-size: 20px;
}
.ticket-list .filter-room-item-quantity button {
  width: 40px;
  height: 100%;
}
@media only screen and (max-width: 599px) {
  .ticket-list .filter-room-item-quantity {
    width: auto;
    height: 30px;
  }
  .ticket-list .filter-room-item-quantity input {
    width: 40px;
    height: 100%;
    font-size: 14px;
  }
  .ticket-list .filter-room-item-quantity button {
    width: 30px;
  }
}
.ticket-list .filter-room-item-quantity b {
  font-size: 18px;
}
.ticket-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
  border: 1px solid #ccc;
  border-radius: 6px;
}
.ticket-item-title {
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
}
.ticket-item-price {
  color: #FF5B00;
  font-weight: 600;
  font-size: clamp(1.063rem, 0.83rem + 0.875vw, 1.25rem);
  line-height: 24.2px;
}
.ticket-item-desc {
  font-weight: 400;
  font-size: 16px;
  line-height: 19.36px;
  color: #b6b6b6;
}

.rating-ranking {
  margin: 32px 0 24px 0;
}
.rating-ranking-header {
  color: #FF5B00;
  font-size: 20px;
  font-weight: bold;
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px dashed #000000;
}
.rating-ranking-subheader {
  font-size: 20px;
  color: #000000;
  font-weight: 700;
  margin-bottom: 24px;
}
.rating-ranking-wrapper {
  display: flex;
  justify-content: space-between;
  gap: 0 50px;
  align-items: center;
}
.rating-ranking-score {
  color: #FF5B00;
  font-weight: 700;
  display: flex;
  flex-direction: column;
  gap: 10px 0;
  line-height: normal;
  text-align: center;
  padding: 35px;
}
.rating-ranking-score .score {
  font-size: 60px;
}
.rating-ranking-score .text {
  font-size: 20px;
}
.rating-ranking-details {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px 0;
}
.rating-ranking-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.rating-ranking-item .label {
  flex: 0.5;
  font-size: 20px;
  color: #000000;
  font-weight: 500;
}
.rating-ranking-item .bar {
  flex: 2;
  margin: 0 10px;
  height: 10px;
  background: rgba(217, 217, 217, 0.6);
  position: relative;
  border-radius: 100rem;
}
.rating-ranking-item .bar span {
  border-radius: 100rem;
  display: block;
  height: 100%;
  background: #FF5B00;
  position: absolute;
  top: 0;
  left: 0;
}
.rating-ranking-item .value {
  flex: 0 0 30px;
  text-align: right;
  font-size: 14px;
  color: #000000;
  font-weight: 500;
}
@media only screen and (max-width: 1200px) {
  .rating-ranking-item .label {
    font-size: 16px;
  }
}
@media only screen and (max-width: 767px) {
  .rating-ranking-subheader {
    text-align: center;
    margin-bottom: 10px;
  }
  .rating-ranking-wrapper {
    flex-direction: column;
    gap: 15px 0;
  }
  .rating-ranking-score {
    padding: unset;
  }
  .rating-ranking-score .score {
    font-size: 50px;
  }
}
@media only screen and (max-width: 599px) {
  .rating-ranking-item .label {
    flex: 1;
  }
}

.review-card {
  background-color: #ffffff;
  border: 1.5px solid #FF5B00;
  border-radius: 12px;
  padding: 20px 30px;
}
.review-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  margin-bottom: 12px;
  padding-bottom: 20px;
  border-bottom: 1px solid #dbdbdb;
}
.review-info {
  display: flex;
  align-items: center;
  gap: 15px;
}
.review-avatar {
  border-radius: 100rem;
  overflow: hidden;
  width: 56px;
  height: 56px;
}
.review-avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.review-name {
  font-size: 16px;
  line-height: 1.5;
  color: #111928;
  font-weight: 400;
}
.review-date {
  font-size: 14px;
  line-height: 1.57;
  color: #637381;
  font-weight: 400;
}
.review-rating .rating-value {
  font-size: 16px;
  line-height: 1.5;
  color: #111928;
  font-weight: 500;
  font-weight: bold;
}
.review-rating {
  display: flex;
  align-items: center;
  gap: 10px;
}
.review-rating-list {
  display: flex;
  align-items: center;
  gap: 5px;
  color: #f59e0b;
}
.review-content {
  margin-top: 10px;
}
.review-content h3 {
  font-size: 20px;
  line-height: 1.3;
  color: #111928;
  font-weight: 500;
}
.review-content p {
  font-size: 16px;
  line-height: 1.5;
  color: #637381;
  font-weight: 400;
  margin-top: 10px;
}
@media only screen and (max-width: 599px) {
  .review-header {
    flex-direction: column;
  }
  .review-card {
    padding: 18px;
  }
  .review-content {
    text-align: center;
  }
  .review-content p {
    font-size: 14.5px;
  }
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 24px 0 32px 0;
  gap: 8px;
}
.pagination a {
  padding: 10px 16px;
  border: 1px solid #ddd;
  border-radius: 5px;
  text-decoration: none;
  color: #333;
  display: inline-block;
  font-size: 14px;
  transition: background-color 0.2s linear;
}
.pagination a.active {
  background-color: #FF5B00;
  color: #ffffff;
  border-color: #FF5B00;
}
@media (hover: hover) {
  .pagination a:hover:not(.active) {
    background-color: #f5f5f5;
  }
}
@media only screen and (max-width: 424px) {
  .pagination a {
    padding: 8px 14px;
    font-size: 12px;
  }
}
@media only screen and (max-width: 374px) {
  .pagination a {
    padding: 5px 10px;
  }
}

.features-section {
  background-color: #f6f9ff;
  padding: clamp(1.875rem, -2.679rem + 17.143vw, 3.75rem) 0;
}
.features-section-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 20px;
  gap: 15px;
}
.features-section-item h3 {
  font-size: 20px;
  line-height: 1.1;
  color: #000000;
  font-weight: bold;
}
.features-section-item p {
  font-size: 14px;
  line-height: 1.57;
  color: #000000;
  font-weight: 400;
}

.topbar {
  padding: 24px 0;
  background-color: #fcf6f2;
}
.topbar-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}
.topbar-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px 0;
}
.topbar-list li a {
  font-size: 14px;
  font-weight: 600;
  line-height: 1.2142857143;
  color: #FF5B00;
  transition: opacity 0.2s linear;
}
.topbar-list li a:hover {
  opacity: 0.8;
}
.topbar-list li a:before {
  content: "|";
  margin: 0 8px;
  color: #FF5B00;
}
.topbar-list li:first-child a:before {
  content: none;
}
.topbar-user {
  width: fit-content;
  margin-left: auto;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
}
.topbar-user button {
  font-size: 14px;
  font-weight: 600;
  line-height: 1.2142857143;
  color: #FF5B00;
  transition: opacity 0.2s linear;
}
.topbar-user button:hover {
  opacity: 0.8;
}
.topbar-user button:before {
  content: "|";
  margin: 0 8px;
  color: #FF5B00;
}
.topbar-user button:first-child:before {
  content: none;
}
@media only screen and (max-width: 767px) {
  .topbar {
    display: none;
  }
  .topbar-row {
    flex-direction: column;
  }
}

.header {
  padding: 8px 0;
  z-index: 80;
  width: 100%;
  background-color: #ffffff;
  animation: headerBefore 0.6s ease-in-out;
}
.header.fixed {
  position: fixed;
  top: 0;
  left: 0;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  animation: headerFixed 0.6s cubic-bezier(0.645, 0.045, 0.355, 1);
}
@keyframes headerBefore {
  from {
    opacity: 0;
    transform: scale(0.5);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes headerFixed {
  from {
    opacity: 0;
    transform: translateY(-100%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.header-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}
.header-wrapper-nav ul {
  display: flex;
  align-items: center;
  gap: 16px;
}
.header-wrapper-nav ul li {
  position: relative;
}
.header-wrapper-nav ul li:hover .submenu {
  display: block;
}
.header-wrapper-nav ul li a {
  font-size: 16px;
  font-weight: 600;
  line-height: 1.1;
  color: #000000;
  display: inline-flex;
  align-items: center;
  gap: 8px;
  padding: 15px 16px;
  transition: color 0.2s linear;
  width: 100%;
}
.header-wrapper-nav ul li a i {
  font-size: 12px;
}
.header-wrapper-nav ul li a:hover {
  color: #FF5B00;
}
.header-wrapper-nav .submenu {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  min-width: 150px;
  background-color: #f9f9f9;
  box-shadow: rgba(100, 100, 111, 0.3) 0px 7px 29px 0px;
  z-index: 1000;
  min-width: 200px;
  max-height: 300px;
  animation: effectSubMenu 0.3s ease;
  overflow-y: auto;
  overscroll-behavior: none;
}
.header-wrapper-nav .submenu::-webkit-scrollbar {
  width: 6px;
  background-color: transparent;
}
.header-wrapper-nav .submenu::-webkit-scrollbar-thumb {
  background-color: #FF5B00;
}
@keyframes effectSubMenu {
  from {
    transform: translateY(-20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
.header-wrapper-nav .submenu::before {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 0;
  height: 6px;
  width: 100%;
}
.header-wrapper-nav .submenu li a {
  padding: 20px 16px;
}
.header-wrapper-nav .submenu li:not(:last-child) {
  border-bottom: 1px solid rgba(204, 204, 204, 0.6);
}
.header-wrapper-bars {
  max-width: 38px;
  display: none;
}
.header-wrapper-bars img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@media only screen and (max-width: 767px) {
  .header {
    background-color: #fcf6f2;
  }
  .header-wrapper-nav {
    display: none;
  }
  .header-wrapper-bars {
    display: block;
  }
}

.banner-item {
  height: clamp(25rem, 16.587rem + 9.615vw, 28.125rem);
}
.banner-item-image {
  height: 100%;
  object-fit: cover;
}
.banner-item-content {
  color: #ffffff;
  text-align: center;
  top: 59px;
  max-width: 980px;
  padding: 10px;
  width: 100%;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.banner-item-content h1 {
  font-size: clamp(1.25rem, 0.75rem + 2.5vw, 3.75rem);
  line-height: clamp(1.25rem, 0.75rem + 2.5vw, 3.75rem);
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 24px;
}
.banner-item-content p {
  font-size: clamp(0.875rem, 0.768rem + 0.401vw, 1.25rem);
  font-weight: 400;
  line-height: 24.2px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
}
@media only screen and (max-width: 767px) {
  .banner-item {
    height: clamp(12.5rem, 1.071rem + 57.143vw, 16.25rem);
  }
  .banner-item-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.filter {
  border-radius: 10px;
  background-color: #ffffff;
  max-width: 980px;
  width: 100%;
  padding: 25px 20px;
  border: 1.5px solid #FF5B00;
  z-index: 15;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.filter-tab {
  display: flex;
  align-items: center;
  text-align: center;
  gap: 15px;
  overflow-x: auto;
  white-space: nowrap;
  scrollbar-width: thin;
  scrollbar-color: #ccc9c9 #e0e0e0;
  margin-bottom: 10px;
  padding-bottom: 14px;
}
.filter-tab-item {
  padding: 0 15px;
  cursor: pointer;
  transition: color 0.2s linear;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  position: relative;
  padding-bottom: 8px;
  user-select: none;
}
.filter-tab-item.active {
  pointer-events: none;
}
.filter-tab-item.active::after {
  position: absolute;
  bottom: 0;
  left: 0;
  content: "";
  width: 100%;
  height: 2px;
  background-color: #FF5B00;
  transition: width 0.2s linear;
}
.filter-tab-item.active span {
  color: #FF5B00;
}
.filter-tab-item img {
  width: 25px;
  height: 25px;
}
.filter-tab-item span {
  font-size: 14px;
  line-height: 1.69;
  color: #1a2b47;
  font-weight: 500;
}
.filter-pane {
  display: none;
}
.filter-pane.active {
  display: block;
}
.filter-select, .filter-box {
  background-color: #f5f5f5;
  padding: 8.5px 16px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
}
.filter-select span,
.filter-select .daterange-dichVu,
.filter-select .daterange-tourDuLich,
.filter-select .daterange-suKien, .filter-box span,
.filter-box .daterange-dichVu,
.filter-box .daterange-tourDuLich,
.filter-box .daterange-suKien {
  font-size: 14px;
  line-height: 1.57;
  color: #FF5B00;
  font-weight: 400;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
  border: none;
  outline: none;
  background-color: transparent;
}
.filter-select {
  position: relative;
  cursor: pointer;
  user-select: none;
}
.filter-search {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  border-radius: 5px;
  padding: 8.5px 10px;
  background-color: #FF5B00;
  color: #ffffff;
  font-size: 20px;
  line-height: 1.1;
  width: 100%;
  transition: background-color 0.2s linear;
}
@media (hover: hover) {
  .filter-search:hover {
    background-color: #f19866;
  }
}
.filter-dropdown, .filter-room {
  position: absolute;
  top: calc(100% + 6px);
  left: 0;
  width: 100%;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border: 1.8px solid #FF5B00;
  background-color: #ffffff;
  border-radius: 5px;
  overflow: hidden;
  z-index: 20;
  display: none;
}
.filter-dropdown.active {
  display: block;
}
.filter-dropdown input {
  width: 100%;
  padding: 8.5px 16px;
  border: none;
  outline: none;
  font-family: Open Sans;
  font-size: 16px;
  color: #000000;
  border-bottom: 1px solid #ccc;
}
.filter-dropdown ul li {
  font-size: 16px;
  line-height: 1.5;
  color: #000000;
  padding: 8.5px 16px;
  text-transform: capitalize;
  transition: all 0.2s ease;
  width: 100%;
}
.filter-dropdown ul li:not(:first-child) {
  border-top: 1px solid rgba(108, 117, 124, 0.2);
}
.filter-dropdown ul li:hover {
  background-color: #FF5B00;
  color: #ffffff;
}
.filter-dropdown-main {
  max-height: 210px;
  overflow-y: auto;
  overscroll-behavior: none;
}
.filter-dropdown-main::-webkit-scrollbar {
  width: 8px;
}
.filter-dropdown-main::-webkit-scrollbar-track {
  border-radius: 8px;
  background: rgba(108, 117, 124, 0.2);
}
.filter-dropdown-main::-webkit-scrollbar-thumb {
  background-color: #FF5B00;
  border-radius: 8px;
}
.filter-room {
  flex-direction: column;
  padding: 15px 24px;
  gap: 24px;
  user-select: none;
  cursor: auto;
}
.filter-room.active {
  display: flex;
}
.filter-room-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}
.filter-room-item-quantity {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  height: 25px;
}
.filter-room-item-quantity button {
  border-radius: 5px;
  background-color: #f9f9f9;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 100%;
  user-select: none;
}
.filter-room-item-quantity button img {
  width: 12px;
}
.filter-room-item-quantity button:active {
  opacity: 0.6;
}
.filter-room-item-quantity input {
  width: 30px;
  text-align: center;
  height: 100%;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  outline-color: #ff6a00;
  font-size: 14px;
}
.filter-box input {
  width: 100%;
  height: 100%;
  font-family: inherit;
  border: none;
  outline: none;
  background-color: transparent;
  font-size: 16px;
  line-height: 1.57;
}
.filter-box input::placeholder {
  color: #d9d9d9;
}
@media only screen and (max-width: 767px) {
  .filter {
    border-width: 2px;
    position: unset;
    transform: unset;
    padding: 20px 12px 30px 12px;
    margin-bottom: 30px;
  }
  .filter-search {
    padding-block: 11px;
    font-size: 18px;
  }
}

.tab {
  margin-bottom: 40px;
}
.tab-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 24px;
}
.tab-list {
  width: 62%;
  display: flex !important;
  text-align: center;
  flex-wrap: nowrap;
}
.tab-list .owl-nav button {
  margin-top: unset !important;
  margin: unset !important;
}
.tab-list .owl-nav .disabled {
  display: none !important;
}
.tab-list-item {
  padding: 8px 16px;
  display: inline-block;
  border-radius: 100rem;
  border: 1px solid transparent;
  line-height: 1.5714285714;
  font-size: 14px;
  color: #000000;
  font-weight: 600;
  text-transform: capitalize;
  transition: color 0.2s linear;
  user-select: none;
  white-space: nowrap;
}
.tab-list-item:hover:not(.active) {
  color: #FF5B00;
}
.tab-list-item.active {
  pointer-events: none;
  color: #FF5B00;
  border-color: #FF5B00;
}
.tab-pane {
  display: none;
}
.tab-pane.active {
  display: block;
}
@media only screen and (max-width: 1023px) {
  .tab-wrapper {
    flex-direction: column-reverse;
    margin-bottom: 20px;
    gap: 15px;
  }
  .tab-list {
    gap: 5px 2px;
    width: 80%;
    justify-content: center;
  }
}
@media only screen and (max-width: 767px) {
  .tab-list {
    width: 100%;
  }
}

.card-image {
  height: 0;
  position: relative;
  padding-bottom: 92.35%;
  border-radius: 6px 6px 0 0;
  overflow: hidden;
}
@media (hover: hover) {
  .card-image:hover img {
    transform: scale(1.2);
    opacity: 0.8;
  }
}
.card-image img {
  transition: all 0.8s cubic-bezier(0.075, 0.82, 0.165, 1);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.card-image-label {
  position: absolute;
  top: 0;
  left: 0;
  margin: 24px 0 0 8px;
  padding: 8px;
  background-color: #FF5B00;
  text-transform: capitalize;
  font-size: 12px;
  line-height: 1.33;
  color: #ffffff;
  font-weight: 400;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.card-content {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 24px 20px;
}
.card-price {
  font-size: 20px;
  color: #FF5B00;
}
.card-price b {
  text-transform: uppercase;
}
.card-heading a {
  font-size: 14px;
  color: #000000;
  font-weight: bold;
  line-height: 1.4;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
  transition: color 0.2s linear;
}
.card-heading a:hover {
  color: #FF5B00;
}
.card-info {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.card-info p {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}
.card-info p span {
  font-family: Open Sans;
  font-size: 12px;
  color: #000000;
}
.card-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.card-list li {
  display: flex;
  align-items: center;
  gap: 12px;
  font-size: 14px;
  color: #4ecb71;
  font-weight: 500;
}
.card-room {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 8px 12px;
}
.card-room-item {
  display: flex;
  gap: 5px;
}
.card-room-item img {
  width: 14px !important;
}
.card-room-item span {
  font-size: 14px;
  color: #403e3e;
}
.card-day {
  font-size: 14px;
  color: #b0abab;
  font-weight: 400;
  margin-left: auto;
}
.card-detail {
  font-size: 16px;
  line-height: 1.5;
  color: #FF5B00;
  font-weight: 500;
  text-decoration: underline;
  width: fit-content;
}
@media only screen and (max-width: 1023px) {
  .card-image {
    padding-bottom: 68.35%;
  }
}
@media only screen and (max-width: 767px) {
  .card-content {
    padding: 18px;
  }
  .card-image {
    padding-bottom: 62.35%;
  }
}

.tour {
  margin-bottom: 40px;
}
.tour-item {
  border-radius: 5px;
  height: 0;
  position: relative;
  padding-bottom: 80.25%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
  overflow: hidden;
}
.tour-item-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -2;
}
.tour-item-content {
  position: absolute;
  top: 0;
  left: 0;
  padding: 24px;
  color: #ffffff;
  font-weight: bold;
  line-height: 1.4;
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
  height: 100%;
}
.tour-item-content h2 {
  font-size: 24px;
}
.tour-item-content p {
  font-size: 14px;
}
.tour-item-content a {
  border-radius: 5px;
  background-color: #ffffff;
  color: #FF5B00;
  font-weight: bold;
  display: inline-block;
  padding: 6.4px 15px;
  width: fit-content;
  margin-top: auto;
  transition: all 0.2s ease;
}
.tour-item-content a:hover {
  color: #ffffff;
  background-color: #FF5B00;
}
@media only screen and (max-width: 991px) {
  .tour-item {
    min-height: 180px;
    padding-bottom: unset;
  }
}
@media only screen and (max-width: 424px) {
  .tour-item-content {
    gap: 8px;
  }
  .tour-item-content h2 {
    font-size: 20px;
  }
}

.travel {
  margin-bottom: 40px;
}
.travel-item {
  height: 0;
  position: relative;
  padding-bottom: 123.55%;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  overflow: hidden;
}
@media (hover: hover) and (pointer: fine) {
  .travel-item:hover .travel-item-bg {
    transform: scale(1.1);
    opacity: 0.8;
  }
}
.travel-item-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -2;
  transition: transform 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.travel-item-title {
  position: absolute;
  bottom: 0;
  left: 0;
  font-size: 20px;
  color: #ffffff;
  font-weight: bold;
  padding: 16px;
  width: 100%;
  text-transform: capitalize;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.info-hotel {
  position: fixed;
  top: 0;
  right: 0;
  height: 94vh;
  z-index: 200;
  max-width: 326px;
  max-height: 858px;
  display: none;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 15px;
  padding: 24px 20px;
  border: 2px solid #FF5B00;
  background-color: #ffffff;
  animation: roll-in-right 0.6s ease-out both;
}
.info-hotel.show {
  display: block;
}
@keyframes roll-in-right {
  0% {
    transform: translateX(-980px) rotate(540deg);
    opacity: 0;
  }
  100% {
    transform: translateX(0) rotate(0deg);
    opacity: 1;
  }
}
.info-hotel-wrapper {
  height: 100%;
  overscroll-behavior: none;
  margin-right: -0.7rem;
  padding-right: 0.5rem;
  overflow-y: auto;
}
.info-hotel-wrapper::-webkit-scrollbar {
  width: 5px;
}
.info-hotel-wrapper::-webkit-scrollbar-track {
  border-radius: 10px;
}
.info-hotel-wrapper::-webkit-scrollbar-thumb {
  background-color: #FF5B00;
  border-radius: 10px;
}
.info-hotel h1 {
  font-size: 18px;
  color: #000000;
  font-weight: bold;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
  margin: 12px 0;
  text-transform: capitalize;
}
.info-hotel-list {
  margin: 16px 0;
  display: flex;
  flex-direction: column;
  gap: 16px;
  border-bottom: 1px solid #ccc;
  padding-bottom: 12px;
}
.info-hotel-list h4 {
  font-size: 16px;
  color: #000000;
  font-weight: bold;
}
.info-hotel-list ul {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.info-hotel-list ul li {
  display: flex;
  gap: 12px;
  font-size: 14px;
  color: #000000;
}
.info-hotel-footer {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.info-hotel-footer span {
  font-size: 14px;
  color: #000000;
}
.info-hotel-footer b {
  color: #FF5B00;
  font-size: 24px;
  font-weight: 700;
  text-transform: uppercase;
}
.info-hotel-footer a {
  display: inline-flex;
  padding: 8px;
  width: 100%;
  border-radius: 100rem;
  border: 1px solid #FF5B00;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color: #FF5B00;
  transition: all 0.2s ease;
}
.info-hotel-footer a:hover {
  background-color: #FF5B00;
  color: #ffffff;
}

.ads {
  margin-bottom: 40px;
}
.ads-image {
  width: 100%;
}
.ads-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.featured-item {
  border-radius: 6px;
  overflow: hidden;
  background-color: #fff4ef;
}
.featured-image {
  padding-bottom: 54.65%;
  height: 0;
  position: relative;
}
.featured-image img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.featured-avatar {
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  padding-left: 24px;
}
.featured-avatar img {
  width: 70px !important;
  height: 70px !important;
  border-radius: 50%;
  object-fit: cover;
}
.featured-avatar img:not(:first-child) {
  margin-left: -42px;
}
.featured-avatar-more {
  font-size: 14px;
  margin-left: -11px;
  color: #ffffff;
  background-color: #FF5B00;
  border-radius: 5px;
  padding: 5px;
  text-transform: capitalize;
}
.featured-content {
  margin-top: -1rem;
  padding: 0 24px 24px 24px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.featured-content-title {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
  font-size: 20px;
  color: #000000;
  font-weight: bold;
}
.featured-content-label {
  font-size: 14px;
  color: #000000;
  text-transform: capitalize;
  border-radius: 5px;
  width: fit-content;
  padding: 5px 8px;
  background-color: #e2dbd8;
  color: rgba(0, 0, 0, 0.5);
  font-weight: 500;
}

#back-to-top {
  --size-button: clamp(3rem, 2.285rem + 1.749vw, 3.125rem);
  position: fixed;
  bottom: clamp(0.688rem, -0.009rem + 2.624vw, 1.25rem);
  right: clamp(0.688rem, -0.009rem + 2.624vw, 1.25rem);
  z-index: 60;
  background-color: #FF5B00;
  color: #ffffff;
  cursor: pointer;
  width: var(--size-button);
  height: var(--size-button);
  line-height: var(--size-button);
  text-align: center;
  font-size: clamp(1.125rem, 0.97rem + 0.583vw, 1.25rem);
  border-radius: 5px;
  transition: transform 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  display: none;
}
#back-to-top:hover {
  opacity: 0.8;
}

.blog {
  margin-top: 40px;
}
.blog-pane {
  margin-top: 24px;
  display: none;
}
.blog-pane.active {
  display: block;
}
.blog-tab {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 32px;
}
.blog-tab-item {
  font-size: 14px;
  line-height: 1.57;
  color: #000000;
  font-weight: bold;
  transition: color 0.2s ease;
  user-select: none;
}
.blog-tab-item:hover:not(.active) {
  color: #FF5B00;
}
.blog-tab-item.active {
  color: #FF5B00;
  position: relative;
  pointer-events: none;
  display: block;
}
.blog-tab-item.active::after {
  content: "";
  position: absolute;
  bottom: -100%;
  left: 0;
  height: 2.5px;
  width: 100%;
  background-color: #FF5B00;
  margin-bottom: 12px;
}
.blog-item {
  border-radius: 5px;
  border: 1px solid #ced4da;
  background-color: #ffffff;
  padding: clamp(0.875rem, -1rem + 3.906vw, 1.5rem);
  display: flex;
  align-items: center;
  gap: 20px;
}
.blog-item-image {
  height: 0;
  position: relative;
  padding-bottom: 34.15%;
  border-radius: 5px;
  flex-basis: 40.2%;
  flex-shrink: 0;
  flex-grow: 0;
}
.blog-item-image > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.blog-item-content {
  display: flex;
  flex-direction: column;
  gap: 16px;
  color: #000000;
  font-size: 14px;
}
.blog-item-content h2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
  border-radius: 5px;
  padding: 6px;
  background-color: rgba(108, 117, 124, 0.3);
  font-weight: 400;
  color: rgba(0, 0, 0, 0.5);
  font-size: 14px;
  width: fit-content;
}
.blog-item-content h1 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
  line-height: 1.4;
  font-weight: bold;
  text-transform: capitalize;
  font-size: 16px;
}
.blog-item-content p {
  color: #858484;
}
.blog-readmore {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  padding: 0 14px;
  background-color: #FF5B00;
  max-width: 270px;
  width: 100%;
  height: 42px;
  font-size: 16px;
  color: #ffffff;
  margin-top: 24px;
  font-weight: 500;
  transition: background-color 0.2s linear;
}
.blog-readmore:hover {
  background-color: #be4c0d;
  color: #ffffff;
}
@media only screen and (max-width: 1023px) {
  .blog-tab {
    justify-content: center;
    gap: 20px 24px;
  }
  .blog-item {
    display: block;
  }
  .blog-item-image {
    padding-bottom: 62.5%;
    margin-bottom: 10px;
  }
  .blog-item-content {
    gap: 11px;
  }
}

.menu-mobile {
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  max-width: 300px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  z-index: 200;
  overflow-y: auto;
  transition: transform 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
  background-color: #ffffff;
  transform: translateX(100%);
}
.menu-mobile.active {
  transform: translate(0);
}
.menu-mobile-header {
  padding: 18px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  color: #ffffff;
  background-color: #FF5B00;
  font-size: 15px;
}
.menu-mobile-icon {
  font-size: 16.8px;
  border-radius: 100rem;
  border: 1px solid #ffffff;
  width: 42px;
  height: 42px;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
}
.menu-mobile-auth {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}
.menu-mobile-auth button {
  color: inherit;
  color: #ffffff;
}
.menu-mobile-close {
  color: #ffffff;
  font-size: 32px;
}
.menu-mobile-nav {
  padding: 18px 20px;
  display: flex;
  flex-direction: column;
}
.menu-mobile-nav > li > a {
  padding: 12px 0;
}
.menu-mobile-nav li a {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
  color: #FF5B00;
  width: 100%;
  font-weight: 600;
  font-size: 15px;
  text-align: left;
}
.menu-mobile-nav:not(:last-child) {
  border-bottom: 1.5px solid rgba(204, 204, 204, 0.6);
}
.menu-mobile-nav .submenu {
  display: none;
  margin-left: 15px;
  background-color: #f3f3f3;
}
.menu-mobile-nav .submenu li a {
  padding: 15px;
}
.menu-mobile-contact {
  padding: 18px 20px;
}
.menu-mobile-contact li a {
  display: inline-block;
  text-decoration: none;
  color: #FF5B00;
  width: 100%;
  font-weight: 600;
  font-size: 15px;
  text-align: left;
  padding: 6.8px;
}
.menu-mobile-contact li a:before {
  content: "-";
  color: #FF5B00;
  margin-right: 8px;
}
@media only screen and (max-width: 1023px) {
  .menu-mobile {
    display: block;
  }
}

.form {
  background-color: #ffffff;
  max-width: 494px;
  width: 100%;
  z-index: 220;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 30px;
  display: none;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(1);
  animation: myAnim 1s ease 0s 1 normal forwards;
  max-height: 95vh;
  overflow-y: auto;
  overscroll-behavior: none;
  padding: 25px clamp(1.25rem, -0.08rem + 5.008vw, 3.125rem);
}
@keyframes myAnim {
  0% {
    transform: translate(-50%, -50%) scale(0.4);
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
  }
}
.form.show {
  display: block;
}
.form-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  border-bottom: 1.8px solid #FF5B00;
  padding-bottom: 15px;
  margin-bottom: 24px;
}
.form-header h3 {
  font-size: 18px;
  line-height: 1.56;
  font-weight: 700;
  text-transform: uppercase;
}
.form-group {
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-bottom: 24px;
}
.form-field {
  border-radius: 10px;
  padding: 10px 16px;
  border: 1.5px solid #ced4da;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}
.form-field input {
  width: 100%;
  border: none;
  outline: none;
  background-color: transparent;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 400;
}
.form-message {
  color: red;
  font-size: 12px;
  display: none;
  letter-spacing: 0.5px;
}
.form-link {
  font-size: 16px;
  line-height: 1.5;
  color: #FF5B00;
  font-weight: 500;
  text-decoration: underline;
  transition: opacity 0.2s ease;
}
.form-link:hover {
  opacity: 0.8;
}
.form-button {
  border-radius: 10px;
  padding: 9px 16px;
  background-color: #FF5B00;
  color: #ffffff;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 500;
  width: 100%;
  max-width: 338px;
  transition: background-color 0.2s ease;
  margin: 24px auto;
}
.form-button:hover {
  background-color: #f19866;
}
.form-divider {
  font-size: 16px;
  line-height: 1.5;
  font-weight: 400;
  display: flex;
  align-items: center;
}
.form-divider p {
  white-space: nowrap;
  padding: 0 10px;
}
.form-divider-line {
  width: 45px;
  height: 1.5px;
  background-color: #cccc;
}
.form-account {
  font-size: 16px;
  line-height: 1.5;
  font-weight: 400;
  display: flex;
  align-items: center;
  gap: 5px;
}
.form .form-check {
  font-size: 16px;
  line-height: 1.5;
  font-weight: 400;
}
@media only screen and (max-width: 767px) {
  .form {
    max-width: 100%;
    border-radius: unset;
    max-height: 100%;
    height: 100%;
  }
}

.footer {
  margin-top: clamp(2.5rem, -3.125rem + 11.719vw, 4.375rem);
  padding-top: clamp(1.75rem, -3.5rem + 10.938vw, 3.5rem);
  border-top: 1px solid #e5e1e1;
}
.footer-title {
  font-size: 20px;
  font-weight: 500;
  line-height: 24.2px;
  color: #000000;
  text-transform: uppercase;
  width: fit-content;
}
.footer-title::after {
  content: "";
  display: block;
  height: 1.5px;
  width: 80%;
  background-color: #e5e1e1;
  margin: 24px 0 34px 0;
}
.footer-line {
  padding-left: 26px;
  border-left: 3px solid #FF5B00;
}
.footer-company {
  margin-top: 30px;
}
.footer-company h1 {
  font-size: 20px;
  font-weight: 600;
  line-height: 24.2px;
  color: #FF5B00;
  text-transform: uppercase;
  margin-bottom: 14px;
}
.footer-company ul {
  display: flex;
  gap: clamp(0.875rem, -0.25rem + 2.344vw, 1.25rem);
  flex-direction: column;
}
.footer-company ul li {
  font-size: 18px;
  line-height: 1.1111111111;
}
.footer-company ul li b {
  color: #000000;
  font-weight: 600;
}
.footer-company ul li span {
  color: rgba(0, 0, 0, 0.46);
  font-weight: 400;
}
.footer-company ul li a {
  color: rgba(0, 0, 0, 0.46);
  transition: color 0.2s linear;
}
@media (hover: hover) {
  .footer-company ul li a:hover {
    color: #FF5B00;
  }
}
.footer-list {
  display: flex;
  flex-direction: column;
  gap: clamp(0.875rem, -1.75rem + 5.469vw, 1.75rem);
}
.footer-list li a {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  color: #403e3e;
  transition: color 0.2s linear;
}
@media (hover: hover) {
  .footer-list li a:hover {
    color: #FF5B00;
  }
}
.footer-payment h3 {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.3333333333;
  margin: 18px 0 16px 0;
}
.footer-social {
  margin: 16px 0;
}
.footer-social span {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.3333333333;
  color: #403e3e;
}
.footer-social ul {
  margin-top: 18px;
  display: flex;
  align-items: center;
  gap: 24px;
}
.footer-copyright {
  border-top: 1.5px solid #e5e1e1;
  padding: 24px 0 69px 0;
  margin-top: clamp(1.563rem, -3.125rem + 9.766vw, 3.125rem);
}
.footer-copyright span {
  font-size: clamp(1.125rem, 0.75rem + 0.781vw, 1.25rem);
  font-weight: 400;
  line-height: 24.2px;
  color: #000000;
}
@media only screen and (max-width: 1023px) {
  .footer-title::after {
    margin: 10px 0;
  }
  .footer-company {
    margin-top: 15px;
  }
}

.category-guide .home-title {
  margin-bottom: unset;
}
.category-guide-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 24px;
}
.category-guide .controls {
  display: flex;
  gap: 8px;
}
.category-guide .controls button {
  background-color: #FF5B00;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  outline: none;
  color: #ffffff;
  transition: all 0.2s ease-in;
}
.category-guide .controls button:hover {
  background-color: rgba(255, 91, 0, 0.5);
  color: #FF5B00;
}
.category-guide .card-item {
  position: relative;
  border-radius: 5px;
  overflow: hidden;
}
.category-guide .card-item:before {
  content: "";
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}
.category-guide .card-item-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 220px;
  width: 100%;
}
.category-guide .card-item-content h1 a {
  font-size: clamp(1.125rem, 0.919rem + 0.774vw, 1.5rem);
  line-height: 1.33;
  color: #ffffff;
  font-weight: bold;
  text-align: center;
  padding-bottom: 16px;
  position: relative;
  text-transform: uppercase;
  display: inline-block;
}
.category-guide .card-item-content h1 a:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100px;
  background-color: #FF5B00;
  height: 5px;
}
@media only screen and (max-width: 575px) {
  .category-guide-header {
    justify-content: center;
  }
  .category-guide .controls {
    display: none;
  }
}

.banner-promo {
  margin-top: 36px;
}
.banner-promo-bg {
  padding: 24px;
  border-radius: 10px;
  color: #ffffff;
  background-image: url("../images/subscribe-bg.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.banner-promo h1 {
  font-size: clamp(1.25rem, 0.807rem + 1.669vw, 1.875rem);
  font-weight: 700;
}
.banner-promo-button {
  font-family: Inter;
  font-size: 16px;
  line-height: 1.5;
  color: #FF5B00;
  font-weight: 400;
  border-radius: 4px;
  background-color: #ffffff;
  padding: 6.5px 15px;
  transition: all 0.2s ease;
}
.banner-promo-button:hover {
  background-color: #f7c2a3;
  color: #ffffff;
}
.banner-promo p {
  font-size: 20px;
}
.banner-promo p a {
  color: #ffffff;
  transition: color 0.2s ease;
}
.banner-promo p a:hover {
  color: #f7c2a3;
}

.we-guide-card {
  border-radius: 5px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.we-guide-card, .we-guide-card-image {
  overflow: hidden;
}
.we-guide-card-image {
  height: 238px;
}
.we-guide-card-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all 0.2s ease;
}
@media (hover: hover) {
  .we-guide-card-image:hover img {
    transform: scale(1.1);
    opacity: 0.8;
  }
}
.we-guide-card-content {
  padding: 20px 16px;
}
.we-guide-card-title {
  font-size: 20px;
  color: #FF5B00;
  font-weight: 700;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
}
.we-guide-card-label {
  font-size: 14px;
  color: #000;
  text-transform: capitalize;
  border-radius: 5px;
  width: fit-content;
  padding: 5px 8px;
  background-color: #e2dbd8;
  color: rgba(0, 0, 0, 0.5);
  font-weight: 500;
  margin: 8px 0;
  display: inline-block;
}
.we-guide-card-button {
  font-size: 16px;
  line-height: 1.5;
  color: #ffffff;
  font-weight: 400;
  background-color: #FF5B00;
  border-radius: 5px;
  padding: 8px 15px;
  background-color: #FF5B00;
  display: inline-block;
  text-align: center;
  width: 100%;
  transition: opacity 0.2s ease;
  margin-top: 8px;
}
.we-guide-card-button.readmore {
  max-width: 270px;
  margin: 24px auto 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.we-guide-card-button:hover {
  opacity: 0.8;
}

.container-guide {
  max-width: 1200px;
  width: 100%;
  padding: 0 12px;
  margin: 0 auto;
}

.top-guide {
  margin-top: 10px;
}
.top-guide-list {
  display: flex;
  white-space: nowrap;
  overflow-x: auto;
  padding-bottom: 14px;
  max-width: 100%;
  gap: 12px;
  align-items: center;
}
.top-guide-tab {
  border: 1.5px solid #FF5B00;
  border-radius: 5px;
  padding: 8px 16px;
  color: #FF5B00;
  font-size: 20px;
  font-weight: 400;
  transition: all 0.2s ease;
}
.top-guide-tab.active {
  background-color: #FF5B00;
  color: #ffffff;
  pointer-events: none;
}
@media (hover: hover) {
  .top-guide-tab:hover {
    background-color: #FF5B00;
    color: #ffffff;
  }
}
.top-guide-pane {
  display: none;
}
.top-guide-pane.active {
  display: block;
}
.top-guide-item {
  border-radius: 5px;
  overflow: hidden;
  position: relative;
}
.top-guide-item::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(241, 241, 241, 0.5);
}
.top-guide-item img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  display: inline-block;
}
.top-guide-content {
  display: flex;
  flex-direction: column;
  gap: 5px;
  text-align: left;
  padding: 12px;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 4;
}
.top-guide-content h3 {
  color: #FF5B00;
  font-size: 14px;
  font-weight: 500;
}
.top-guide-content p {
  font-size: 12px;
  color: #000000;
  font-weight: 400;
}
.top-guide .owl-carousel .owl-nav .owl-prev {
  left: 0;
}
.top-guide .owl-carousel .owl-nav .owl-next {
  right: 0;
}

.thumbnail-guide {
  height: 0;
  position: relative;
  padding-bottom: 53.25%;
  border-radius: 6px;
}
.thumbnail-guide img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.content-guide {
  color: #000000;
}
.content-guide-title {
  font-size: 20px;
  color: #FF5B00;
  font-weight: 700;
  margin-bottom: 15px;
}
.content-guide-name {
  margin: 24px 0 15px 0;
  font-size: 24px;
  font-weight: 700;
}
.content-guide-date {
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 15px;
}
.content-guide-description {
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 15px;
}
.content-guide-about {
  font-size: 16px;
  font-weight: 500;
}
.content-guide-list {
  list-style-type: disc;
  padding-left: 30px;
}
.content-guide-list li {
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 5px;
}

.info-guide,
.verification-guide {
  border-radius: 6px;
  background-color: #f5f5f5;
  padding: 24px 20px;
}

.info-guide-title {
  font-size: 20px;
  font-weight: 700;
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px dashed rgba(108, 117, 124, 0.4);
  color: #FF5B00;
}
.info-guide-line {
  margin-bottom: 16px;
  border-bottom: 1px dashed rgba(108, 117, 124, 0.4);
}
.info-guide-item {
  display: flex;
  align-items: flex-start;
  gap: 12px;
}
.info-guide-content {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.info-guide-content h4 {
  font-size: 16px;
  color: #FF5B00;
  font-weight: 500;
}
.info-guide-content p {
  font-size: 14px;
  color: #000000;
  font-weight: 400;
}
.info-guide-chat {
  margin-top: 16px;
  border-radius: 5px;
  padding: 8px;
  background-color: #fcf6f2;
  border: 1px solid #FF5B00;
  color: #FF5B00;
  font-size: 14px;
  font-weight: 700;
  display: inline-block;
  width: 100%;
  text-align: center;
  transition: all 0.2s ease;
}
.info-guide-chat:hover {
  background-color: #FF5B00;
  color: #ffffff;
}

.verification-guide h4 {
  font-size: 20px;
  color: #000000;
  font-weight: 700;
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px dashed rgba(108, 117, 124, 0.4);
}
.verification-guide-list {
  display: flex;
  flex-direction: column;
  gap: 14px;
}
.verification-guide-item {
  display: flex;
  align-items: center;
  gap: 20px;
}
.verification-guide-item span {
  font-size: 16px;
  color: #000000;
  font-weight: 400;
}

.carousel-card-guide .location-guide {
  display: flex;
  align-items: center;
  gap: 14px;
}
.carousel-card-guide .location-guide img {
  max-width: 20px;
}
@media only screen and (max-width: 1199px) {
  .carousel-card-guide {
    display: none !important;
  }
}

.dropdown-guide {
  border-radius: 5px;
  border: 1px solid rgba(34, 34, 34, 0.2);
  padding: 24px 20px;
}
.dropdown-guide h1 {
  font-size: 24px;
  color: #FF5B00;
  font-weight: 700;
}
.dropdown-guide-rating {
  display: flex;
  align-items: center;
  gap: 8px;
  margin: 12px 0;
}
.dropdown-guide-rating i {
  color: #ffc107;
}
.dropdown-guide-list {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.dropdown-guide-select {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  position: relative;
}
.dropdown-guide-select-input,
.dropdown-guide-select span {
  font-size: 14px;
  color: #FF5B00;
  font-weight: 500;
  width: 100%;
  display: inline-block;
  text-align: left !important;
  border: 1.5px solid #FF5B00;
  border-radius: 5px;
  padding: 8px 12px 8px 46px;
  background-color: #ffffff;
  cursor: pointer;
}
.dropdown-guide-select-input {
  outline: none;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
}
.dropdown-guide-select-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 12px;
}
.dropdown-guide-button {
  border-radius: 6px;
  padding: 10px 8px;
  background-color: #FF5B00;
  font-size: 14px;
  color: #ffffff;
  font-weight: 700;
  text-align: center;
  transition: opacity 0.2s ease-in;
}
.dropdown-guide-button:hover {
  opacity: 0.8;
}
.dropdown-guide #dropdown-guide-room {
  position: relative;
}
.dropdown-guide-room {
  position: absolute;
  top: calc(100% + 2px);
  left: 0;
  width: 100%;
  box-shadow: rgba(100, 100, 111, 0.2) 0 7px 29px 0;
  border: 1.8px solid #FF5B00;
  background-color: #ffffff;
  border-radius: 5px;
  overflow: hidden;
  z-index: 20;
  padding: 12px 24px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  display: none;
}
.dropdown-guide-room.active {
  display: flex;
}
.dropdown-guide-room-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}
.dropdown-guide-room-item-quantity {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  height: 25px;
}
.dropdown-guide-room-item-quantity button {
  border-radius: 5px;
  background-color: #f9f9f9;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 100%;
  user-select: none;
}
.dropdown-guide-room-item-quantity button img {
  width: 12px;
}
.dropdown-guide-room-item-quantity button:active {
  opacity: 0.6;
}
.dropdown-guide-room-item-quantity input {
  width: 30px;
  text-align: center;
  height: 100%;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  outline-color: #FF5B00;
  font-size: 14px;
}

.modal-guide-caption {
  font-size: 18px;
  text-transform: uppercase;
  color: #FF5B00;
  font-weight: 700;
}
.modal-guide-header {
  display: flex;
  align-items: center;
  gap: 20px;
}
.modal-guide-avatar {
  max-width: 80px;
  height: 80px;
  overflow: hidden;
  border-radius: 10rem;
  display: inline-block;
}
.modal-guide-avatar img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.modal-guide-content {
  font-size: 14px;
  color: #000000;
  font-weight: 400;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.modal-guide-content h1 {
  font-size: 20px;
  color: #FF5B00;
  font-weight: 700;
}
.modal-guide-content p {
  font-size: 14px;
  color: #000000;
  font-weight: 400;
}
.modal-guide-button {
  width: 100%;
  border-radius: 6px;
  padding: 14px;
  background-color: #FF5B00;
  color: #ffffff;
  font-size: 18px;
  font-weight: 700;
  text-align: center;
  transition: opacity 0.2s ease-in;
}
.modal-guide-button:hover {
  opacity: 0.8;
}

@media only screen and (max-width: 1023px) {
  .top-guide-tab {
    font-size: 16px;
    padding: 8px 12px;
  }
}
@media only screen and (max-width: 599px) {
  .content-guide-description,
.content-guide-list li {
    font-size: 16.5px;
  }

  .modal-guide-header {
    display: flex;
    flex-direction: column;
    text-align: center;
    gap: 15px;
  }

  .modal-guide-content {
    gap: 4px;
  }

  .modal-guide-caption,
.modal-guide-content h1 {
    font-size: 16.5px;
  }
}
.payment .summary {
  background-color: #f9f9f9;
  border-radius: 10px;
  overflow: hidden;
  border: 1px solid rgba(108, 117, 124, 0.2);
  margin-bottom: 24px;
}
.payment .summary-item {
  padding: 24px 16px;
  border-bottom: 1px solid rgba(108, 117, 124, 0.2);
}
.payment .summary-item-title {
  font-size: 16px;
  line-height: 1.21;
  color: #222222;
  font-weight: bold;
}
.payment .summary-item-title--primary {
  color: #FF5B00;
}
.payment .summary-item-date {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #6c757c;
}
.payment .summary-guide-thumbnail {
  height: 270px;
  border-top-left-radius: 18px;
  border-top-right-radius: 18px;
  overflow: hidden;
}
.payment .summary-guide-thumbnail img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.payment .summary-guide-content {
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  padding: 12px 12px 30px 12px;
  border: 1.5px solid rgba(108, 117, 124, 0.3);
}
.payment .summary-guide-avatar {
  max-width: 100px;
  height: 100px;
  border-radius: 100rem;
  overflow: hidden;
  margin: -63px auto 0 auto;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.payment .summary-guide-avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.payment .summary-guide-header {
  margin-top: 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.payment .summary-guide-header h1 {
  line-height: 1.5;
  font-size: 15px;
  color: #000000;
  font-weight: 500;
}
.payment .summary-guide-header p {
  font-size: 15px;
  color: #FF5B00;
  font-weight: bold;
}
.payment .summary-guide-date {
  margin: 20px 0 15px 0;
  display: flex;
  justify-content: space-between;
  gap: 10px;
  text-align: center;
}
.payment .summary-guide-date-item {
  flex: 1;
  border-radius: 5px;
  border: 1.8px solid #FF5B00;
  padding: 8px;
  display: flex;
  flex-direction: column;
  gap: 6px;
}
.payment .summary-guide-date-item span {
  font-size: 14px;
  color: #a5a5a5;
  font-weight: 400;
}
.payment .summary-guide-date-item span.font-bold {
  font-weight: bold;
}
.payment .summary-guide-date-item b {
  font-size: 14px;
  color: #000000;
  font-weight: 500;
}
.payment .summary-guide-info {
  display: flex;
  justify-content: space-between;
  gap: 12px;
}
.payment .summary-guide-info-item {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  border: 1.5px solid #FF5B00;
  border-radius: 5px;
  padding: 5px;
  flex: 1;
  font-size: 12px;
  color: #FF5B00;
  font-weight: 500;
}
.payment .summary-guide-footer {
  border-top: 1px solid rgba(108, 117, 124, 0.2);
  padding-top: 20px;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.payment .summary-guide-footer-item {
  display: flex;
  justify-content: space-between;
  gap: 14px;
  align-items: center;
}
.payment .summary-guide-footer-item b {
  font-size: 14px;
  color: #000000;
}
.payment .summary-guide-footer-item span {
  font-weight: bold;
  font-size: 14px;
  color: #6c757c;
}
.payment .summary-guide-footer-item span.font-primary {
  color: #FF5B00;
}
.payment-box {
  padding: 25px 20px;
  border-radius: 10px;
  border: 1px solid rgba(108, 117, 124, 0.2);
  background-color: #f9f9f9;
}
.payment-line {
  border: 1.5px dashed #938d8d;
  margin: 24px 0;
}
.payment-infoDetail {
  display: grid;
  gap: 15px;
  grid-template-columns: repeat(2, 1fr);
  font-weight: 400;
  line-height: 16.94px;
}
.payment-infoDetail-title {
  font-size: 15px;
  color: #191919;
}
.payment-infoDetail-price {
  font-size: 16px;
  color: #b5b5b5;
  text-align: right;
}
.payment-infoDetail--second {
  gap: 60px;
  width: fit-content;
}
.payment-total {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.payment-total-item {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 6px;
  font-weight: 600;
  font-size: 14px;
  line-height: 16.94px;
  color: #222222;
}
.payment-total-price {
  color: #7c7c7c;
  text-align: right;
}
.payment-total-price--primary {
  color: #FF5B00;
}
.payment .heading {
  font-weight: 700;
  font-size: 20px;
  line-height: 24.2px;
  color: #FF5B00;
}
.payment .button-primary {
  color: #ffffff;
  font-size: 20px;
  font-weight: 700;
  line-height: 1.2;
  border-radius: 3px;
  text-decoration: none;
  padding: 12px;
  background-color: #FF5B00;
  width: fit-content;
  border: none;
  transition: opacity 0.2s linear;
}
.payment .button-primary:hover {
  opacity: 0.8;
}
.payment .message {
  font-weight: 400;
  font-size: 16px;
  line-height: 1.21875;
  padding: 22px;
  border-radius: 5px;
  border: 1px solid #FF5B00;
  background-color: #fff4ef;
}
.payment-form-group label {
  margin-bottom: 12px;
  font-weight: 600;
  font-size: 16px;
  line-height: 1.1875;
}
.payment-form-group label span {
  color: #ff0000;
}
.payment-info {
  gap: 24px;
}
.payment-info-course {
  margin-top: 24px;
  border: 1.8px solid #FF5B00;
  border-radius: 10px;
  padding: 16px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  background-color: #ffffff;
}
@media only screen and (max-width: 599px) {
  .payment-info-course {
    flex-direction: column;
  }
}
.payment-info-course .stati-quantity {
  display: flex;
  gap: 12px;
}
.payment-info-course .stati-quantity button {
  width: 40px;
  height: 40px;
  background-color: #f9f9f9;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  border: none;
}
.payment-info-course .stati-quantity button:active {
  background-color: #f7e8e8;
}
.payment-info-course .quantity-value {
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  font-weight: 600;
  font-size: 20px;
  line-height: 24.2px;
  width: 55px;
  height: 40px;
  text-align: center;
}
.payment-info-course .quantity-value::-webkit-inner-spin-button, .payment-info-course .quantity-value::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.payment-info-course .quantity-value[type=number] {
  -moz-appearance: textfield;
}
.payment-info-image {
  width: 100px;
  height: 100px;
  border-radius: 5px;
}
.payment-info-image span {
  font-weight: 400;
  font-size: 16px;
  line-height: 1.1875;
}
.payment-info-content {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.payment-info-content h3 {
  font-weight: 600;
  font-size: 16px;
  line-height: 1.1875;
}
.payment-info-content p {
  font-weight: 400;
  font-size: 14px;
  line-height: 1.1428571429;
  color: #222222;
  margin-top: auto;
}
@media only screen and (max-width: 599px) {
  .payment-info-content p {
    margin-top: unset;
  }
}
.payment-method .custom-modal-width {
  max-width: 800px;
}
.payment-method .customer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}
.payment-method .customer button {
  background-color: transparent;
  border: none;
  font-weight: 500;
  font-size: 24px;
  line-height: 1.2291666667;
  color: #222222;
  transition: color 0.2s linear;
  text-decoration-line: underline;
}
.payment-method .customer button:hover {
  color: #FF5B00;
}
@media only screen and (max-width: 599px) {
  .payment-method .customer {
    flex-direction: column;
  }
}
.payment-method .terms {
  padding: 24px;
  margin-top: 32px;
  border: 1.5px solid #FF5B00;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
}
.payment-method .terms-price {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}
.payment-method .terms-price b {
  color: #FF5B00;
  font-weight: 700;
  font-size: 24px;
  line-height: 1.2291666667;
}
.payment-method .terms-price a {
  text-decoration: none;
  background-color: #FF5B00;
  border-radius: 3px;
  padding: 12px;
  font-weight: 700;
  font-size: 20px;
  color: #ffffff;
  line-height: 1.21;
  text-align: center;
  transition: opacity 0.2s linear;
}
.payment-method .terms-price a:hover {
  opacity: 0.8;
}
.payment-method .timer {
  font-weight: 500;
  font-size: 16px;
  line-height: 1.21;
  margin-bottom: 20px;
  color: inherit;
}
.payment-method .timer b {
  font-weight: bold;
  font-size: 24px;
  line-height: 1.2291666667;
  color: #FF5B00;
}
.payment-method .payment-select {
  border-radius: 5px;
  padding: 16px;
  border: 1px solid rgba(0, 0, 0, 0.5);
  font-weight: 400;
  font-size: 16px;
  line-height: 1.21;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}
.payment-method .payment-select input,
.payment-method .payment-select label {
  cursor: pointer;
}
.payment-method .payment-detail {
  display: none;
  padding: 24px;
  border-radius: 5px;
  border: 1px solid #ccc;
  width: 100%;
}
.payment-method .payment-detail h4 {
  font-weight: 500;
  font-size: 16px;
  line-height: 1.21;
  color: #222222;
}
.payment-method .payment-detail ul {
  margin-top: 15px;
  padding-left: 25px;
  list-style-type: none;
}
.payment-method .payment-detail ul li {
  font-weight: 400;
  font-size: 16px;
  line-height: 1.21;
  color: #222222;
}
.payment-method .payment-detail ul li:not(:last-child) {
  margin-bottom: 12px;
}
.payment-method .payment-detail .qr-image {
  max-width: 255px;
  height: 186px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}
.payment-success h1 {
  color: #222222;
  font-weight: 600;
  font-size: clamp(1.563rem, 0.236rem + 3.538vw, 2.5rem);
  line-height: 48.41px;
}
.payment-success .order-detail-title {
  margin-bottom: 16px;
  color: #FF5B00;
  font-weight: 500;
  font-size: 24px;
  line-height: 29.5px;
}
.payment-success .order-detail-list {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-bottom: 16px;
  margin-bottom: 16px;
  border-bottom: 1px solid rgba(108, 117, 124, 0.2);
}
.payment-success .order-detail-item {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 20px;
}
.payment-success .order-detail-item span {
  font-weight: 500;
  font-size: 16px;
  line-height: 19.36px;
  color: #222222;
}
.payment-success .order-detail-item span:first-child {
  justify-self: start;
  text-align: left;
}
.payment-success .order-detail-item span:last-child {
  justify-self: end;
  text-align: right;
}

.post-moment-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  margin-bottom: 25px;
}
.post-moment-header-title {
  font-size: 28px;
  color: #FF5B00;
  font-weight: 700;
}
.post-moment-header-button {
  font-size: 20px;
  font-weight: 700;
  border-radius: 10px;
  padding: 8px 16px;
  background-color: #FF5B00;
  color: #ffffff;
  display: inline-block;
  transition: opacity 0.2s ease;
}
@media (hover: hover) {
  .post-moment-header-button:hover {
    opacity: 0.8;
  }
}
.post-moment-card-image {
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  overflow: hidden;
  height: 200px;
  width: 100%;
}
@media (hover: hover) {
  .post-moment-card-image:hover img {
    transform: scale(1.1);
    opacity: 0.8;
  }
}
.post-moment-card-image img {
  transition: all 0.8s cubic-bezier(0.075, 0.82, 0.165, 1);
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.post-moment-content {
  border: 1.5px solid rgba(108, 117, 124, 0.2);
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  padding: 24px 20px;
}
.post-moment-content-title {
  font-size: 15px;
  color: #000000;
  font-weight: bold;
  margin-bottom: 15px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
  transition: color 0.2s ease;
}
.post-moment-content-title:hover {
  color: #FF5B00;
}
.post-moment-content-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
  white-space: nowrap;
}
.post-moment-content-time, .post-moment-content-view {
  font-size: 12px;
  color: #6c757c;
  font-weight: 400;
}
.post-moment-content-view {
  display: flex;
  align-items: center;
  gap: 6px;
}
.post-moment-readmore {
  display: flex;
  justify-content: center;
  margin-top: 24px;
}
.post-moment-readmore a {
  font-size: 20px;
  color: #FF5B00;
  font-weight: 700;
  border: 1.5px solid #FF5B00;
  border-radius: 10px;
  padding: 8px 16px;
  font-size: 20px;
  color: #FF5B00;
  font-weight: 700;
  transition: all 0.2s linear;
}
.post-moment-readmore a:hover {
  background-color: #FF5B00;
  color: #ffffff;
}

.banner-moment {
  background-image: url("../images/banner-header.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: clamp(31.25rem, 20.313rem + 14.583vw, 37.813rem);
  position: relative;
}

.search-moment {
  position: absolute;
  bottom: 75px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #ffffff;
  padding: 28px 40px;
  border-radius: 6px;
  max-width: 878px;
  width: calc(100% - 28px);
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  gap: 20px;
}
.search-moment-item {
  display: flex;
  gap: 10px;
  align-items: flex-start;
}
.search-moment-item-button {
  border-radius: 10px;
  padding: 16px;
  background-color: #FF5B00;
  font-size: 20px;
  color: #ffffff;
  font-weight: 700;
  display: inline-block;
  width: 100%;
  text-align: center;
  transition: opacity 0.2s ease;
}
@media (hover: hover) {
  .search-moment-item-button:hover {
    opacity: 0.8;
  }
}
.search-moment-content {
  display: flex;
  flex-direction: column;
  gap: 6px;
  width: 100%;
}
.search-moment-content h4 {
  font-size: 16px;
  color: #000000;
  font-weight: 600;
  line-height: normal;
}
.search-moment-content input {
  font-family: inherit;
  color: #000000;
  outline: none;
  border: none;
  font-size: 16px;
}
.search-moment-content input::placeholder {
  color: #FF5B00;
}
.search-moment-dropdown {
  display: flex;
  gap: 5px;
  align-items: center;
  cursor: pointer;
  position: relative;
  width: 75%;
}
.search-moment-dropdown span {
  font-size: 16px;
  color: #FF5B00;
  font-weight: 400;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
}
.search-moment-dropdown-list {
  position: absolute;
  top: calc(100% + 6px);
  left: 0;
  width: 100%;
  background-color: #ffffff;
  max-height: 200px;
  overflow-y: auto;
  overscroll-behavior: none;
  display: none;
  z-index: 50;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.search-moment-dropdown-list::-webkit-scrollbar {
  width: 5px;
}
.search-moment-dropdown-list::-webkit-scrollbar-track {
  background-color: rgba(108, 117, 124, 0.2);
}
.search-moment-dropdown-list::-webkit-scrollbar-thumb {
  background-color: #FF5B00;
  border-radius: 6px;
}
.search-moment-dropdown-list li {
  font-size: 15px;
  line-height: 1.5;
  color: #000000;
  padding: 8.5px 16px;
  text-transform: capitalize;
  transition: all 0.2s ease;
  width: 100%;
}
.search-moment-dropdown-list li:not(:first-child) {
  border-top: 1px solid rgba(108, 117, 124, 0.2);
}
@media (hover: hover) {
  .search-moment-dropdown-list li:hover {
    background-color: #ff5b00;
    color: #fff;
  }
}

.detail-moment-thumbnail {
  height: 0;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  padding-bottom: 56.25%;
}
.detail-moment-thumbnail img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.detail-moment-thumbnail-heart {
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: #ffffff;
  border-radius: 100rem;
  width: 40px;
  height: 40px;
  margin: 24px;
  cursor: pointer;
}
.detail-moment-thumbnail-heart i {
  position: absolute;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #FF5B00;
  font-size: 18.5px;
}
.detail-moment-caption {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 25px;
  padding-bottom: 30px;
  border-bottom: 1.5px dashed #FF5B00;
}
.detail-moment-user {
  display: flex;
  align-items: center;
  gap: 20px;
}
.detail-moment-user img {
  width: 50px;
  height: 50px;
  border-radius: 100rem;
}
.detail-moment-user h4 {
  font-size: 24px;
  color: #000000;
  font-weight: 700;
}
.detail-moment-button {
  font-size: 20px;
  color: #ffffff;
  font-weight: 700;
  border-radius: 10px;
  padding: 8px 16px;
  background-color: #FF5B00;
  transition: opacity 0.2s ease;
}
.detail-moment-button:hover {
  opacity: 0.8;
}
.detail-moment-content-title {
  font-size: 20px;
  color: #000000;
  font-weight: 700;
}
.detail-moment-content-time {
  margin: 15px 0;
  display: block;
  font-size: 12px;
  color: #6c757c;
  font-weight: 400;
}
.detail-moment-content-description {
  font-size: 20px;
  color: #000000;
  font-weight: 400;
  line-height: 1.6;
}
.detail-moment-content-image {
  height: 0;
  position: relative;
  padding-bottom: 43.95%;
  border-radius: 5px;
  overflow: hidden;
  margin: 16px 0 20px 0;
}
.detail-moment-content-image img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.detail-moment-comment {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.detail-moment-comment h1 {
  font-size: 20px;
  color: #000000;
  font-weight: 700;
}
.detail-moment-comment p {
  color: #000000;
  font-size: 16px;
  font-weight: 400;
}
.detail-moment-comment p a {
  color: #FF5B00;
  transition: opacity 0.2s ease;
}
.detail-moment-comment p a:hover {
  opacity: 0.8;
}
.detail-moment-related {
  margin-top: 30px;
}
.detail-moment-related h1 {
  font-size: 20px;
  color: #000000;
  font-weight: 700;
  margin-bottom: 24px;
}

@media only screen and (max-width: 1023px) {
  .detail-moment-content-description {
    font-size: 18px;
  }

  .detail-moment-content-image {
    padding-bottom: 56.25%;
  }
}
@media only screen and (max-width: 767px) {
  .detail-moment-content-description {
    font-size: 16px;
  }

  .post-moment-header {
    flex-direction: column;
  }
  .post-moment-header-title {
    font-size: 22px;
  }
  .post-moment-header-button {
    font-size: 18px;
  }

  .post-moment-readmore a {
    font-size: 18px;
  }

  .banner-moment {
    height: 400px;
  }

  .search-moment {
    grid-template-columns: 1fr;
    padding: 25px 30px;
  }
  .search-moment-item-button {
    padding: 10px;
    font-size: 17px;
    letter-spacing: 1px;
  }
  .search-moment-item img {
    display: none;
  }
  .search-moment-dropdown {
    width: 100%;
  }
}
@media only screen and (max-width: 599px) {
  .detail-moment-user h4,
.detail-moment-button {
    font-size: 18px;
  }
}
@media only screen and (max-width: 424px) {
  .detail-moment-caption {
    flex-direction: column;
    gap: 8px;
  }

  .detail-moment-caption {
    padding-bottom: 20px;
  }
}
@media only screen and (max-width: 374px) {
  .search-moment {
    padding: 20px;
  }

  .post-moment-content-footer {
    flex-direction: column;
  }
}
.detail-member-profile {
  display: flex;
  align-items: center;
  gap: 25px;
  border-radius: 10px;
  border: 1.5px solid #FF5B00;
  padding: 20px 24px;
  margin-bottom: 32px;
  position: relative;
}
.detail-member-profile-avatar {
  width: 100px;
  height: 100px;
}
.detail-member-profile-avatar img {
  object-fit: cover;
  border-radius: 50%;
  width: 100%;
  height: 100%;
}
.detail-member-profile-info {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.detail-member-profile-name {
  display: flex;
  align-items: center;
  gap: 15px;
  font-size: 32px;
  color: #000000;
  font-weight: bold;
}
.detail-member-profile-list {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px 15px;
  flex-wrap: wrap;
}
.detail-member-profile-list li {
  display: flex;
  align-items: center;
  gap: 8px;
  border-right: 1.5px solid #d9d9d9;
  padding-right: 15px;
}
.detail-member-profile-list li:last-child {
  border-right: none;
  padding-right: 0;
}
.detail-member-profile-pencil {
  cursor: pointer;
  position: absolute;
  bottom: 1.5rem;
  right: 1.5rem;
}
.detail-member-post {
  font-size: 20px;
  color: #ffffff;
  font-weight: 700;
  border-radius: 10px;
  padding: 8px 16px;
  background-color: #FF5B00;
  display: inline-block;
  transition: opacity 0.2s ease;
}
@media (hover: hover) {
  .detail-member-post:hover {
    opacity: 0.8;
  }
}
.detail-member-intro {
  padding: 24px 20px;
  border: 1.5px solid #FF5B00;
  border-radius: 10px;
}
.detail-member-intro h1 {
  font-size: 20px;
  color: #FF5B00;
}
.detail-member-intro ul {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.detail-member-intro ul li {
  display: flex;
  align-items: center;
  gap: 20px;
}
.detail-member-intro ul li a {
  font-size: 16px;
  color: #000000;
  font-weight: 400;
  transition: color 0.2s ease;
  display: inline-block;
}
@media (hover: hover) {
  .detail-member-intro ul li a:hover {
    color: #FF5B00;
  }
}
.detail-member-modal-title {
  font-size: 24px;
  color: #FF5B00;
  font-weight: 700;
}

@media only screen and (max-width: 1023px) {
  .detail-member-modal-title {
    font-size: 20px;
  }

  .detail-member-post {
    font-size: 18px;
  }
}
@media only screen and (max-width: 767px) {
  .detail-member-profile {
    flex-direction: column;
    justify-content: center;
    text-align: center;
    margin-bottom: 25px;
  }
  .detail-member-profile-info {
    align-items: center;
  }
  .detail-member-profile-pencil {
    right: 1rem;
    bottom: 1rem;
  }
  .detail-member-profile-name h1 {
    font-size: 20px;
  }
}
.search-col {
  border: 1.5px solid #FF5B00;
  border-radius: 5px;
  padding: 24px 20px;
}
.search-col-section:not(:last-child) {
  margin-bottom: 24px;
}
.search-col span {
  font-size: 16px;
  font-weight: 400;
}
.search-col-header {
  display: flex;
  align-items: center;
  gap: 15px;
  justify-content: space-between;
  margin-bottom: 15px;
  padding-bottom: 15px;
  border-bottom: 2.5px solid #FF5B00;
  user-select: none;
  cursor: pointer;
}
.search-col-header h4 {
  font-size: 18px;
  color: #FF5B00;
  font-weight: bold;
}
.search-col-main {
  position: relative;
  width: 100%;
  padding-right: 34px;
}
.search-col-icon {
  right: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.search-col-content.active {
  display: block;
}
.search-col .stars {
  display: flex;
  flex-direction: column;
  gap: 11px;
}
.search-col .stars .stars-label {
  display: flex;
  align-items: center;
  gap: 8px;
}
.search-col .stars .stars-label .fa-solid {
  font-size: 18px;
  color: #fadb14;
}
.search-col .stars .stars-label .fa-regular {
  font-size: 18px;
  color: #ccc;
}
.search-col .price-range-values {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: space-between;
  font-weight: 400;
  font-size: 12px !important;
}
.search-col .price-range-input {
  height: 15px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 100%;
}
.search-col .price-range-input::-webkit-slider-runnable-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  animate: 0.2s;
  box-shadow: 0px 0px 0px #000000;
  background: #ededed;
  border-radius: 50px;
  border: 0px solid #ffffff;
}
.search-col .price-range-input::-webkit-slider-thumb {
  box-shadow: 0px 0px 0px #000000;
  border: 3px solid #FF5B00;
  height: 18px;
  width: 18px;
  border-radius: 25px;
  background: #ffffff;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -7px;
}
.search-col .price-range-input::-moz-range-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  animate: 0.2s;
  box-shadow: 0px 0px 0px #000000;
  background: #ededed;
  border-radius: 50px;
  border: 0px solid #ffffff;
}
.search-col .price-range-input::-moz-range-thumb {
  box-shadow: 0px 0px 0px #000000;
  border: 3px solid #FF5B00;
  height: 18px;
  width: 18px;
  border-radius: 25px;
  background: #ffffff;
  cursor: pointer;
}
.search-col .price-range-input::-ms-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  animate: 0.2s;
  box-shadow: 0px 0px 0px #000000;
  background: transparent;
  border-color: transparent;
  color: transparent;
}
.search-col .price-range-input::-ms-fill-lower {
  background: #ededed;
  border: 0px solid #ffffff;
  border-radius: 50px;
}
.search-col .price-range-input::-ms-fill-upper {
  background: #ededed;
  border: 0px solid #ffffff;
  border-radius: 50px;
}
.search-col .price-range-input::-ms-thumb {
  box-shadow: 0px 0px 0px #000000;
  border: 3px solid #FF5B00;
  height: 18px;
  width: 18px;
  border-radius: 25px;
  background: #ffffff;
  cursor: pointer;
}

.search-page-sort {
  display: flex;
  align-items: center;
  gap: 10px;
}
.search-page-sort-text {
  font-size: 16px;
  color: #000000;
  font-weight: 500;
}
.search-page-sort-button {
  border: 1px solid #FF5B00;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border-radius: 6px;
  padding: 8px 16px;
  color: #FF5B00;
  font-size: 14px;
  line-height: 1.57;
  font-weight: 400;
  position: relative;
}
.search-page-sort-button i {
  font-size: 16px;
}
.search-page-sort-dropdown {
  position: absolute;
  z-index: 50;
  top: calc(100% + 6.5px);
  left: 0;
  width: 100%;
  width: 240px;
  border-radius: 10px;
  border: 1px solid #FF5B00;
  background-color: #ffffff;
  flex-direction: column;
  gap: 16px;
  padding: 20px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  display: none;
}
.search-page-sort-dropdown.active {
  display: flex;
}
.search-page-sort-dropdown li {
  text-align: left;
  font-size: 16px;
  color: #FF5B00;
  font-weight: 400;
  cursor: pointer;
}
.search-page-sort-dropdown li:hover {
  color: #ffc240;
}
.search-page-map {
  border-radius: 6px;
  padding: 8px 16px;
  background-color: #f5f5f5;
  display: inline-flex;
  align-items: center;
  gap: 8px;
}
.search-page-map span {
  font-size: 14px;
  line-height: 1.57;
  color: #FF5B00;
  font-weight: 400;
}
.search-page-result {
  font-size: 14px;
  line-height: 1.57;
  color: #000000;
  font-weight: 500;
  border-bottom: 2px solid #FF5B00;
  padding-bottom: 20px;
  margin-bottom: 20px;
}
.search-page-filter {
  padding-bottom: 24px;
  margin-bottom: 24px;
  border-bottom: 1px solid #FF5B00;
}
.search-page-filter-button {
  font-size: 16px;
  line-height: 1.5;
  color: #ffffff;
  font-weight: 400;
  background-color: #FF5B00;
  border-radius: 5px;
  padding: 8px 15px;
  text-align: center;
  transition: opacity 0.2s ease;
  width: 100%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  transition: opacity 0.2s ease;
}
.search-page-filter-button:hover {
  opacity: 0.8;
}
.search-page-filter-modalButton {
  font-size: 16px;
  line-height: 1.5;
  color: #ffffff;
  font-weight: 500;
  border-radius: 10px;
  padding: 8px 16px;
  transition: opacity 0.2s ease;
}
.search-page-filter-modalButton--result {
  background-color: #FF5B00;
}
.search-page-filter-modalButton--delete {
  background-color: #6c757d;
}
.search-page-filter-modalButton:hover {
  opacity: 0.8;
}

@media only screen and (max-width: 991px) {
  .search-col {
    border: unset;
  }

  #search-page-filterMobile {
    position: fixed;
    left: 0;
    top: 0;
    max-width: 300px;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    transition: transform 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    transform: translateX(-100%);
    z-index: 400;
    background-color: #ffffff;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }
  #search-page-filterMobile.show {
    transform: translateX(0);
  }
}
@media only screen and (max-width: 424px) {
  .search-page-sort-dropdown {
    right: 0;
    left: unset;
  }
}
.hotel-list-resortTab {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  gap: clamp(0.313rem, -0.53rem + 3.172vw, 1.5rem);
  max-width: 100%;
  width: 100%;
  overflow-x: auto;
  padding-bottom: 15px;
}
.hotel-list-resortTab-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  padding: 8px 21px;
  cursor: pointer;
  user-select: none;
  font-size: clamp(0.938rem, 0.757rem + 0.77vw, 1.25rem);
  font-weight: bold;
  color: #000000;
}
.hotel-list-resortTab-item.active {
  pointer-events: none;
  border-bottom: 2px solid #FF5B00;
  color: #FF5B00;
}
.hotel-list-resortTab-item img {
  max-width: clamp(2.188rem, 1.522rem + 2.504vw, 3.125rem);
}
.hotel-list-resortPane {
  margin-top: 15px;
  display: none;
}
.hotel-list-resortPane.active {
  display: block;
}

.top-destinations-title {
  font-size: 20px;
  color: #FF5B00;
  font-weight: 700;
}
.top-destinations-subtitle {
  font-size: 16px;
  color: #909197;
  font-weight: 700;
  margin: 16px 0 30px 0;
}
.top-destinations-item-image {
  border-radius: 6px;
  overflow: hidden;
  height: 0;
  position: relative;
  padding-bottom: 81.55%;
}
@media (hover: hover) {
  .top-destinations-item-image:hover img {
    transform: scale(1.1);
    opacity: 0.8;
  }
}
.top-destinations-item img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all 0.3s ease;
}
.top-destinations-item-name {
  font-size: 20px;
  color: #000000;
  font-weight: 700;
  display: inline-block;
  width: fit-content;
  transition: color 0.2s ease;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
}
.top-destinations-item-name:hover {
  color: #FF5B00;
}
.top-destinations-item-info {
  font-size: 16px;
  color: #909197;
  font-weight: 400;
}

.list-hotelDetail {
  margin-top: 20px;
  padding-top: 36px;
  border-top: 1.5px solid #d9d9d9;
}
.list-hotelDetail-tag {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 12px;
}
.list-hotelDetail-tag li a {
  border-radius: 5px;
  background-color: rgba(108, 117, 124, 0.2);
  padding: 5px 8px;
  font-size: 14px;
  color: #4e4c4c;
  font-weight: 400;
  display: inline-block;
  transition: opacity 0.2s ease;
}
.list-hotelDetail-tag li a:hover {
  opacity: 0.8;
}
.list-hotelDetail-gallery {
  margin-top: 25px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 10px;
  height: 460px;
}
.list-hotelDetail-gallery-item {
  border-radius: 6px;
  overflow: hidden;
  cursor: zoom-in;
  display: inline-block;
}
.list-hotelDetail-gallery-item:nth-child(n+4) {
  display: none;
}
.list-hotelDetail-gallery-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.list-hotelDetail-gallery-item:first-child {
  grid-column: 1/4;
  grid-row: 1/3;
}
.list-hotelDetail-gallery-item:nth-child(2) {
  grid-column: 4/5;
  grid-row: 1/2;
}
.list-hotelDetail-gallery-item:nth-child(3) {
  grid-column: 4/5;
  grid-row: 2/3;
}
.list-hotelDetail-desc {
  border-radius: 10px;
  background-color: #fcf6f2;
  padding: 20px 24px;
}
.list-hotelDetail-room {
  border-radius: 10px;
  border: 1.5px solid #d9d9d9;
  padding: 24px 12px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.list-hotelDetail-room-title {
  font-size: 14px;
  color: #000000;
  font-weight: 400;
}
.list-hotelDetail-room-price {
  font-family: Inter;
  font-size: 24px;
  color: #FF5B00;
  text-transform: uppercase;
  font-weight: 700;
}
.list-hotelDetail-room-button {
  border-radius: 4px;
  padding: 10px 8px;
  background-color: #FF5B00;
  font-size: 14px;
  color: #ffffff;
  font-weight: 700;
  display: inline-block;
  transition: opacity 0.2s ease;
  text-align: center;
}
.list-hotelDetail-room-button:hover {
  opacity: 0.8;
}
.list-hotelDetail-searchRoom {
  margin-top: 24px;
  padding: 16px 20px;
  border-radius: 10px;
  padding: 16px 20px;
  background-color: rgba(249, 249, 249, 0.9);
}
.list-hotelDetail-searchRoom-caption {
  font-size: 16px;
  line-height: 1.5;
  color: #FF5B00;
  font-weight: bold;
  margin-bottom: 24px;
}
.list-hotelDetail-searchRoom-list {
  display: grid;
  grid-template-columns: 1.7fr 1fr 1fr 1fr;
  gap: 12px;
}
.list-hotelDetail-searchRoom-item {
  border: 1px solid #FF5B00;
  border-radius: 6px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
}
.list-hotelDetail-searchRoom-title {
  color: #000000;
  font-weight: 500;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 12px;
  white-space: nowrap;
}
.list-hotelDetail-searchRoom-title i {
  font-size: 15px;
}
.list-hotelDetail-searchRoom-date {
  color: #FF5B00;
  font-weight: 600;
  font-size: 14px;
  outline: none;
  border: none;
  text-align: center;
}
.list-hotelDetail-searchRoom-button {
  border-radius: 5px;
  padding: 8px;
  background-color: #FF5B00;
  font-size: 20px;
  line-height: 1.1;
  color: #ffffff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-weight: 500;
  transition: opacity 0.2s ease;
  white-space: nowrap;
}
.list-hotelDetail-searchRoom-button:hover {
  opacity: 0.8;
}
.list-hotelDetail .services-detail {
  border-radius: 10px;
  background-color: #f5f5f5;
  padding: 24px 20px;
  margin-top: 24px;
}
.list-hotelDetail .services-detail-caption {
  font-size: 20px;
  color: #000000;
  font-weight: 700;
  padding-bottom: 16px;
  margin-bottom: 16px;
  border-bottom: 1.5px dashed rgba(108, 117, 124, 0.2);
}
.list-hotelDetail .services-detail-title {
  font-size: 16px;
  color: #FF5B00;
  font-weight: bold;
  margin-bottom: 16px;
}
.list-hotelDetail .services-detail-list {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.list-hotelDetail .services-detail-list:not(:last-child) {
  margin-bottom: 24px;
}
.list-hotelDetail .services-detail-list li {
  display: flex;
  align-items: center;
  gap: 25px;
}
.list-hotelDetail .services-detail-list li span {
  font-size: 16px;
  color: #000000;
  font-weight: 400;
}
.list-hotelDetail .services-detail-buttonMobile {
  padding: 8px;
  background-color: #ffffff;
  font-size: 20px;
  color: #FF5B00;
  display: inline-block;
  text-align: center;
  border-radius: 5px;
  border: 1px solid #FF5B00;
  font-weight: 700;
  width: fit-content;
}
@media only screen and (max-width: 991px) {
  .list-hotelDetail .services-detail {
    position: fixed;
    top: 0;
    right: 0;
    max-width: 300px;
    width: 100%;
    height: 100vh;
    transform: translateX(100%);
    transition: transform 0.3s ease;
    background-color: #ffffff;
    z-index: 200;
    overflow-y: auto;
    border-radius: unset;
    margin-top: unset;
  }
  .list-hotelDetail .services-detail.show {
    transform: translateX(0);
  }
}

.room-detail {
  border-bottom: 1.5px solid #FF5B00;
  padding-bottom: 30px;
  margin-bottom: 30px;
}
.room-detail-thumbnail {
  height: 0;
  position: relative;
  padding-bottom: 70.85%;
  border-radius: 8px;
  overflow: hidden;
}
.room-detail-thumbnail img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.room-detail-button {
  border-radius: 5px;
  padding: 8px;
  background-color: #fff4ef;
  font-size: 14px;
  color: #FF5B00;
  font-weight: 700;
  text-align: center;
  transition: background-color 0.2s ease;
}
.room-detail-button:hover {
  background-color: #ffe1d3;
}
.room-detail-content-title {
  font-size: clamp(1rem, 0.583rem + 1.778vw, 1.25rem);
  color: #000000;
  font-weight: 700;
  margin-bottom: 16px;
}
.room-detail-content-list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 14px 24px;
  padding-bottom: 16px;
  margin-bottom: 16px;
  border-bottom: 1px solid #e1dcd9;
}
.room-detail-content-list li {
  display: flex;
  align-items: center;
  gap: 8px;
}
.room-detail-content-listSecond {
  display: flex;
  flex-wrap: wrap;
  gap: 14px 20px;
}
.room-detail-content-listSecond li {
  font-size: 14px;
  color: #4ecb71;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 8px;
}
.room-detail-content-price {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-left: auto;
  max-width: 150px;
  width: 100%;
}
.room-detail-content-price-vnd {
  font-size: 16px;
  color: #FF5B00;
  font-weight: 700;
}
.room-detail-content-price-text {
  font-size: 14px;
  color: #b0abab;
  font-weight: 400;
  text-align: right;
}
.room-detail-content-cart {
  background-color: transparent;
  color: #FF5B00;
  border: 1px solid #FF5B00;
}
.room-detail-content-cart, .room-detail-content-bookRoom {
  padding: 10px 12px;
  font-size: 14px;
  font-weight: 700;
  border-radius: 5px;
  transition: opacity 0.2s ease;
}
.room-detail-content-cart:hover, .room-detail-content-bookRoom:hover {
  opacity: 0.8;
}
.room-detail-content-bookRoom {
  color: #ffffff;
  background-color: #FF5B00;
}

.room-info {
  max-width: 326px;
  width: 100%;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 15px;
  padding: 20px 24px;
  border: 2px solid #FF5B00;
  background-color: #ffffff;
}
.room-info-wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 100%;
  overscroll-behavior: none;
  margin-right: -0.7rem;
  padding-right: 0.5rem;
  overflow-y: auto;
}
.room-info-wrapper::-webkit-scrollbar {
  width: 5px;
}
.room-info-wrapper::-webkit-scrollbar-track {
  border-radius: 10px;
}
.room-info-wrapper::-webkit-scrollbar-thumb {
  background-color: #FF5B00;
  border-radius: 10px;
}
.room-info-list {
  display: flex;
  flex-direction: column;
  gap: 16px;
  border-bottom: 1px solid #ccc;
  padding-bottom: 12px;
}
.room-info-list h4 {
  font-size: 16px;
  color: #000000;
  font-weight: bold;
}
.room-info-list ul {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.room-info-list ul li {
  display: flex;
  gap: 12px;
  font-size: 14px;
  color: #000000;
}
.room-info-footer {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.room-info-footer span {
  font-size: 14px;
  color: #000000;
}
.room-info-footer b {
  color: #FF5B00;
  font-size: 24px;
  font-weight: 700;
  text-transform: uppercase;
}
.room-info-footer-add, .room-info-footer-select {
  display: inline-flex;
  padding: 8px;
  width: 100%;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  border-radius: 6px;
  transition: all 0.2s ease;
  border: 1px solid transparent;
}
.room-info-footer-add:hover, .room-info-footer-select:hover {
  opacity: 0.8;
}
.room-info-footer-add {
  background-color: transparent;
  border-color: #FF5B00;
  color: #FF5B00;
}
.room-info-footer-select {
  background-color: #FF5B00;
  color: #ffffff;
}

@media only screen and (max-width: 1023px) {
  .list-hotelDetail-gallery {
    display: block;
    height: auto !important;
  }
  .list-hotelDetail-gallery-item {
    position: relative;
  }
  .list-hotelDetail-gallery-item:nth-child(n+2) {
    display: none;
  }
  .list-hotelDetail-gallery-item::before {
    content: "Xem Thêm Hình Ảnh";
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    font-weight: bold;
    font-size: 16px;
    position: absolute;
    letter-spacing: 1px;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 30%;
    background-color: rgba(255, 91, 0, 0.4);
    z-index: 5;
  }
}
@media only screen and (max-width: 767px) {
  .list-hotelDetail-searchRoom-list {
    grid-template-columns: repeat(2, 1fr);
  }

  .list-hotelDetail-searchRoom-item:first-child {
    grid-column: 1/3;
    grid-row: 1/2;
  }

  .list-hotelDetail-searchRoom-item:nth-child(2) {
    grid-column: 1/2;
    grid-row: 2/3;
  }

  .list-hotelDetail-searchRoom-item:nth-child(3) {
    grid-column: 2/3;
    grid-row: 2/3;
  }

  .list-hotelDetail-searchRoom-button {
    grid-column: 1/3;
    grid-row: 3/4;
    padding: 15px 8px;
  }
}
@media only screen and (max-width: 599px) {
  .list-hotelDetail-gallery {
    height: 320px;
  }
}
@media only screen and (max-width: 1199px) {
  .room-info {
    max-width: 100%;
  }

  .modal-dialog {
    max-width: 100%;
    margin: 0;
  }
}
.order-info {
  border: 1.5px solid #FF5B00;
  border-radius: 5px;
  padding: 18px 12px;
  background-color: #ffffff;
}
.order-info:not(:last-child) {
  margin-bottom: 12px;
}
.order-info-thumbnail {
  width: 100px;
  height: 100px;
  border-radius: 5px;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
}
.order-info-content {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.order-info-content h1 {
  font-weight: 600;
  font-size: 14px;
  color: #000000;
  line-height: 18px;
}
.order-info-content p {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #000000;
}
.order-info-quantity {
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 1fr;
  gap: 8px;
}
.order-info-quantity label {
  color: #000000;
  font-weight: 400;
  font-size: 15px;
  line-height: 19px;
}
.order-info-quantity .filter-room-item-quantity {
  height: 30px;
}
.order-info-quantity .filter-room-item-quantity input,
.order-info-quantity .filter-room-item-quantity button {
  width: 35px;
}
.order-info-image {
  height: 0;
  position: relative;
  padding-bottom: 46.15%;
  border-radius: 5px;
  flex-basis: 80.2%;
  flex-shrink: 0;
  flex-grow: 0;
  border-radius: 5px;
  overflow: hidden;
}
.order-info-image img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@media only screen and (max-width: 575px) {
  .order-info-checkbox {
    font-size: 24px !important;
  }
}

#button-removeCheckbox {
  font-size: 14px;
  color: #FF5B00;
  font-weight: 400;
  border-radius: 5px;
  padding: 8px 10px;
  background-color: #ffffff;
  font-size: 14px;
  color: #FF5B00;
  font-weight: 400;
  border: 1px solid #FF5B00;
  transition: opacity 0.2s ease;
}
#button-removeCheckbox:hover {
  opacity: 0.8;
}

.booking-container {
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 0.3fr 1fr;
  gap: 6px;
}
.booking-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;
  border-radius: 8px;
  padding: 10px 5px;
  border: 1.5px solid #FF5B00;
}
.booking-item-title {
  font-size: 12px;
  color: #a9a6a6;
  font-weight: 400;
}
.booking-item-date {
  font-size: 12px;
  color: #000000;
  font-weight: bold;
}
.booking-item-time {
  font-size: 12px;
  color: #000000;
  font-weight: 400;
}
.booking-divider {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}
.booking-divider-night {
  font-size: 11px;
  color: #a9a6a6;
  font-weight: 300;
}
.booking-divider-line {
  width: 100%;
}

.vendor-bg {
  background-color: #f9f9f9;
  padding: 75px 0;
}
.vendor-button {
  border-radius: 10px;
  padding: 8px 16px;
  background-color: #FF5B00;
  font-size: 16px;
  line-height: 1.5;
  color: #ffffff;
  font-weight: 500;
  display: inline-block;
  text-align: center;
  transition: opacity 0.2s ease;
}
@media (hover: hover) {
  .vendor-button:hover {
    opacity: 0.8;
  }
}
.vendor-banner {
  height: 0;
  position: relative;
  padding-bottom: 28.35%;
}
.vendor-banner > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.vendor-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
}
.vendor-content-title {
  font-size: 18px;
  line-height: 1.56;
  color: #FF5B00;
  font-weight: 700;
  text-align: left;
  text-transform: uppercase;
}
.vendor-content-desc {
  font-size: 18px;
  line-height: 1.56;
  color: #000000;
  font-weight: 400;
  text-align: left;
}
.vendor-content-image {
  height: 0;
  position: relative;
  padding-bottom: 73.85%;
  border-radius: 10px;
  overflow: hidden;
}
.vendor-content-image > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.vendor-business {
  padding: 100px 0;
  position: relative;
}
.vendor-business-bg {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 500px;
  width: 100%;
  z-index: -5;
}
.vendor-business-title {
  font-size: 24px;
  line-height: 1.17;
  color: #FF5B00;
  text-align: center;
  font-weight: 700;
  margin-bottom: 24px;
  text-transform: uppercase;
}
.vendor-business-card {
  display: flex;
  flex-direction: column;
  border-radius: 26px;
  overflow: hidden;
  background-color: #ffffff;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.vendor-business-thumbnail {
  padding-bottom: 74.2%;
  height: 0;
  position: relative;
}
.vendor-business-thumbnail > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.vendor-business-content {
  padding: 20px;
}
.vendor-business-content p {
  text-align: left;
  font-size: 16px;
  line-height: 1.5;
  color: #000000;
  font-weight: bold;
  margin-bottom: 8px;
}
.vendor-business-content span {
  text-align: center;
  font-size: 16px;
  line-height: 1.5;
  color: #FF5B00;
  font-weight: bold;
}
.vendor-business-button {
  border-radius: 10px;
  padding: 8px 16px;
  background-color: #FF5B00;
  font-size: 24px;
  line-height: 1;
  color: #ffffff;
  font-weight: 500;
  max-width: 300px;
  width: 100%;
  text-align: center;
  transition: opacity 0.2s ease;
  display: inline-block;
}
.vendor-business-button:hover {
  opacity: 0.8;
}
.vendor-question-content {
  border-radius: 30px;
  padding: 24px 20px;
  background-color: #fcf6f2;
}
.vendor-question-caption {
  font-size: 18px;
  line-height: 1.56;
  color: #FF5B00;
  font-weight: 700;
}
.vendor-question-desc {
  font-size: 16px;
  line-height: 1.88;
  color: #000000;
  font-weight: 400;
  margin: 16px 0;
}
.vendor-question-title {
  font-size: 18px;
  line-height: 1.56;
  color: #FF5B00;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
}
.vendor-question-list {
  display: flex;
  flex-direction: column;
  gap: 34px;
  margin-top: 16px;
}
.vendor-question-list li {
  display: flex;
  align-items: center;
  gap: 16px;
}
.vendor-question-list li span {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 100rem;
  width: 32px;
  height: 32px;
  color: #ffffff;
  background-color: #FF5B00;
}
.vendor-question-list li b {
  font-size: 20px;
  line-height: 1;
  font-weight: 600;
}
.vendor-question-register {
  border: 1.5px solid #FF5B00;
  border-radius: 30px;
  padding: 24px 20px;
  background-color: #f9f9f9;
}
.vendor-question-register-title {
  font-size: clamp(1.5rem, -0.278rem + 3.704vw, 2.5rem);
  line-height: 1.2;
  font-weight: 500;
  color: #000000;
}
.vendor-question-register-desc {
  font-size: 18px;
  line-height: 1.56;
  font-weight: 400;
  color: #000000;
  padding: 24px 0;
}
.vendor-question-register-terms {
  font-size: 18px;
  line-height: 1.56;
  font-weight: 400;
  color: #000000;
  margin-bottom: 24px;
}
.vendor-question-register-terms a {
  display: inline-block;
  color: #FF5B00;
  transition: opacity 0.2s ease;
}
.vendor-question-register-terms a:hover {
  opacity: 0.8;
}
.vendor-solution-caption {
  font-size: 18px;
  line-height: 1.56;
  color: #FF5B00;
  font-weight: 700;
  text-transform: uppercase;
}
.vendor-solution-desc {
  font-size: 18px;
  line-height: 1.56;
  color: #000000;
  font-weight: 400;
  padding: 16px 0;
}
.vendor-solution-list {
  display: flex;
  flex-direction: column;
  gap: 5px;
  font-size: 18px;
}
.vendor-solution-title {
  font-size: 18px;
  line-height: 1.56;
  color: #FF5B00;
  font-weight: 700;
  padding: 16px 0;
}
.vendor-solution-button {
  border-radius: 10px;
  padding: 8px 16px;
  background-color: #FF5B00;
  font-size: 16px;
  color: #ffffff;
  font-weight: 500;
  max-width: 270px;
  width: 100%;
  text-align: center;
  transition: opacity 0.2s ease;
  display: inline-block;
  transition: opacity 0.2s ease;
  margin-top: 16px;
}
.vendor-solution-button:hover {
  opacity: 0.8;
}
@media only screen and (max-width: 1200px) {
  .vendor-banner {
    padding-bottom: 40.25%;
  }
  .vendor-question-register-desc {
    padding: 15px 0;
    font-size: 15px;
  }
}
@media only screen and (max-width: 1023px) {
  .vendor-business {
    padding-block: 30px 0;
    margin-bottom: 30px;
  }
  .vendor-business-title {
    font-size: 18px;
  }
  .vendor-business-content p,
.vendor-business-content span {
    font-size: 14.5px;
  }
  .vendor-business-bg {
    display: none;
  }
}
@media only screen and (max-width: 767px) {
  .vendor-bg {
    padding: 30px 0;
  }
  .vendor-content {
    gap: 11px;
  }
  .vendor-content-desc, .vendor-content-title {
    font-size: 15px;
  }
  .vendor-business {
    padding-block: 30px;
  }
  .vendor-business-button {
    font-size: 20px;
  }
  .vendor-solution-desc, .vendor-solution-list {
    font-size: 16px;
  }
  .vendor-question-list {
    gap: 15px;
  }
  .vendor-question-list li b {
    font-size: 17px;
  }
  .vendor-question-title {
    font-size: 16px;
  }
}

.stats-section .stats {
  padding: 45px 0;
  background-color: #fcf6f2;
  text-align: center;
}
.stats-section .stats-caption {
  font-size: clamp(1.25rem, 1.073rem + 0.668vw, 1.5rem);
  line-height: 0.92;
  color: #FF5B00;
  font-weight: 700;
  margin-bottom: clamp(0.938rem, 0.272rem + 2.504vw, 1.875rem);
  text-transform: uppercase;
}
.stats-section .stats-description {
  font-size: clamp(1rem, 0.823rem + 0.668vw, 1.25rem);
  line-height: 1.1;
  color: #000000;
  font-weight: bold;
  line-height: 1.7;
}
.stats-section .stats-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}
.stats-section .stats-item-title {
  font-size: 20px;
  line-height: 1.1;
  color: #000000;
  font-weight: bold;
}
.stats-section .stats-item-desc {
  font-size: 14px;
  line-height: 1.57;
  color: #000000;
  font-weight: 400;
}

.experiences-section .experiences-caption {
  font-size: clamp(1.25rem, 1.073rem + 0.668vw, 1.5rem);
  line-height: 0.92;
  color: #FF5B00;
  font-weight: 700;
}
.experiences-section .experiences-item {
  border-radius: 6px;
  padding: 24px;
  background-color: #f9f9f9;
}
.experiences-section .experiences-item-caption {
  font-family: Inter;
  font-size: 14px;
  color: #000000;
  font-weight: bold;
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid #FF5B00;
}
.experiences-section .experiences-content {
  display: flex;
  align-items: center;
  gap: 16px;
}
.experiences-section .experiences-content-image {
  border-radius: 6px;
  overflow: hidden;
  max-width: 73px;
}
.experiences-section .experiences-content-main {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.experiences-section .experiences-content-main h4 a {
  font-size: 14px;
  color: #000000;
  font-weight: 500;
  display: inline-block;
  transition: opacity 0.2s ease;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
}
.experiences-section .experiences-content-main h4 a:hover {
  opacity: 0.8;
}
.experiences-section .experiences-content-main p {
  font-size: 12.5px;
  color: #FF5B00;
  font-weight: 400;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
}

.card-top-tour {
  position: relative;
  border-radius: 6px;
  height: 300px;
  overflow: hidden;
}
.card-top-tour-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.card-top-tour-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 24px;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: rgba(0, 0, 0, 0.3);
}
.card-top-tour-title a {
  font-size: clamp(1.5rem, 0.968rem + 2.003vw, 2.25rem);
  text-transform: uppercase;
  position: relative;
  color: #ffffff;
  display: inline-block;
}
.card-top-tour-title a:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  height: 1px;
  width: 52%;
  background-color: #ffffff;
}
.card-top-tour-count {
  font-size: 24px;
}

.tour-detail-calendar {
  display: flex;
  justify-content: space-between;
  gap: 8px;
  margin-bottom: 20px;
  overflow-x: auto;
  width: 100%;
  white-space: nowrap;
  display: flex;
  flex-wrap: nowrap;
  gap: 8px;
  justify-content: space-between;
  padding-bottom: 14px;
  height: 75px;
}
.tour-detail-calendar::-webkit-scrollbar {
  height: 6px;
}
.tour-detail-calendar::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.tour-detail-calendar::-webkit-scrollbar-thumb {
  background: #FF5B00;
}
.tour-detail-calendar-item {
  border: 1.5px solid #FF5B00;
  border-radius: 5px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  user-select: none;
  gap: 5px;
  justify-content: center;
  text-align: center;
  width: 100%;
  cursor: pointer;
}
.tour-detail-calendar-item.active {
  background-color: #fff4ef;
  pointer-events: none;
}
.tour-detail-calendar-item span {
  font-size: 14px;
  font-weight: 400;
  color: #9a9898;
}
.tour-detail-calendar-item b {
  color: #FF5B00;
  font-weight: 600;
}
.tour-detail-calendar .dropdown-guide-select {
  width: 140px;
  flex-shrink: 0;
}

.tour-detail-services .services-item {
  padding: 8px 10px;
  border-radius: 6px;
  border: 1px solid #FF5B00;
  cursor: pointer;
}
.tour-detail-services .services-item.active {
  background-color: #fff4ef;
  pointer-events: none;
}
.tour-detail-services .services-item-content {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.tour-detail-services .services-item-content h4 {
  font-size: 14px;
  font-weight: 600;
  color: #000000;
  line-height: 1.57;
}
.tour-detail-services .services-item-content span {
  font-size: 14px;
  font-weight: 400;
  color: #babab9;
  line-height: 1.57;
}
.tour-detail-services .services-control {
  display: none;
}
.tour-detail-services .services-control-item {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  align-items: center;
}
.tour-detail-services .services-control-item:not(:last-child) {
  border-bottom: 1px dashed #FF5B00;
}
.tour-detail-services .services-control-item-price {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.tour-detail-services .services-control-item-price span {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
}
.tour-detail-services .services-control-item-price b {
  text-transform: uppercase;
  color: #FF5B00;
  font-weight: 600;
  line-height: 24px;
  font-size: clamp(1.063rem, 0.83rem + 0.875vw, 1.25rem);
}
.tour-detail-services .filter-room-item-quantity {
  height: 40px;
}
.tour-detail-services .filter-room-item-quantity input {
  width: 55px;
  height: 40px;
  font-size: 20px;
}
.tour-detail-services .filter-room-item-quantity button {
  width: 40px;
  height: 100%;
}
@media only screen and (max-width: 599px) {
  .tour-detail-services-price {
    gap: 10px;
  }
  .tour-detail-services .filter-room-item-quantity {
    width: auto;
    height: 30px;
  }
  .tour-detail-services .filter-room-item-quantity input {
    width: 40px;
    height: 100%;
    font-size: 14px;
  }
  .tour-detail-services .filter-room-item-quantity button {
    width: 30px;
  }
  .tour-detail-services b {
    font-size: 18px;
  }
}

.tour-detail-price {
  font-weight: 700;
  font-size: clamp(1.25rem, 0.94rem + 1.166vw, 1.5rem);
  line-height: 29px;
  color: #ff0000;
}
.tour-detail-cart {
  display: flex;
  gap: 12px;
  max-width: 340px;
  width: 100%;
}
.tour-detail-cart-add, .tour-detail-cart-buy {
  border-radius: 6px;
  border: 1px solid #FF5B00;
  padding: 8px 12px;
  font-weight: 700;
  font-size: 14px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  transition: opacity 0.2s ease;
  width: 100%;
}
.tour-detail-cart-add:hover, .tour-detail-cart-buy:hover {
  opacity: 0.8;
}
.tour-detail-cart-add {
  color: #FF5B00;
}
.tour-detail-cart-buy {
  color: #ffffff;
  background-color: #FF5B00;
}
@media only screen and (max-width: 424px) {
  .tour-detail-cart {
    flex-direction: column;
  }
}

.tour-detail-services .tag {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #FF5B00;
  border: 1px solid #FF5B00;
  border-radius: 5px;
  padding: 8px;
  width: fit-content;
}
.tour-detail-services .line {
  width: 100%;
  height: 1px;
  background-color: rgba(108, 117, 124, 0.2);
  margin: 20px 0;
}
.tour-detail-services .feature {
  font-weight: 400;
  font-size: 14px;
  color: #424242;
  border-radius: 5px;
  padding: 5px;
  width: fit-content;
  background-color: rgba(108, 117, 124, 0.2);
}

.event-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(3, 1fr);
  gap: clamp(0.875rem, -1.307rem + 3.409vw, 1.25rem);
}
.event-grid .card {
  grid-column: 1/2;
  grid-row: 1/3;
}
.event-grid-thumbnail {
  height: 0;
  position: relative;
  padding-bottom: 69.85%;
  overflow: hidden;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.event-grid-thumbnail img {
  transition: all 0.2s ease;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.event-grid-thumbnail img:hover {
  transform: scale(1.1);
  opacity: 0.8;
}
.event-grid-card {
  border-radius: 5px;
  border: 1.5px solid #FF5B00;
}
@media only screen and (max-width: 1023px) {
  .event-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media only screen and (max-width: 599px) {
  .event-grid {
    grid-template-columns: 1fr;
  }
}

.card-event {
  position: relative;
  border-radius: 6px;
  height: 300px;
  overflow: hidden;
}
.card-event-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.card-event-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 10px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
  background-color: rgba(0, 0, 0, 0.3);
  text-align: center;
}
.card-event-content h3 a {
  font-size: clamp(1.5rem, 0.968rem + 2.003vw, 2.25rem);
  text-transform: uppercase;
  position: relative;
  padding-bottom: 8px;
  margin-bottom: 2px;
  color: #ffffff;
  display: inline-block;
}
.card-event-content h3 a:after {
  content: "";
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
  height: 5px;
  width: 30%;
  background-color: #FF5B00;
}
.card-event-content span {
  font-size: 24px;
}

.about-content h4 {
  color: #000000;
  font-weight: 700;
  font-size: clamp(1rem, 0.823rem + 0.668vw, 1.25rem);
  line-height: 24.2px;
}
.about-content p {
  color: #000000;
  font-weight: 400;
  font-size: clamp(1rem, 0.823rem + 0.668vw, 1.25rem);
  line-height: 1.5;
}

.event-info {
  border-radius: 10px;
  background-color: #f5f5f5;
  padding: 24px 20px;
  margin-top: 24px;
}
.event-info-caption {
  font-size: 20px;
  color: #000000;
  font-weight: 700;
  padding-bottom: 16px;
  margin-bottom: 16px;
  border-bottom: 1.5px dashed rgba(108, 117, 124, 0.2);
}
.event-info-list {
  display: flex;
  align-items: flex-start;
  gap: 12px;
}
.event-info-list:not(:last-child) {
  margin-bottom: 16px;
}
.event-info-list img {
  width: 20px;
}
.event-info-list span {
  color: #7e7b7b;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
}

.location-detail {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1.5px solid #d9d9d9;
}
.location-detail .box {
  padding: 24px;
  border: 1.5px solid #d9d9d9;
  border-radius: 6px;
}
.location-detail .location-detail-gallery .list-hotelDetail-gallery-item:first-child {
  position: relative;
}
.location-detail .location-detail-gallery .list-hotelDetail-gallery-item:first-child .content {
  position: absolute;
  z-index: 10;
  top: clamp(1.25rem, 0.011rem + 4.665vw, 2.25rem);
  left: clamp(1.25rem, 0.011rem + 4.665vw, 2.25rem);
}
.location-detail .location-detail-gallery .list-hotelDetail-gallery-item:first-child .content-title {
  font-size: clamp(1.875rem, 1.41rem + 1.749vw, 2.25rem);
  font-weight: 400;
  color: #ffffff;
  margin-bottom: 15px;
}
.location-detail .location-detail-gallery .list-hotelDetail-gallery-item:first-child .content-desc {
  font-size: clamp(1rem, 0.69rem + 1.166vw, 1.25rem);
  font-weight: 400;
  color: #ffffff;
}
.location-detail .location-detail-gallery .list-hotelDetail-gallery-item:first-child:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
}
.location-detail .location-detail-exp .content {
  background-image: url("../images/location-bg.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  height: 100%;
  color: #ffffff;
  border-radius: 5px;
  overflow: hidden;
  padding: 75px 24px;
  position: relative;
}
.location-detail .location-detail-exp .content-shadow {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}
.location-detail .location-detail-exp .content-wrapper {
  display: flex;
  flex-direction: column;
  gap: 12px;
  z-index: 10;
  position: relative;
  height: 100%;
}
.location-detail .location-detail-exp .content-title {
  font-weight: 700;
  font-size: 28px;
  line-height: 32px;
  text-transform: capitalize;
}
.location-detail .location-detail-exp .content-desc {
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
}
.location-detail .location-detail-exp .content-button {
  margin-top: auto;
  border-radius: 5px;
  background-color: #ffffff;
  display: inline-block;
  text-align: center;
  padding: 10px 20px;
  color: #FF5B00;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  max-width: 140px;
  width: 100%;
  transition: all 0.2s ease;
}
.location-detail .location-detail-exp .content-button:hover {
  background-color: #FF5B00;
  color: #ffffff;
}
.location-detail .location-detail-map .content {
  font-weight: 400;
  font-size: clamp(1rem, 0.845rem + 0.583vw, 1.125rem);
  line-height: 1.5;
  color: #000000;
}
.location-detail .location-detail-map .readmore-btn {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  cursor: pointer;
  color: #FF5B00;
  border: 1px solid #FF5B00;
  border-radius: 6px;
  padding: 5px 15px;
  margin-top: 25px;
}
.location-detail .location-detail-comment .info {
  display: flex;
  align-items: center;
  gap: 18px;
  margin-bottom: 30px;
}
.location-detail .location-detail-comment .info-text {
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #FF5B00;
}
.location-detail .location-detail-comment .info-dots {
  width: 6px;
  height: 6px;
  border-radius: 100rem;
  display: inline-block;
  background-color: #a9a7a7;
}
.location-detail .location-detail-comment .comment-box h5 {
  font-weight: 700;
  font-size: 20px;
  line-height: 24.2px;
  margin-bottom: 24px;
}
.location-detail .location-detail-comment .comment-box-main {
  border: 1.5px solid #FF5B00;
  border-radius: 6px;
  padding: 20px 24px;
  display: flex;
  flex-direction: column;
  gap: 25px;
  align-items: flex-start;
}
.location-detail .location-detail-comment .comment-box-main .send {
  max-width: 238px;
  width: 100%;
  background-color: #FF5B00;
  color: #ffffff;
  border-radius: 5px;
  padding: 9px 15px;
  text-align: center;
  font-weight: 400;
  font-size: 16px;
  transition: all 0.2s ease;
}
.location-detail .location-detail-comment .comment-box-main .send:hover {
  opacity: 0.8;
}
.location-detail .location-detail-comment .comment-box-main textarea {
  resize: none;
  background-color: #f9f9f9;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}
.location-detail .location-detail-comment .comment-box-main textarea::placeholder {
  color: rgba(108, 117, 124, 0.3);
}
.location-detail .location-detail-trending {
  border: 1.5px solid #FF5B00;
  border-radius: 5px;
  background-color: #f9f9f9;
  padding: 25px;
}
.location-detail .location-detail-trending .trending-caption {
  font-weight: 700;
  color: #FF5B00;
  line-height: 24.2px;
  font-size: 20px;
  margin-bottom: 20px;
}
.location-detail .location-detail-trending .trending-item {
  display: flex;
  align-items: center;
  gap: clamp(0.875rem, 0.41rem + 1.749vw, 1.25rem);
}
.location-detail .location-detail-trending .trending-item-image {
  max-width: 100px;
  width: 100%;
  border-radius: 5px;
  overflow: hidden;
}
.location-detail .location-detail-trending .trending-item-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.location-detail .location-detail-trending .trending-item-content {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.location-detail .location-detail-trending .trending-item-content h4 {
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
}
.location-detail .location-detail-trending .trending-item-content h4 a {
  color: #383737;
}
.location-detail .location-detail-trending .trending-item-content-user {
  display: flex;
  align-items: center;
  gap: 8px;
}
.location-detail .location-detail-trending .trending-item-content-user span {
  color: #6a6969;
  font-weight: 400;
  font-size: 15px;
  line-height: 17px;
}

.list-blog-box {
  border: 1.5px solid #FF5B00;
  padding: 24px 20px;
  border-radius: 6px;
  margin-bottom: 24px;
}

.list-blog-title {
  font-weight: 600;
  font-size: 20px;
  line-height: 24.2px;
  color: #FF5B00;
  border-bottom: 3px solid #FF5B00;
  padding-bottom: 15px;
}

.list-blog-articles .blog-item {
  border: 1.5px solid rgba(108, 117, 124, 0.2);
  padding: 24px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}
.list-blog-articles .blog-item-thumbnail {
  position: relative;
  height: 0;
  padding-bottom: 25.45%;
  flex-basis: 35.3%;
  flex-shrink: 0;
  flex-grow: 0;
  border-radius: 6px;
  overflow: hidden;
}
.list-blog-articles .blog-item-thumbnail-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.list-blog-articles .blog-item-favorite {
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 16px;
  border-radius: 100rem;
  background-color: #ffffff;
  width: 34px;
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.list-blog-articles .blog-item-content-title a {
  color: #000000;
  font-size: clamp(1.125rem, 0.859rem + 1.002vw, 1.5rem);
  font-weight: 500;
  line-height: 29px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
  transition: color 0.2s ease;
}
.list-blog-articles .blog-item-content-title a:hover {
  color: #FF5B00;
}
.list-blog-articles .blog-item-content-subtitle span {
  color: #FF5B00;
  font-weight: 400;
  font-size: 16px;
  line-height: 19.36px;
  display: inline-block;
}
.list-blog-articles .blog-item-content-subtitle span:not(:first-child):before {
  content: "|";
  margin: 0 8px;
  color: #FF5B00;
}
.list-blog-articles .blog-item-content-desc {
  background-color: #fff4ef;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #000000;
  padding: 15px 10px;
}

.list-blog-mostViewed .search {
  background-color: rgba(245, 245, 245, 0.9);
  padding: 10px 15px;
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
}
.list-blog-mostViewed .search input {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  border: none;
  width: 100%;
  outline: none;
  background-color: transparent;
  color: #000000;
}
.list-blog-mostViewed .search input::placeholder {
  color: #afafaf;
}
.list-blog-mostViewed .search i {
  cursor: pointer;
  color: #d9d9d9;
  font-size: 20px;
}
.list-blog-mostViewed .articles-item {
  display: flex;
  align-items: center;
  gap: 14px;
  justify-content: space-between;
  width: fit-content;
}
.list-blog-mostViewed .articles-item-thumbnail {
  max-width: 100px;
  width: 100%;
  border-radius: 5px;
  overflow: hidden;
}
.list-blog-mostViewed .articles-item-thumbnail img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.list-blog-mostViewed .articles-item-content h1 a {
  color: #3e3e3e;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
}
.list-blog-mostViewed .articles-item-content p {
  color: #afafaf;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  margin-top: 8px;
}
.list-blog-mostViewed .list-content {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.list-blog-mostViewed .list-content li a {
  font-weight: 400;
  font-size: 14px;
  line-height: 16.94px;
  color: #111928;
  transition: color 0.2s ease;
}
.list-blog-mostViewed .list-content li a:hover {
  color: #FF5B00;
}

.list-blog-location .caption {
  color: #FF5B00;
  font-weight: 700;
  font-size: 24px;
  line-height: 22px;
  text-align: center;
  margin-bottom: clamp(1.563rem, 0.401rem + 4.373vw, 2.5rem);
  text-transform: uppercase;
}
.list-blog-location .location-item {
  height: 0;
  position: relative;
  padding-bottom: 84.65%;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 6px;
  overflow: hidden;
}
.list-blog-location .location-item-image img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.list-blog-location .location-item-content {
  position: absolute;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.1);
}
.list-blog-location .location-item-content-title {
  color: #ffffff;
  font-weight: 600;
  font-size: 36px;
  line-height: 22px;
  text-transform: uppercase;
  padding-bottom: 10px;
  display: inline-block;
  text-align: center;
}
.list-blog-location .location-item-content-title:after {
  content: "";
  display: block;
  text-align: center;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  width: 50%;
  height: 4px;
  background-color: #FF5B00;
}

.blog-detail-post .caption {
  font-weight: 400;
  font-size: clamp(1.25rem, 0.54rem + 2.671vw, 2.25rem);
  line-height: 1.4;
}
.blog-detail-post .thumbnail {
  height: 0;
  position: relative;
  padding-bottom: 56.25%;
  border-radius: 6px;
  overflow: hidden;
}
.blog-detail-post .thumbnail-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.blog-detail-post .content {
  max-height: 200px;
  overflow: hidden;
  transition: max-height 0.5s ease;
}
.blog-detail-post .content.expanded {
  max-height: none;
}
.blog-detail-post .readmore-btn {
  display: none;
  justify-content: center;
  margin-top: clamp(0.625rem, -0.931rem + 3.241vw, 1.5rem);
  width: 100%;
  padding: 20px 0;
  background: linear-gradient(180deg, #ffffff 50%, #999999 100%);
  cursor: pointer;
}
.blog-detail-post .readmore-btn.show {
  display: inline-flex;
}
.blog-detail-post .readmore-btn-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}
.blog-detail-post .readmore-btn-content span {
  font-size: clamp(1.25rem, 0.833rem + 1.778vw, 1.5rem);
  color: #999999;
  font-weight: 500;
  line-height: 24px;
}

.blog-detail-comment .date {
  color: #928c8c;
  font-weight: 500;
  font-size: 16px;
  line-height: 19.36px;
  margin-bottom: 24px;
}
.blog-detail-comment .info-author {
  box-shadow: rgba(108, 117, 124, 0.2) 0px 1px 4px;
  border: 1px solid rgba(108, 117, 124, 0.2);
  background-color: #f9f9f9;
  padding: 25px;
  border-radius: 6px;
  margin: 24px 0;
}
.blog-detail-comment .info-author-header {
  display: flex;
  align-items: center;
  gap: 15px;
}
.blog-detail-comment .info-author-header-avatar {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  overflow: hidden;
  object-fit: cover;
}
.blog-detail-comment .info-author-header-content h4 {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #111928;
  margin-bottom: 2px;
}
.blog-detail-comment .info-author-header-content p {
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: #111928;
}
.blog-detail-comment .info-author-content p {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #637381;
  margin: 16px 0;
}
.blog-detail-comment .info-author-content a {
  display: inline-block;
  border-radius: 3px;
  border: 1px solid #FF5B00;
  padding: 8px;
  color: #FF5B00;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  transition: all 0.2s ease;
}
.blog-detail-comment .info-author-content a:hover {
  color: #ffffff;
  background-color: #FF5B00;
}
.blog-detail-comment .stats {
  display: flex;
  align-items: center;
  gap: 14px;
  width: 100%;
  white-space: nowrap;
  overflow-x: auto;
  padding-bottom: 15px;
}
.blog-detail-comment .stats span {
  font-weight: 400;
  font-size: 16px;
  line-height: 19.36px;
  color: #637381;
}
.blog-detail-comment .stats span:not(:last-child)::after {
  content: "|";
  margin: 0 8px;
  color: #FF5B00;
}
.blog-detail-comment .comment-section h2 {
  font-weight: 700;
  font-size: 20px;
  line-height: 24.2px;
  margin: 9px 0 24px 0;
}
.blog-detail-comment .comment-section-login {
  font-weight: 400;
  font-size: 16px;
  line-height: 19.36px;
  color: #111928;
}
.blog-detail-comment .comment-section-login a {
  color: #FF5B00;
  transition: opacity 0.2s ease;
}
.blog-detail-comment .comment-section-login a:hover {
  opacity: 0.8;
}

.blog-detail-location {
  background: linear-gradient(to right, #a1c4fd, #c2e9fb);
  padding: 20px 24px;
  border-radius: 6px;
}
.blog-detail-location .caption {
  font-size: 20px;
  font-weight: 700;
  line-height: 24.2px;
  color: #ffffff;
  text-transform: uppercase;
}
.blog-detail-location .button-subscribe {
  font-weight: 400;
  font-size: 16px;
  color: #ffffff;
  background-color: #FF5B00;
  line-height: 24px;
  padding: 6.875px 15px;
  border-radius: 6px;
  text-align: center;
  transition: opacity 0.2s ease;
}
.blog-detail-location .button-subscribe:hover {
  opacity: 0.8;
}
.blog-detail-location .post-tabs {
  display: flex;
  align-items: center;
  gap: 32px;
  overflow-x: auto;
  white-space: nowrap;
  width: 100%;
  padding-bottom: 14px;
  margin-bottom: 16px;
}
.blog-detail-location .post-tabs::-webkit-scrollbar {
  height: 6px;
}
.blog-detail-location .post-tabs::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 5px;
}
.blog-detail-location .post-tabs::-webkit-scrollbar-thumb {
  background: #FF5B00;
  border-radius: 5px;
}
.blog-detail-location .post-tabs::-webkit-scrollbar-thumb:hover {
  background: #e65200;
}
.blog-detail-location .post-tabs-item {
  color: #ffffff;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  padding-bottom: 12px;
  border-bottom: 2px solid #ffffff;
}
.blog-detail-location .post-tabs-item.active {
  color: #FF5B00;
  border-color: #FF5B00;
  pointer-events: none;
}
.blog-detail-location .post-pane {
  display: none;
}
.blog-detail-location .post-pane.active {
  display: block;
}
.blog-detail-location .post-item {
  border-radius: 6px;
  position: relative;
  overflow: hidden;
  height: 0;
  padding-bottom: 120.35%;
}
.blog-detail-location .post-item-background {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
}
.blog-detail-location .post-item-content {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 20px;
  width: 100%;
  background-color: rgba(89, 86, 86, 0.3);
}
.blog-detail-location .post-item-content h1 a {
  color: #ffffff;
  font-size: 400px;
  font-size: 20px;
  line-height: 22px;
  padding-bottom: 5px;
  margin-bottom: 10px;
  position: relative;
  display: inline-block;
  transition: color 0.2s ease;
}
.blog-detail-location .post-item-content h1 a:hover {
  color: #FF5B00;
}
.blog-detail-location .post-item-content h1 a:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 42%;
  height: 1.5px;
  background-color: #ffffff;
}
.blog-detail-location .post-item-content-author img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  object-fit: cover;
}
.blog-detail-location .post-item-content-author span {
  color: #ffffff;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}
.blog-detail-location .post-item-content-like {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  color: #ffffff;
}
.blog-detail-location .post-item-content-like i {
  font-size: 20px;
}
.blog-detail-location .post-item-content-like span {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
}

@media only screen and (max-width: 767px) {
  .list-blog-articles .blog-item {
    padding: 15px;
    display: block;
  }
  .list-blog-articles .blog-item-thumbnail {
    padding-bottom: 56.25%;
    border-radius: unset;
  }
  .list-blog-articles .blog-item-content {
    margin-top: 15px;
  }
}