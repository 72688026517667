.list-blog-box {
  border: 1.5px solid $primary-color;
  padding: 24px 20px;
  border-radius: 6px;
  margin-bottom: 24px;
}
.list-blog-title {
  font-weight: 600;
  font-size: 20px;
  line-height: 24.2px;
  color: $primary-color;
  border-bottom: 3px solid $primary-color;
  padding-bottom: 15px;
}
.list-blog-articles {
  .blog {
    &-item {
      border: 1.5px solid rgb(108, 117, 124, 0.2);
      padding: 24px 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 20px;
      &-thumbnail {
        position: relative;
        height: 0;
        padding-bottom: 25.45%;
        flex-basis: 35.3%;
        flex-shrink: 0;
        flex-grow: 0;
        border-radius: 6px;
        overflow: hidden;
        &-image {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      &-favorite {
        position: absolute;
        bottom: 0;
        right: 0;
        margin: 16px;
        border-radius: 100rem;
        background-color: $light-color;
        @include size(34px);
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }
      &-content {
        &-title a {
          color: $primary-text;
          font-size: clamp(1.125rem, 0.859rem + 1.002vw, 1.5rem);
          font-weight: 500;
          line-height: 29px;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          word-break: break-word;
          transition: color 0.2s ease;
          &:hover {
            color: $primary-color;
          }
        }
        &-subtitle {
          span {
            color: $primary-color;
            font-weight: 400;
            font-size: 16px;
            line-height: 19.36px;
            display: inline-block;
            &:not(:first-child):before {
              content: "|";
              margin: 0 8px;
              color: $primary-color;
            }
          }
        }
        &-desc {
          background-color: #fff4ef;
          border-radius: 5px;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          color: $primary-text;
          padding: 15px 10px;
        }
      }
    }
  }
}

.list-blog-mostViewed {
  .search {
    background-color: rgb(245, 245, 245, 0.9);
    padding: 10px 15px;
    border-radius: 6px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    input {
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      border: none;
      width: 100%;
      outline: none;
      background-color: transparent;
      color: $primary-text;
      &::placeholder {
        color: #afafaf;
      }
    }
    i {
      cursor: pointer;
      color: #d9d9d9;
      font-size: 20px;
    }
  }
  .articles-item {
    display: flex;
    align-items: center;
    gap: 14px;
    justify-content: space-between;
    width: fit-content;
    &-thumbnail {
      max-width: 100px;
      width: 100%;
      border-radius: 5px;
      overflow: hidden;
      img {
        @include size(100%);
        object-fit: cover;
      }
    }
    &-content {
      h1 a {
        color: #3e3e3e;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-word;
      }
      p {
        color: #afafaf;
        font-size: 14px;
        font-weight: 400;
        line-height: 17px;
        margin-top: 8px;
      }
    }
  }
  .list-content {
    display: flex;
    flex-direction: column;
    gap: 15px;
    li a {
      font-weight: 400;
      font-size: 14px;
      line-height: 16.94px;
      color: #111928;
      transition: color 0.2s ease;
      &:hover {
        color: $primary-color;
      }
    }
  }
}

.list-blog-location {
  .caption {
    color: $primary-color;
    font-weight: 700;
    font-size: 24px;
    line-height: 22px;
    text-align: center;
    margin-bottom: clamp(1.563rem, 0.401rem + 4.373vw, 2.5rem);
    text-transform: uppercase;
  }
  .location-item {
    height: 0;
    position: relative;
    padding-bottom: 84.65%;
    background-color: rgb(0, 0, 0, 0.5);
    border-radius: 6px;
    overflow: hidden;
    &-image img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    &-content {
      position: absolute;
      left: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      @include size(100%);
      padding: 20px;
      background-color: rgba(0, 0, 0, 0.1);
      &-title {
        color: $light-color;
        font-weight: 600;
        font-size: 36px;
        line-height: 22px;
        text-transform: uppercase;
        padding-bottom: 10px;
        display: inline-block;
        text-align: center;
        &:after {
          content: "";
          display: block;
          text-align: center;
          position: relative;
          left: 50%;
          transform: translateX(-50%);
          width: 50%;
          height: 4px;
          background-color: $primary-color;
        }
      }
    }
  }
}

.blog-detail-post {
  .caption {
    font-weight: 400;
    font-size: clamp(1.25rem, 0.54rem + 2.671vw, 2.25rem);
    line-height: 1.4;
  }
  .thumbnail {
    height: 0;
    position: relative;
    padding-bottom: 56.25%;
    border-radius: 6px;
    overflow: hidden;
    &-image {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .content {
    max-height: 200px;
    overflow: hidden;
    transition: max-height 0.5s ease;
    &.expanded {
      max-height: none;
    }
  }
  .readmore-btn {
    display: none;
    justify-content: center;
    margin-top: clamp(0.625rem, -0.931rem + 3.241vw, 1.5rem);
    width: 100%;
    padding: 20px 0;
    background: linear-gradient(180deg, #ffffff 50%, #999999 100%);
    cursor: pointer;
    &.show {
      display: inline-flex;
    }
    &-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 20px;
      span {
        font-size: clamp(1.25rem, 0.833rem + 1.778vw, 1.5rem);
        color: #999999;
        font-weight: 500;
        line-height: 24px;
      }
    }
  }
}

.blog-detail-comment {
  .date {
    color: #928c8c;
    font-weight: 500;
    font-size: 16px;
    line-height: 19.36px;
    margin-bottom: 24px;
  }
  .info-author {
    box-shadow: rgb(108, 117, 124, 0.2) 0px 1px 4px;
    border: 1px solid rgb(108, 117, 124, 0.2);
    background-color: #f9f9f9;
    padding: 25px;
    border-radius: 6px;
    margin: 24px 0;
    &-header {
      display: flex;
      align-items: center;
      gap: 15px;
      &-avatar {
        @include size(56px);
        border-radius: 50%;
        overflow: hidden;
        object-fit: cover;
      }
      &-content {
        h4 {
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          color: #111928;
          margin-bottom: 2px;
        }
        p {
          font-weight: 700;
          font-size: 16px;
          line-height: 22px;
          color: #111928;
        }
      }
    }
    &-content {
      p {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #637381;
        margin: 16px 0;
      }
      a {
        display: inline-block;
        border-radius: 3px;
        border: 1px solid $primary-color;
        padding: 8px;
        color: $primary-color;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        transition: all 0.2s ease;
        &:hover {
          color: $light-color;
          background-color: $primary-color;
        }
      }
    }
  }
  .stats {
    display: flex;
    align-items: center;
    gap: 14px;
    width: 100%;
    white-space: nowrap;
    overflow-x: auto;
    padding-bottom: 15px;
    span {
      font-weight: 400;
      font-size: 16px;
      line-height: 19.36px;
      color: #637381;
      &:not(:last-child)::after {
        content: "|";
        margin: 0 8px;
        color: $primary-color;
      }
    }
  }
  .comment-section {
    h2 {
      font-weight: 700;
      font-size: 20px;
      line-height: 24.2px;
      margin: 9px 0 24px 0;
    }
    &-login {
      font-weight: 400;
      font-size: 16px;
      line-height: 19.36px;
      color: #111928;
      a {
        color: $primary-color;
        transition: opacity 0.2s ease;
        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
}

.blog-detail-location {
  background: linear-gradient(to right, #a1c4fd, #c2e9fb);
  padding: 20px 24px;
  border-radius: 6px;
  .caption {
    font-size: 20px;
    font-weight: 700;
    line-height: 24.2px;
    color: $light-color;
    text-transform: uppercase;
  }
  .button-subscribe {
    font-weight: 400;
    font-size: 16px;
    color: $light-color;
    background-color: $primary-color;
    line-height: 24px;
    padding: 6.875px 15px;
    border-radius: 6px;
    text-align: center;
    transition: opacity 0.2s ease;
    &:hover {
      opacity: 0.8;
    }
  }
  .post-tabs {
    display: flex;
    align-items: center;
    gap: 32px;
    overflow-x: auto;
    white-space: nowrap;
    width: 100%;
    padding-bottom: 14px;
    margin-bottom: 16px;
    &::-webkit-scrollbar {
      height: 6px;
    }
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
      border-radius: 5px;
    }
    &::-webkit-scrollbar-thumb {
      background: $primary-color;
      border-radius: 5px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: darken($primary-color, 5%);
    }
    &-item {
      color: $light-color;
      font-size: 14px;
      font-weight: 600;
      line-height: 22px;
      padding-bottom: 12px;
      border-bottom: 2px solid $light-color;
      &.active {
        color: $primary-color;
        border-color: $primary-color;
        pointer-events: none;
      }
    }
  }
  .post {
    &-pane {
      display: none;
      &.active {
        display: block;
      }
    }
    &-item {
      border-radius: 6px;
      position: relative;
      overflow: hidden;
      height: 0;
      padding-bottom: 120.35%;
      &-background {
        @include size(100%);
        position: absolute;
        top: 0;
        left: 0;
        object-fit: cover;
      }
      &-content {
        position: absolute;
        bottom: 0;
        left: 0;
        padding: 20px;
        width: 100%;
        background-color: rgb(89 86 86 / 30%);
        h1 a {
          color: $light-color;
          font-size: 400px;
          font-size: 20px;
          line-height: 22px;
          padding-bottom: 5px;
          margin-bottom: 10px;
          position: relative;
          display: inline-block;
          transition: color 0.2s ease;
          &:hover {
            color: $primary-color;
          }
          &:after {
            content: "";
            position: absolute;
            left: 0;
            bottom: 0;
            width: 42%;
            height: 1.5px;
            background-color: $light-color;
          }
        }
        &-author {
          img {
            @include size(30px);
            border-radius: 50%;
            object-fit: cover;
          }
          span {
            color: $light-color;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
          }
        }
        &-like {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 12px;
          color: $light-color;
          i {
            font-size: 20px;
          }
          span {
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
          }
        }
      }
    }
  }
}

@include maxWidth(767px) {
  .list-blog-articles {
    .blog-item {
      padding: 15px;
      display: block;
      &-thumbnail {
        padding-bottom: 56.25%;
        border-radius: unset;
      }
      &-content {
        margin-top: 15px;
      }
    }
  }
}
