.event-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(3, 1fr);
  gap: clamp(0.875rem, -1.307rem + 3.409vw, 1.25rem);
  .card {
    grid-column: 1/2;
    grid-row: 1/3;
  }
  &-thumbnail {
    height: 0;
    position: relative;
    padding-bottom: 69.85%;
    overflow: hidden;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    img {
      transition: all 0.2s ease;
      position: absolute;
      top: 0;
      left: 0;
      @include size(100%);
      object-fit: cover;
      &:hover {
        transform: scale(1.1);
        opacity: 0.8;
      }
    }
  }
  &-card {
    border-radius: 5px;
    border: 1.5px solid $primary-color;
  }
  @include maxWidth(1023px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @include maxWidth(599px) {
    grid-template-columns: 1fr;
  }
}

.card-event {
  position: relative;
  border-radius: 6px;
  height: 300px;
  overflow: hidden;
  &-image {
    @include size(100%);
    object-fit: cover;
  }
  &-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 10px;
    color: $light-color;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
    background-color: rgba(0, 0, 0, 0.3);
    text-align: center;
    h3 a {
      font-size: clamp(1.5rem, 0.968rem + 2.003vw, 2.25rem);
      text-transform: uppercase;
      position: relative;
      padding-bottom: 8px;
      margin-bottom: 2px;
      color: $light-color;
      display: inline-block;
      &:after {
        content: "";
        @include blockCenter("left");
        bottom: 0;
        height: 5px;
        width: 30%;
        background-color: $primary-color;
      }
    }
    span {
      font-size: 24px;
    }
  }
}

.about-content {
  h4 {
    color: $primary-text;
    font-weight: 700;
    font-size: clamp(1rem, 0.823rem + 0.668vw, 1.25rem);
    line-height: 24.2px;
  }
  p {
    color: $primary-text;
    font-weight: 400;
    font-size: clamp(1rem, 0.823rem + 0.668vw, 1.25rem);
    line-height: 1.5;
  }
}

.event-info {
  border-radius: 10px;
  background-color: #f5f5f5;
  padding: 24px 20px;
  margin-top: 24px;
  &-caption {
    font-size: 20px;
    color: $primary-text;
    font-weight: 700;
    padding-bottom: 16px;
    margin-bottom: 16px;
    border-bottom: 1.5px dashed rgba(108, 117, 124, 0.2);
  }
  &-list {
    display: flex;
    align-items: flex-start;
    gap: 12px;
    &:not(:last-child) {
      margin-bottom: 16px;
    }
    img {
      width: 20px;
    }
    span {
      color: #7e7b7b;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
    }
  }
}
