.post-moment {
  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
    margin-bottom: 25px;
    &-title {
      font-size: 28px;
      color: $primary-color;
      font-weight: 700;
    }
    &-button {
      font-size: 20px;
      font-weight: 700;
      border-radius: 10px;
      padding: 8px 16px;
      background-color: $primary-color;
      color: $light-color;
      display: inline-block;
      transition: opacity 0.2s ease;
      @media (hover: hover) {
        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
  &-card {
    &-image {
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
      overflow: hidden;
      height: 200px;
      width: 100%;
      @media (hover: hover) {
        &:hover img {
          transform: scale(1.1);
          opacity: 0.8;
        }
      }
      img {
        transition: all 0.8s cubic-bezier(0.075, 0.82, 0.165, 1);
        @include size(100%);
        object-fit: cover;
      }
    }
  }
  &-content {
    border: 1.5px solid rgb(108, 117, 124, 0.2);
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    padding: 24px 20px;
    &-title {
      font-size: 15px;
      color: $primary-text;
      font-weight: bold;
      margin-bottom: 15px;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      word-break: break-word;
      transition: color 0.2s ease;
      &:hover {
        color: $primary-color;
      }
    }
    &-footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 5px;
      white-space: nowrap;
    }
    &-time,
    &-view {
      font-size: 12px;
      color: #6c757c;
      font-weight: 400;
    }
    &-view {
      display: flex;
      align-items: center;
      gap: 6px;
    }
  }
  &-readmore {
    display: flex;
    justify-content: center;
    margin-top: 24px;
    a {
      font-size: 20px;
      color: $primary-color;
      font-weight: 700;
      border: 1.5px solid $primary-color;
      border-radius: 10px;
      padding: 8px 16px;
      font-size: 20px;
      color: $primary-color;
      font-weight: 700;
      transition: all 0.2s linear;
      &:hover {
        background-color: $primary-color;
        color: $light-color;
      }
    }
  }
}

.banner-moment {
  @include background-image(
    "../images/banner-header.png",
    "../../../../assets/images/banner-header.png"
  );
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: clamp(31.25rem, 20.313rem + 14.583vw, 37.813rem);
  position: relative;
}
.search-moment {
  position: absolute;
  bottom: 75px;
  left: 50%;
  transform: translateX(-50%);
  background-color: $light-color;
  padding: 28px 40px;
  border-radius: 6px;
  max-width: 878px;
  width: calc(100% - 28px);
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  gap: 20px;
  &-item {
    display: flex;
    gap: 10px;
    align-items: flex-start;
    &-button {
      border-radius: 10px;
      padding: 16px;
      background-color: $primary-color;
      font-size: 20px;
      color: $light-color;
      font-weight: 700;
      display: inline-block;
      width: 100%;
      text-align: center;
      transition: opacity 0.2s ease;
      @media (hover: hover) {
        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
  &-content {
    display: flex;
    flex-direction: column;
    gap: 6px;
    width: 100%;
    h4 {
      font-size: 16px;
      color: $primary-text;
      font-weight: 600;
      line-height: normal;
    }
    input {
      font-family: inherit;
      color: $primary-text;
      outline: none;
      border: none;
      font-size: 16px;
      &::placeholder {
        color: $primary-color;
      }
    }
  }
  &-dropdown {
    display: flex;
    gap: 5px;
    align-items: center;
    cursor: pointer;
    position: relative;
    width: 75%;
    span {
      font-size: 16px;
      color: $primary-color;
      font-weight: 400;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      word-break: break-word;
    }
    &-list {
      position: absolute;
      top: calc(100% + 6px);
      left: 0;
      width: 100%;
      background-color: $light-color;
      max-height: 200px;
      overflow-y: auto;
      overscroll-behavior: none;
      display: none;
      z-index: 50;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
      &::-webkit-scrollbar {
        width: 5px;
      }
      &::-webkit-scrollbar-track {
        background-color: rgb(108, 117, 124, 0.2);
      }
      &::-webkit-scrollbar-thumb {
        background-color: $primary-color;
        border-radius: 6px;
      }
      li {
        font-size: 15px;
        line-height: 1.5;
        color: $primary-text;
        padding: 8.5px 16px;
        text-transform: capitalize;
        transition: all 0.2s ease;
        width: 100%;
        &:not(:first-child) {
          border-top: 1px solid rgba(108, 117, 124, 0.2);
        }
        &:hover {
          @media (hover: hover) {
            background-color: #ff5b00;
            color: #fff;
          }
        }
      }
    }
  }
}

.detail-moment {
  &-thumbnail {
    height: 0;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    padding-bottom: 56.25%;
    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    &-heart {
      position: absolute;
      bottom: 0;
      right: 0;
      background-color: $light-color;
      border-radius: 100rem;
      @include size(40px);
      margin: 24px;
      cursor: pointer;
      i {
        position: absolute;
        @include blockCenter("both");
        color: $primary-color;
        font-size: 18.5px;
      }
    }
  }
  &-caption {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    margin-bottom: 25px;
    padding-bottom: 30px;
    border-bottom: 1.5px dashed $primary-color;
  }
  &-user {
    display: flex;
    align-items: center;
    gap: 20px;
    img {
      @include size(50px);
      border-radius: 100rem;
    }
    h4 {
      font-size: 24px;
      color: $primary-text;
      font-weight: 700;
    }
  }
  &-button {
    font-size: 20px;
    color: $light-color;
    font-weight: 700;
    border-radius: 10px;
    padding: 8px 16px;
    background-color: $primary-color;
    transition: opacity 0.2s ease;
    &:hover {
      opacity: 0.8;
    }
  }
  &-content {
    &-title {
      font-size: 20px;
      color: $primary-text;
      font-weight: 700;
    }
    &-time {
      margin: 15px 0;
      display: block;
      font-size: 12px;
      color: #6c757c;
      font-weight: 400;
    }
    &-description {
      font-size: 20px;
      color: $primary-text;
      font-weight: 400;
      line-height: 1.6;
    }
    &-image {
      height: 0;
      position: relative;
      padding-bottom: 43.95%;
      border-radius: 5px;
      overflow: hidden;
      margin: 16px 0 20px 0;
      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  &-comment {
    display: flex;
    flex-direction: column;
    gap: 10px;
    h1 {
      font-size: 20px;
      color: $primary-text;
      font-weight: 700;
    }
    p {
      color: $primary-text;
      font-size: 16px;
      font-weight: 400;
      a {
        color: $primary-color;
        transition: opacity 0.2s ease;
        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
  &-related {
    margin-top: 30px;
    h1 {
      font-size: 20px;
      color: $primary-text;
      font-weight: 700;
      margin-bottom: 24px;
    }
  }
}

// Responsive all sreen
@include maxWidth(1023px) {
  .detail-moment-content-description {
    font-size: 18px;
  }
  .detail-moment-content-image {
    padding-bottom: 56.25%;
  }
}
@include maxWidth(767px) {
  .detail-moment-content-description {
    font-size: 16px;
  }
  .post-moment-header {
    flex-direction: column;
    &-title {
      font-size: 22px;
    }
    &-button {
      font-size: 18px;
    }
  }
  .post-moment-readmore a {
    font-size: 18px;
  }
  .banner-moment {
    height: 400px;
  }
  .search-moment {
    grid-template-columns: 1fr;
    padding: 25px 30px;
    &-item {
      &-button {
        padding: 10px;
        font-size: 17px;
        letter-spacing: 1px;
      }
      img {
        display: none;
      }
    }

    &-dropdown {
      width: 100%;
    }
  }
}
@include maxWidth(599px) {
  .detail-moment-user h4,
  .detail-moment-button {
    font-size: 18px;
  }
}
@include maxWidth(424px) {
  .detail-moment-caption {
    flex-direction: column;
    gap: 8px;
  }
  .detail-moment-caption {
    padding-bottom: 20px;
  }
}
@include maxWidth(374px) {
  .search-moment {
    padding: 20px;
  }
  .post-moment-content-footer {
    flex-direction: column;
  }
}
