.location-detail {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1.5px solid #d9d9d9;
  .box {
    padding: 24px;
    border: 1.5px solid #d9d9d9;
    border-radius: 6px;
  }
  .location-detail-gallery {
    .list-hotelDetail-gallery-item:first-child {
      position: relative;
      .content {
        position: absolute;
        z-index: 10;
        top: clamp(1.25rem, 0.011rem + 4.665vw, 2.25rem);
        left: clamp(1.25rem, 0.011rem + 4.665vw, 2.25rem);
        &-title {
          font-size: clamp(1.875rem, 1.41rem + 1.749vw, 2.25rem);
          font-weight: 400;
          color: $light-color;
          margin-bottom: 15px;
        }
        &-desc {
          font-size: clamp(1rem, 0.69rem + 1.166vw, 1.25rem);
          font-weight: 400;
          color: $light-color;
        }
      }
      &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.2);
      }
    }
  }
  .location-detail-exp {
    .content {
      @include background-image(
        "../images/location-bg.png",
        "../../../../assets/images/location-bg.png"
      );
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
      height: 100%;
      color: $light-color;
      border-radius: 5px;
      overflow: hidden;
      padding: 75px 24px;
      position: relative;
      &-shadow {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.3);
      }
      &-wrapper {
        display: flex;
        flex-direction: column;
        gap: 12px;
        z-index: 10;
        position: relative;
        height: 100%;
      }
      &-title {
        font-weight: 700;
        font-size: 28px;
        line-height: 32px;
        text-transform: capitalize;
      }
      &-desc {
        font-weight: 600;
        font-size: 14px;
        line-height: 24px;
      }
      &-button {
        margin-top: auto;
        border-radius: 5px;
        background-color: $light-color;
        display: inline-block;
        text-align: center;
        padding: 10px 20px;
        color: $primary-color;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        max-width: 140px;
        width: 100%;
        transition: all 0.2s ease;
        &:hover {
          background-color: $primary-color;
          color: $light-color;
        }
      }
    }
  }

  .location-detail-map {
    .content {
      font-weight: 400;
      font-size: clamp(1rem, 0.845rem + 0.583vw, 1.125rem);
      line-height: 1.5;
      color: $primary-text;
    }
    .readmore-btn {
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      text-align: center;
      cursor: pointer;
      color: $primary-color;
      border: 1px solid $primary-color;
      border-radius: 6px;
      padding: 5px 15px;
      margin-top: 25px;
    }
  }

  .location-detail-comment {
    .info {
      display: flex;
      align-items: center;
      gap: 18px;
      margin-bottom: 30px;
      &-text {
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: $primary-color;
      }
      &-dots {
        @include size(6px);
        border-radius: 100rem;
        display: inline-block;
        background-color: #a9a7a7;
      }
    }
    .comment-box {
      h5 {
        font-weight: 700;
        font-size: 20px;
        line-height: 24.2px;
        margin-bottom: 24px;
      }
      &-main {
        border: 1.5px solid $primary-color;
        border-radius: 6px;
        padding: 20px 24px;
        display: flex;
        flex-direction: column;
        gap: 25px;
        align-items: flex-start;
        .send {
          max-width: 238px;
          width: 100%;
          background-color: $primary-color;
          color: $light-color;
          border-radius: 5px;
          padding: 9px 15px;
          text-align: center;
          font-weight: 400;
          font-size: 16px;
          transition: all 0.2s ease;
          &:hover {
            opacity: 0.8;
          }
        }
        textarea {
          resize: none;
          background-color: #f9f9f9;
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          &::placeholder {
            color: rgb(108, 117, 124, 0.3);
          }
        }
      }
    }
  }
  .location-detail-trending {
    border: 1.5px solid $primary-color;
    border-radius: 5px;
    background-color: #f9f9f9;
    padding: 25px;
    .trending-caption {
      font-weight: 700;
      color: $primary-color;
      line-height: 24.2px;
      font-size: 20px;
      margin-bottom: 20px;
    }
    .trending-item {
      display: flex;
      align-items: center;
      gap: clamp(0.875rem, 0.41rem + 1.749vw, 1.25rem);
      &-image {
        max-width: 100px;
        width: 100%;
        border-radius: 5px;
        overflow: hidden;
        img {
          @include size(100%);
          object-fit: cover;
        }
      }
      &-content {
        display: flex;
        flex-direction: column;
        gap: 8px;
        h4 {
          font-weight: 500;
          font-size: 15px;
          line-height: 20px;
          a {
            color: rgb(56, 55, 55);
          }
        }
        &-user {
          display: flex;
          align-items: center;
          gap: 8px;
          span {
            color: #6a6969;
            font-weight: 400;
            font-size: 15px;
            line-height: 17px;
          }
        }
      }
    }
  }
}
