.container-guide {
  max-width: 1200px;
  width: 100%;
  padding: 0 12px;
  margin: 0 auto;
}

.top-guide {
  margin-top: 10px;
  &-list {
    display: flex;
    white-space: nowrap;
    overflow-x: auto;
    padding-bottom: 14px;
    max-width: 100%;
    gap: 12px;
    align-items: center;
  }
  &-tab {
    border: 1.5px solid $primary-color;
    border-radius: 5px;
    padding: 8px 16px;
    color: $primary-color;
    font-size: 20px;
    font-weight: 400;
    transition: all 0.2s ease;
    &.active {
      background-color: $primary-color;
      color: $light-color;
      pointer-events: none;
    }
    &:hover {
      @media (hover: hover) {
        background-color: $primary-color;
        color: $light-color;
      }
    }
  }
  &-pane {
    display: none;
    &.active {
      display: block;
    }
  }
  &-item {
    border-radius: 5px;
    overflow: hidden;
    position: relative;
    &::before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      @include size(100%);
      background-color: rgba(241, 241, 241, 0.5);
    }
    img {
      object-fit: cover;
      @include size(100%);
      display: inline-block;
    }
  }
  &-content {
    display: flex;
    flex-direction: column;
    gap: 5px;
    text-align: left;
    padding: 12px;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 4;
    h3 {
      color: $primary-color;
      font-size: 14px;
      font-weight: 500;
    }
    p {
      font-size: 12px;
      color: $primary-text;
      font-weight: 400;
    }
  }
  // Custom Owl Carousel
  .owl-carousel {
    .owl-nav .owl-prev {
      left: 0;
    }
    .owl-nav .owl-next {
      right: 0;
    }
  }
}

.thumbnail-guide {
  height: 0;
  position: relative;
  padding-bottom: 53.25%;
  border-radius: 6px;
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.content-guide {
  color: $primary-text;
  &-title {
    font-size: 20px;
    color: $primary-color;
    font-weight: 700;
    margin-bottom: 15px;
  }
  &-name {
    margin: 24px 0 15px 0;
    font-size: 24px;
    font-weight: 700;
  }
  &-date {
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 15px;
  }
  &-description {
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 15px;
  }
  &-about {
    font-size: 16px;
    font-weight: 500;
  }
  &-list {
    list-style-type: disc;
    padding-left: 30px;
    li {
      font-size: 18px;
      font-weight: 400;
      margin-bottom: 5px;
    }
  }
}

.info-guide,
.verification-guide {
  border-radius: 6px;
  background-color: #f5f5f5;
  padding: 24px 20px;
}
.info-guide {
  &-title {
    font-size: 20px;
    font-weight: 700;
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px dashed rgb(108, 117, 124, 0.4);
    color: $primary-color;
  }
  &-line {
    margin-bottom: 16px;
    border-bottom: 1px dashed rgb(108, 117, 124, 0.4);
  }
  &-item {
    display: flex;
    align-items: flex-start;
    gap: 12px;
  }
  &-content {
    display: flex;
    flex-direction: column;
    gap: 8px;
    h4 {
      font-size: 16px;
      color: $primary-color;
      font-weight: 500;
    }
    p {
      font-size: 14px;
      color: $primary-text;
      font-weight: 400;
    }
  }
  &-chat {
    margin-top: 16px;
    border-radius: 5px;
    padding: 8px;
    background-color: #fcf6f2;
    border: 1px solid $primary-color;
    color: $primary-color;
    font-size: 14px;
    font-weight: 700;
    display: inline-block;
    width: 100%;
    text-align: center;
    transition: all 0.2s ease;
    &:hover {
      background-color: $primary-color;
      color: $light-color;
    }
  }
}

.verification-guide {
  h4 {
    font-size: 20px;
    color: $primary-text;
    font-weight: 700;
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px dashed rgb(108, 117, 124, 0.4);
  }
  &-list {
    display: flex;
    flex-direction: column;
    gap: 14px;
  }
  &-item {
    display: flex;
    align-items: center;
    gap: 20px;
    span {
      font-size: 16px;
      color: $primary-text;
      font-weight: 400;
    }
  }
}

.carousel-card-guide {
  .location-guide {
    display: flex;
    align-items: center;
    gap: 14px;
    img {
      max-width: 20px;
    }
  }
  @include maxWidth(1199px) {
    display: none !important;
  }
}

.dropdown-guide {
  border-radius: 5px;
  border: 1px solid rgb(34, 34, 34, 0.2);
  padding: 24px 20px;
  h1 {
    font-size: 24px;
    color: $primary-color;
    font-weight: 700;
  }
  &-rating {
    display: flex;
    align-items: center;
    gap: 8px;
    margin: 12px 0;
    i {
      color: #ffc107;
    }
  }
  &-list {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
  &-select {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    position: relative;
    &-input,
    span {
      font-size: 14px;
      color: $primary-color;
      font-weight: 500;
      width: 100%;
      display: inline-block;
      text-align: left !important;
      border: 1.5px solid $primary-color;
      border-radius: 5px;
      padding: 8px 12px 8px 46px;
      background-color: $light-color;
      cursor: pointer;
    }
    &-input {
      outline: none;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      word-break: break-word;
    }
    &-icon {
      @include blockCenter("top");
      left: 12px;
    }
  }
  &-button {
    border-radius: 6px;
    padding: 10px 8px;
    background-color: $primary-color;
    font-size: 14px;
    color: $light-color;
    font-weight: 700;
    text-align: center;
    transition: opacity 0.2s ease-in;
    &:hover {
      opacity: 0.8;
    }
  }
  #dropdown-guide-room {
    position: relative;
  }
  &-room {
    position: absolute;
    top: calc(100% + 2px);
    left: 0;
    width: 100%;
    box-shadow: rgba(100, 100, 111, 0.2) 0 7px 29px 0;
    border: 1.8px solid $primary-color;
    background-color: $light-color;
    border-radius: 5px;
    overflow: hidden;
    z-index: 20;
    padding: 12px 24px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    display: none;
    &.active {
      display: flex;
    }
  }
  &-room-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    &-quantity {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 5px;
      height: 25px;
      button {
        border-radius: 5px;
        background-color: #f9f9f9;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 25px;
        height: 100%;
        user-select: none;
        img {
          width: 12px;
        }
        &:active {
          opacity: 0.6;
        }
      }
      input {
        width: 30px;
        text-align: center;
        height: 100%;
        border-radius: 5px;
        border: 1px solid rgb(0 0 0 / 30%);
        outline-color: $primary-color;
        font-size: 14px;
      }
    }
  }
}

.modal-guide {
  &-caption {
    font-size: 18px;
    text-transform: uppercase;
    color: $primary-color;
    font-weight: 700;
  }
  &-header {
    display: flex;
    align-items: center;
    gap: 20px;
  }
  &-avatar {
    max-width: 80px;
    height: 80px;
    overflow: hidden;
    border-radius: 10rem;
    display: inline-block;
    img {
      object-fit: cover;
      @include size(100%);
    }
  }
  &-content {
    font-size: 14px;
    color: $primary-text;
    font-weight: 400;
    display: flex;
    flex-direction: column;
    gap: 10px;
    h1 {
      font-size: 20px;
      color: $primary-color;
      font-weight: 700;
    }
    p {
      font-size: 14px;
      color: $primary-text;
      font-weight: 400;
    }
  }
  &-button {
    width: 100%;
    border-radius: 6px;
    padding: 14px;
    background-color: $primary-color;
    color: $light-color;
    font-size: 18px;
    font-weight: 700;
    text-align: center;
    transition: opacity 0.2s ease-in;
    &:hover {
      opacity: 0.8;
    }
  }
}

// Responsive all Screen
@include maxWidth(1023px) {
  .top-guide-tab {
    font-size: 16px;
    padding: 8px 12px;
  }
}
@include maxWidth(599px) {
  .content-guide-description,
  .content-guide-list li {
    font-size: 16.5px;
  }
  .modal-guide-header {
    display: flex;
    flex-direction: column;
    text-align: center;
    gap: 15px;
  }
  .modal-guide-content {
    gap: 4px;
  }
  .modal-guide-caption,
  .modal-guide-content h1 {
    font-size: 16.5px;
  }
}
