.list-hotelDetail {
  margin-top: 20px;
  padding-top: 36px;
  border-top: 1.5px solid #d9d9d9;
  &-tag {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 12px;
    li {
      a {
        border-radius: 5px;
        background-color: rgb(108, 117, 124, 0.2);
        padding: 5px 8px;
        font-size: 14px;
        color: #4e4c4c;
        font-weight: 400;
        display: inline-block;
        transition: opacity 0.2s ease;
        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
  &-gallery {
    margin-top: 25px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 10px;
    height: 460px;
    &-item {
      border-radius: 6px;
      overflow: hidden;
      cursor: zoom-in;
      display: inline-block;
      &:nth-child(n + 4) {
        display: none;
      }
      img {
        @include size(100%);
        object-fit: cover;
      }
    }
    &-item:first-child {
      grid-column: 1 / 4;
      grid-row: 1 /3;
    }
    &-item:nth-child(2) {
      grid-column: 4 / 5;
      grid-row: 1 / 2;
    }
    &-item:nth-child(3) {
      grid-column: 4 / 5;
      grid-row: 2 / 3;
    }
  }
  &-desc {
    border-radius: 10px;
    background-color: #fcf6f2;
    padding: 20px 24px;
  }
  &-room {
    border-radius: 10px;
    border: 1.5px solid #d9d9d9;
    padding: 24px 12px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    &-title {
      font-size: 14px;
      color: $primary-text;
      font-weight: 400;
    }
    &-price {
      font-family: Inter;
      font-size: 24px;
      color: $primary-color;
      text-transform: uppercase;
      font-weight: 700;
    }
    &-button {
      border-radius: 4px;
      padding: 10px 8px;
      background-color: $primary-color;
      font-size: 14px;
      color: $light-color;
      font-weight: 700;
      display: inline-block;
      transition: opacity 0.2s ease;
      text-align: center;
      &:hover {
        opacity: 0.8;
      }
    }
  }
  &-searchRoom {
    margin-top: 24px;
    padding: 16px 20px;
    border-radius: 10px;
    padding: 16px 20px;
    background-color: rgb(249, 249, 249, 0.9);
    &-caption {
      font-size: 16px;
      line-height: 1.5;
      color: $primary-color;
      font-weight: bold;
      margin-bottom: 24px;
    }
    &-list {
      display: grid;
      grid-template-columns: 1.7fr 1fr 1fr 1fr;
      gap: 12px;
    }
    &-item {
      border: 1px solid $primary-color;
      border-radius: 6px;
      padding: 16px;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 12px;
    }
    &-title {
      color: $primary-text;
      font-weight: 500;
      font-size: 14px;
      display: flex;
      align-items: center;
      gap: 12px;
      white-space: nowrap;
      i {
        font-size: 15px;
      }
    }
    &-date {
      color: $primary-color;
      font-weight: 600;
      font-size: 14px;
      outline: none;
      border: none;
      text-align: center;
    }
    &-button {
      border-radius: 5px;
      padding: 8px;
      background-color: $primary-color;
      font-size: 20px;
      line-height: 1.1;
      color: $light-color;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      font-weight: 500;
      transition: opacity 0.2s ease;
      white-space: nowrap;
      &:hover {
        opacity: 0.8;
      }
    }
  }

  .services-detail {
    border-radius: 10px;
    background-color: #f5f5f5;
    padding: 24px 20px;
    margin-top: 24px;
    &-caption {
      font-size: 20px;
      color: $primary-text;
      font-weight: 700;
      padding-bottom: 16px;
      margin-bottom: 16px;
      border-bottom: 1.5px dashed rgb(108, 117, 124, 0.2);
    }
    &-title {
      font-size: 16px;
      color: $primary-color;
      font-weight: bold;
      margin-bottom: 16px;
    }
    &-list {
      display: flex;
      flex-direction: column;
      gap: 15px;
      &:not(:last-child) {
        margin-bottom: 24px;
      }
      li {
        display: flex;
        align-items: center;
        gap: 25px;
        span {
          font-size: 16px;
          color: $primary-text;
          font-weight: 400;
        }
      }
    }
    &-buttonMobile {
      padding: 8px;
      background-color: $light-color;
      font-size: 20px;
      color: $primary-color;
      display: inline-block;
      text-align: center;
      border-radius: 5px;
      border: 1px solid $primary-color;
      font-weight: 700;
      width: fit-content;
    }
    @include maxWidth(991px) {
      position: fixed;
      top: 0;
      right: 0;
      max-width: 300px;
      width: 100%;
      height: 100vh;
      transform: translateX(100%);
      transition: transform 0.3s ease;
      background-color: $light-color;
      z-index: 200;
      overflow-y: auto;
      border-radius: unset;
      margin-top: unset;
      &.show {
        transform: translateX(0);
      }
    }
  }
}

.room-detail {
  border-bottom: 1.5px solid $primary-color;
  padding-bottom: 30px;
  margin-bottom: 30px;
  &-thumbnail {
    height: 0;
    position: relative;
    padding-bottom: 70.85%;
    border-radius: 8px;
    overflow: hidden;
    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &-button {
    border-radius: 5px;
    padding: 8px;
    background-color: #fff4ef;
    font-size: 14px;
    color: $primary-color;
    font-weight: 700;
    text-align: center;
    transition: background-color 0.2s ease;
    &:hover {
      background-color: #ffe1d3;
    }
  }
  &-content {
    &-title {
      font-size: clamp(1rem, 0.583rem + 1.778vw, 1.25rem);
      color: $primary-text;
      font-weight: 700;
      margin-bottom: 16px;
    }
    &-list {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      gap: 14px 24px;
      padding-bottom: 16px;
      margin-bottom: 16px;
      border-bottom: 1px solid #e1dcd9;
      li {
        display: flex;
        align-items: center;
        gap: 8px;
      }
    }
    &-listSecond {
      display: flex;
      flex-wrap: wrap;
      gap: 14px 20px;
      li {
        font-size: 14px;
        color: #4ecb71;
        font-weight: 500;
        display: flex;
        align-items: center;
        gap: 8px;
      }
    }
    &-price {
      display: flex;
      flex-direction: column;
      gap: 5px;
      margin-left: auto;
      max-width: 150px;
      width: 100%;
      &-vnd {
        font-size: 16px;
        color: $primary-color;
        font-weight: 700;
      }
      &-text {
        font-size: 14px;
        color: #b0abab;
        font-weight: 400;
        text-align: right;
      }
    }
    &-cart {
      background-color: transparent;
      color: $primary-color;
      border: 1px solid $primary-color;
    }
    &-cart,
    &-bookRoom {
      padding: 10px 12px;
      font-size: 14px;
      font-weight: 700;
      border-radius: 5px;
      transition: opacity 0.2s ease;
      &:hover {
        opacity: 0.8;
      }
    }
    &-bookRoom {
      color: $light-color;
      background-color: $primary-color;
    }
  }
}
// test
.room-info {
  max-width: 326px;
  width: 100%;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 15px;
  padding: 20px 24px;
  border: 2px solid $primary-color;
  background-color: $light-color;
  &-wrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
    height: 100%;
    overscroll-behavior: none;
    margin-right: -0.7rem;
    padding-right: 0.5rem;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 5px;
    }
    &::-webkit-scrollbar-track {
      border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: $primary-color;
      border-radius: 10px;
    }
  }
  &-list {
    display: flex;
    flex-direction: column;
    gap: 16px;
    border-bottom: 1px solid #ccc;
    padding-bottom: 12px;
    h4 {
      font-size: 16px;
      color: $primary-text;
      font-weight: bold;
    }
    ul {
      display: flex;
      flex-direction: column;
      gap: 12px;
      li {
        display: flex;
        gap: 12px;
        font-size: 14px;
        color: $primary-text;
      }
    }
  }
  &-footer {
    display: flex;
    flex-direction: column;
    gap: 10px;
    span {
      font-size: 14px;
      color: $primary-text;
    }
    b {
      color: $primary-color;
      font-size: 24px;
      font-weight: 700;
      text-transform: uppercase;
    }
    &-add,
    &-select {
      display: inline-flex;
      padding: 8px;
      width: 100%;
      align-items: center;
      justify-content: center;
      font-weight: bold;
      border-radius: 6px;
      transition: all 0.2s ease;
      border: 1px solid transparent;
      &:hover {
        opacity: 0.8;
      }
    }
    &-add {
      background-color: transparent;
      border-color: $primary-color;
      color: $primary-color;
    }
    &-select {
      background-color: $primary-color;
      color: $light-color;
    }
  }
}

@include maxWidth(1023px) {
  .list-hotelDetail-gallery {
    display: block;
    height: auto !important;
    &-item {
      position: relative;
      &:nth-child(n + 2) {
        display: none;
      }
      &::before {
        content: "Xem Thêm Hình Ảnh";
        display: flex;
        justify-content: center;
        align-items: center;
        color: $light-color;
        font-weight: bold;
        font-size: 16px;
        position: absolute;
        letter-spacing: 1px;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 30%;
        background-color: rgb(255, 91, 0, 0.4);
        z-index: 5;
      }
    }
  }
}
@include maxWidth(767px) {
  .list-hotelDetail-searchRoom-list {
    grid-template-columns: repeat(2, 1fr);
  }
  .list-hotelDetail-searchRoom-item:first-child {
    grid-column: 1/ 3;
    grid-row: 1 /2;
  }
  .list-hotelDetail-searchRoom-item:nth-child(2) {
    grid-column: 1/ 2;
    grid-row: 2 /3;
  }
  .list-hotelDetail-searchRoom-item:nth-child(3) {
    grid-column: 2/ 3;
    grid-row: 2 /3;
  }
  .list-hotelDetail-searchRoom-button {
    grid-column: 1/ 3;
    grid-row: 3 / 4;
    padding: 15px 8px;
  }
}
@include maxWidth(599px) {
  .list-hotelDetail-gallery {
    height: 320px;
  }
}
@include maxWidth(1199px) {
  .room-info {
    max-width: 100%;
  }
  .modal-dialog {
    max-width: 100%;
    margin: 0;
  }
}
