.vendor {
  &-bg {
    background-color: #f9f9f9;
    padding: 75px 0;
  }
  &-button {
    border-radius: 10px;
    padding: 8px 16px;
    background-color: $primary-color;
    font-size: 16px;
    line-height: 1.5;
    color: $light-color;
    font-weight: 500;
    display: inline-block;
    text-align: center;
    transition: opacity 0.2s ease;
    @media (hover: hover) {
      &:hover {
        opacity: 0.8;
      }
    }
  }
  &-banner {
    height: 0;
    position: relative;
    padding-bottom: 28.35%;
    & > * {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    &-title {
      font-size: 18px;
      line-height: 1.56;
      color: $primary-color;
      font-weight: 700;
      text-align: left;
      text-transform: uppercase;
    }
    &-desc {
      font-size: 18px;
      line-height: 1.56;
      color: $primary-text;
      font-weight: 400;
      text-align: left;
    }
    &-image {
      height: 0;
      position: relative;
      padding-bottom: 73.85%;
      border-radius: 10px;
      overflow: hidden;
      & > * {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  &-business {
    padding: 100px 0;
    position: relative;
    &-bg {
      position: absolute;
      left: 0;
      bottom: 0;
      height: 500px;
      width: 100%;
      z-index: -5;
    }
    &-title {
      font-size: 24px;
      line-height: 1.17;
      color: $primary-color;
      text-align: center;
      font-weight: 700;
      margin-bottom: 24px;
      text-transform: uppercase;
    }
    &-card {
      display: flex;
      flex-direction: column;
      border-radius: 26px;
      overflow: hidden;
      background-color: $light-color;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    }
    &-thumbnail {
      padding-bottom: 74.2%;
      height: 0;
      position: relative;
      & > * {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    &-content {
      padding: 20px;
      p {
        text-align: left;
        font-size: 16px;
        line-height: 1.5;
        color: $primary-text;
        font-weight: bold;
        margin-bottom: 8px;
      }
      span {
        text-align: center;
        font-size: 16px;
        line-height: 1.5;
        color: $primary-color;
        font-weight: bold;
      }
    }
    &-button {
      border-radius: 10px;
      padding: 8px 16px;
      background-color: $primary-color;
      font-size: 24px;
      line-height: 1;
      color: $light-color;
      font-weight: 500;
      max-width: 300px;
      width: 100%;
      text-align: center;
      transition: opacity 0.2s ease;
      display: inline-block;
      &:hover {
        opacity: 0.8;
      }
    }
  }
  &-question {
    &-content {
      border-radius: 30px;
      padding: 24px 20px;
      background-color: #fcf6f2;
    }
    &-caption {
      font-size: 18px;
      line-height: 1.56;
      color: $primary-color;
      font-weight: 700;
    }
    &-desc {
      font-size: 16px;
      line-height: 1.88;
      color: $primary-text;
      font-weight: 400;
      margin: 16px 0;
    }
    &-title {
      font-size: 18px;
      line-height: 1.56;
      color: $primary-color;
      font-weight: 700;
      text-align: center;
      text-transform: uppercase;
    }
    &-list {
      display: flex;
      flex-direction: column;
      gap: 34px;
      margin-top: 16px;
      li {
        display: flex;
        align-items: center;
        gap: 16px;
        span {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          border-radius: 100rem;
          @include size(32px);
          color: $light-color;
          background-color: $primary-color;
        }
        b {
          font-size: 20px;
          line-height: 1;
          font-weight: 600;
        }
      }
    }
    &-register {
      border: 1.5px solid $primary-color;
      border-radius: 30px;
      padding: 24px 20px;
      background-color: #f9f9f9;
      &-title {
        font-size: clamp(1.5rem, -0.278rem + 3.704vw, 2.5rem);
        line-height: 1.2;
        font-weight: 500;
        color: $primary-text;
      }
      &-desc {
        font-size: 18px;
        line-height: 1.56;
        font-weight: 400;
        color: $primary-text;
        padding: 24px 0;
      }
      &-terms {
        font-size: 18px;
        line-height: 1.56;
        font-weight: 400;
        color: $primary-text;
        margin-bottom: 24px;
        a {
          display: inline-block;
          color: $primary-color;
          transition: opacity 0.2s ease;
          &:hover {
            opacity: 0.8;
          }
        }
      }
    }
  }
  &-solution {
    &-caption {
      font-size: 18px;
      line-height: 1.56;
      color: $primary-color;
      font-weight: 700;
      text-transform: uppercase;
    }
    &-desc {
      font-size: 18px;
      line-height: 1.56;
      color: $primary-text;
      font-weight: 400;
      padding: 16px 0;
    }
    &-list {
      display: flex;
      flex-direction: column;
      gap: 5px;
      font-size: 18px;
    }
    &-title {
      font-size: 18px;
      line-height: 1.56;
      color: $primary-color;
      font-weight: 700;
      padding: 16px 0;
    }
    &-button {
      border-radius: 10px;
      padding: 8px 16px;
      background-color: $primary-color;
      font-size: 16px;
      color: $light-color;
      font-weight: 500;
      max-width: 270px;
      width: 100%;
      text-align: center;
      transition: opacity 0.2s ease;
      display: inline-block;
      transition: opacity 0.2s ease;
      margin-top: 16px;
      &:hover {
        opacity: 0.8;
      }
    }
  }
  @include maxWidth(1200px) {
    &-banner {
      padding-bottom: 40.25%;
    }
    &-question-register-desc {
      padding: 15px 0;
      font-size: 15px;
    }
  }
  @include maxWidth(1023px) {
    &-business {
      padding-block: 30px 0;
      margin-bottom: 30px;
      &-title {
        font-size: 18px;
      }
      &-content {
        p,
        span {
          font-size: 14.5px;
        }
      }
      &-bg {
        display: none;
      }
    }
  }
  @include maxWidth(767px) {
    &-bg {
      padding: 30px 0;
    }
    &-content {
      gap: 11px;
      &-desc,
      &-title {
        font-size: 15px;
      }
    }
    &-business {
      padding-block: 30px;
      &-button {
        font-size: 20px;
      }
    }
    &-solution {
      &-desc,
      &-list {
        font-size: 16px;
      }
    }
    &-question {
      &-list {
        gap: 15px;
        li b {
          font-size: 17px;
        }
      }
      &-title {
        font-size: 16px;
      }
    }
  }
}
