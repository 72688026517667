.payment {
  .summary {
    background-color: #f9f9f9;
    border-radius: 10px;
    overflow: hidden;
    border: 1px solid rgb(108 117 124 / 20%);
    margin-bottom: 24px;
    &-item {
      padding: 24px 16px;
      border-bottom: 1px solid rgb(108 117 124 / 20%);
      &-title {
        font-size: 16px;
        line-height: calc(19.36 / 16);
        color: #222222;
        font-weight: bold;
        &--primary {
          color: $primary-color;
        }
      }
      &-date {
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #6c757c;
      }
    }
  }
  .summary-guide {
    &-thumbnail {
      height: 270px;
      border-top-left-radius: 18px;
      border-top-right-radius: 18px;
      overflow: hidden;
      img {
        @include size(100%);
        object-fit: cover;
      }
    }
    &-content {
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
      padding: 12px 12px 30px 12px;
      border: 1.5px solid rgb(108 117 124 / 30%);
    }
    &-avatar {
      max-width: 100px;
      height: 100px;
      border-radius: 100rem;
      overflow: hidden;
      margin: -63px auto 0 auto;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
      img {
        @include size(100%);
        object-fit: cover;
      }
    }
    &-header {
      margin-top: 20px;
      text-align: center;
      display: flex;
      flex-direction: column;
      gap: 10px;
      h1 {
        line-height: 1.5;
        font-size: 15px;
        color: $primary-text;
        font-weight: 500;
      }
      p {
        font-size: 15px;
        color: $primary-color;
        font-weight: bold;
      }
    }
    &-date {
      margin: 20px 0 15px 0;
      display: flex;
      justify-content: space-between;
      gap: 10px;
      text-align: center;
      &-item {
        flex: 1;
        border-radius: 5px;
        border: 1.8px solid $primary-color;
        padding: 8px;
        display: flex;
        flex-direction: column;
        gap: 6px;
        span {
          font-size: 14px;
          color: #a5a5a5;
          font-weight: 400;
          &.font-bold {
            font-weight: bold;
          }
        }
        b {
          font-size: 14px;
          color: $primary-text;
          font-weight: 500;
        }
      }
    }
    &-info {
      display: flex;
      justify-content: space-between;
      gap: 12px;
      &-item {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
        border: 1.5px solid $primary-color;
        border-radius: 5px;
        padding: 5px;
        flex: 1;
        font-size: 12px;
        color: $primary-color;
        font-weight: 500;
      }
    }
    &-footer {
      border-top: 1px solid rgb(108, 117, 124, 0.2);
      padding-top: 20px;
      margin-top: 30px;
      display: flex;
      flex-direction: column;
      gap: 15px;
      &-item {
        display: flex;
        justify-content: space-between;
        gap: 14px;
        align-items: center;
        b {
          font-size: 14px;
          color: $primary-text;
        }
        span {
          font-weight: bold;
          font-size: 14px;
          color: #6c757c;
          &.font-primary {
            color: $primary-color;
          }
        }
      }
    }
  }
  &-box {
    padding: 25px 20px;
    border-radius: 10px;
    border: 1px solid rgb(108 117 124 / 20%);
    background-color: #f9f9f9;
  }
  &-line {
    border: 1.5px dashed #938d8d;
    margin: 24px 0;
  }
  &-infoDetail {
    display: grid;
    gap: 15px;
    grid-template-columns: repeat(2, 1fr);
    font-weight: 400;
    line-height: 16.94px;
    &-title {
      font-size: 15px;
      color: #191919;
    }
    &-price {
      font-size: 16px;
      color: #b5b5b5;
      text-align: right;
    }
    &--second {
      gap: 60px;
      width: fit-content;
    }
  }
  &-total {
    display: flex;
    flex-direction: column;
    gap: 16px;
    &-item {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 6px;
      font-weight: 600;
      font-size: 14px;
      line-height: 16.94px;
      color: #222222;
    }
    &-price {
      color: #7c7c7c;
      text-align: right;
      &--primary {
        color: $primary-color;
      }
    }
  }
  .heading {
    font-weight: 700;
    font-size: 20px;
    line-height: 24.2px;
    color: $primary-color;
  }
  .button-primary {
    color: $light-color;
    font-size: 20px;
    font-weight: 700;
    line-height: calc(24 / 20);
    border-radius: 3px;
    text-decoration: none;
    padding: 12px;
    background-color: $primary-color;
    width: fit-content;
    border: none;
    transition: opacity 0.2s linear;
    &:hover {
      opacity: 0.8;
    }
  }
  .message {
    font-weight: 400;
    font-size: 16px;
    line-height: calc(19.5 / 16);
    padding: 22px;
    border-radius: 5px;
    border: 1px solid $primary-color;
    background-color: #fff4ef;
  }
  &-form-group {
    label {
      margin-bottom: 12px;
      font-weight: 600;
      font-size: 16px;
      line-height: calc(19 / 16);
      span {
        color: #ff0000;
      }
    }
  }
  // Payment Info Section
  &-info {
    &-course {
      margin-top: 24px;
      border: 1.8px solid $primary-color;
      border-radius: 10px;
      padding: 16px 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 20px;
      background-color: $light-color;
      @include maxWidth(599px) {
        flex-direction: column;
      }
      .stati-quantity {
        display: flex;
        gap: 12px;
        button {
          @include size(40px);
          background-color: #f9f9f9;
          border-radius: 5px;
          display: flex;
          align-items: center;
          justify-content: center;
          outline: none;
          border: none;
          &:active {
            background-color: #f7e8e8;
          }
        }
      }
      .quantity-value {
        border-radius: 5px;
        border: 1px solid rgb(0 0 0 / 30%);
        font-weight: 600;
        font-size: 20px;
        line-height: 24.2px;
        width: 55px;
        height: 40px;
        text-align: center;
        &::-webkit-inner-spin-button,
        &::-webkit-outer-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
        &[type="number"] {
          -moz-appearance: textfield;
        }
      }
    }
    gap: 24px;
    &-image {
      @include size(100px);
      border-radius: 5px;
      span {
        font-weight: 400;
        font-size: 16px;
        line-height: calc(19 / 16);
      }
    }
    &-content {
      display: flex;
      flex-direction: column;
      gap: 10px;
      h3 {
        font-weight: 600;
        font-size: 16px;
        line-height: calc(19 / 16);
      }
      p {
        font-weight: 400;
        font-size: 14px;
        line-height: calc(16 / 14);
        color: #222222;
        margin-top: auto;
      }
      @include maxWidth(599px) {
        p {
          margin-top: unset;
        }
      }
    }
  }
  // Payment Method Section
  &-method {
    .custom-modal-width {
      max-width: 800px;
    }
    .customer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 20px;
      button {
        background-color: transparent;
        border: none;
        font-weight: 500;
        font-size: 24px;
        line-height: calc(29.5 / 24);
        color: #222222;
        transition: color 0.2s linear;
        text-decoration-line: underline;
        &:hover {
          color: $primary-color;
        }
      }
      @include maxWidth(599px) {
        flex-direction: column;
      }
    }
    .terms {
      padding: 24px;
      margin-top: 32px;
      border: 1.5px solid $primary-color;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 15px;
      &-price {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;
        b {
          color: $primary-color;
          font-weight: 700;
          font-size: 24px;
          line-height: calc(29.5 / 24);
        }
        a {
          text-decoration: none;
          background-color: $primary-color;
          border-radius: 3px;
          padding: 12px;
          font-weight: 700;
          font-size: 20px;
          color: $light-color;
          line-height: calc(24.2 / 20);
          text-align: center;
          transition: opacity 0.2s linear;
          &:hover {
            opacity: 0.8;
          }
        }
      }
    }
    .timer {
      font-weight: 500;
      font-size: 16px;
      line-height: calc(19.36 / 16);
      margin-bottom: 20px;
      color: inherit;
      b {
        font-weight: bold;
        font-size: 24px;
        line-height: calc(29.5 / 24);
        color: $primary-color;
      }
    }
    .payment-select {
      border-radius: 5px;
      padding: 16px;
      border: 1px solid rgb(0 0 0 / 50%);
      font-weight: 400;
      font-size: 16px;
      line-height: calc(19.36 / 16);
      background-color: $light-color;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 20px;
      input,
      label {
        cursor: pointer;
      }
    }
    .payment-detail {
      display: none;
      padding: 24px;
      border-radius: 5px;
      border: 1px solid #ccc;
      width: 100%;
      h4 {
        font-weight: 500;
        font-size: 16px;
        line-height: calc(19.36 / 16);
        color: #222222;
      }
      ul {
        margin-top: 15px;
        padding-left: 25px;
        list-style-type: none;
        li {
          font-weight: 400;
          font-size: 16px;
          line-height: calc(19.36 / 16);
          color: #222222;
          &:not(:last-child) {
            margin-bottom: 12px;
          }
        }
      }

      .qr-image {
        max-width: 255px;
        height: 186px;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
  &-success {
    h1 {
      color: #222222;
      font-weight: 600;
      font-size: clamp(1.563rem, 0.236rem + 3.538vw, 2.5rem);
      line-height: 48.41px;
    }
    .order-detail {
      &-title {
        margin-bottom: 16px;
        color: $primary-color;
        font-weight: 500;
        font-size: 24px;
        line-height: 29.5px;
      }
      &-list {
        display: flex;
        flex-direction: column;
        gap: 16px;
        padding-bottom: 16px;
        margin-bottom: 16px;
        border-bottom: 1px solid rgb(108, 117, 124, 0.2);
      }
      &-item {
        display: grid;
        grid-template-columns: auto 1fr;
        gap: 20px;
        span {
          font-weight: 500;
          font-size: 16px;
          line-height: 19.36px;
          color: #222222;
          &:first-child {
            justify-self: start;
            text-align: left;
          }
          &:last-child {
            justify-self: end;
            text-align: right;
          }
        }
      }
    }
  }
}
