.card {
  &-image {
    height: 0;
    position: relative;
    padding-bottom: 92.35%;
    border-radius: 6px 6px 0 0;
    overflow: hidden;
    @media (hover: hover) {
      &:hover img {
        transform: scale(1.2);
        opacity: 0.8;
      }
    }
    img {
      transition: all 0.8s cubic-bezier(0.075, 0.82, 0.165, 1);
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    &-label {
      position: absolute;
      top: 0;
      left: 0;
      margin: 24px 0 0 8px;
      padding: 8px;
      background-color: $primary-color;
      text-transform: capitalize;
      font-size: 12px;
      line-height: 1.33;
      color: $light-color;
      font-weight: 400;
      border-radius: 6px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  &-content {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 24px 20px;
  }
  &-price {
    font-size: 20px;
    color: $primary-color;
    b {
      text-transform: uppercase;
    }
  }
  &-heading a {
    font-size: 14px;
    color: $primary-text;
    font-weight: bold;
    line-height: 1.4;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
    transition: color 0.2s linear;
    &:hover {
      color: $primary-color;
    }
  }
  &-info {
    display: flex;
    flex-direction: column;
    gap: 8px;
    p {
      display: flex;
      justify-content: space-between;
      gap: 10px;
      span {
        font-family: Open Sans;
        font-size: 12px;
        color: $primary-text;
      }
    }
  }
  &-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
    li {
      display: flex;
      align-items: center;
      gap: 12px;
      font-size: 14px;
      color: #4ecb71;
      font-weight: 500;
    }
  }
  &-room {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 8px 12px;
    &-item {
      display: flex;
      gap: 5px;
      img {
        width: 14px !important;
      }
      span {
        font-size: 14px;
        color: #403e3e;
      }
    }
  }
  &-day {
    font-size: 14px;
    color: #b0abab;
    font-weight: 400;
    margin-left: auto;
  }
  &-detail {
    font-size: 16px;
    line-height: 1.5;
    color: $primary-color;
    font-weight: 500;
    text-decoration: underline;
    width: fit-content;
  }
  @include maxWidth(1023px) {
    &-image {
      padding-bottom: 68.35%;
    }
  }
  @include maxWidth(767px) {
    &-content {
      padding: 18px;
    }
    &-image {
      padding-bottom: 62.35%;
    }
  }
}
