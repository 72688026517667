.stats-section {
  .stats {
    padding: 45px 0;
    background-color: #fcf6f2;
    text-align: center;
    &-caption {
      font-size: clamp(1.25rem, 1.073rem + 0.668vw, 1.5rem);
      line-height: 0.92;
      color: $primary-color;
      font-weight: 700;
      margin-bottom: clamp(0.938rem, 0.272rem + 2.504vw, 1.875rem);
      text-transform: uppercase;
    }
    &-description {
      font-size: clamp(1rem, 0.823rem + 0.668vw, 1.25rem);
      line-height: 1.1;
      color: $primary-text;
      font-weight: bold;
      line-height: 1.7;
    }
    &-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 16px;
      &-title {
        font-size: 20px;
        line-height: 1.1;
        color: $primary-text;
        font-weight: bold;
      }
      &-desc {
        font-size: 14px;
        line-height: 1.57;
        color: $primary-text;
        font-weight: 400;
      }
    }
  }
}

.experiences-section {
  .experiences {
    &-caption {
      font-size: clamp(1.25rem, 1.073rem + 0.668vw, 1.5rem);
      line-height: 0.92;
      color: $primary-color;
      font-weight: 700;
    }
    &-item {
      border-radius: 6px;
      padding: 24px;
      background-color: #f9f9f9;
      &-caption {
        font-family: Inter;
        font-size: 14px;
        color: $primary-text;
        font-weight: bold;
        padding-bottom: 15px;
        margin-bottom: 15px;
        border-bottom: 1px solid $primary-color;
      }
    }
    &-content {
      display: flex;
      align-items: center;
      gap: 16px;
      &-image {
        border-radius: 6px;
        overflow: hidden;
        max-width: 73px;
      }
      &-main {
        display: flex;
        flex-direction: column;
        gap: 8px;
        h4 a {
          font-size: 14px;
          color: $primary-text;
          font-weight: 500;
          display: inline-block;
          transition: opacity 0.2s ease;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          word-break: break-word;
          &:hover {
            opacity: 0.8;
          }
        }
        p {
          font-size: 12.5px;
          color: $primary-color;
          font-weight: 400;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          word-break: break-word;
        }
      }
    }
  }
}

.card-top-tour {
  position: relative;
  border-radius: 6px;
  height: 300px;
  overflow: hidden;
  &-image {
    @include size(100%);
    object-fit: cover;
  }
  &-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 24px;
    color: $light-color;
    display: flex;
    flex-direction: column;
    gap: 10px;
    background-color: rgba(0, 0, 0, 0.3);
  }
  &-title a {
    font-size: clamp(1.5rem, 0.968rem + 2.003vw, 2.25rem);
    text-transform: uppercase;
    position: relative;
    color: $light-color;
    display: inline-block;
    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      height: 1px;
      width: 52%;
      background-color: $light-color;
    }
  }
  &-count {
    font-size: 24px;
  }
}

.tour-detail-calendar {
  display: flex;
  justify-content: space-between;
  gap: 8px;
  margin-bottom: 20px;
  overflow-x: auto;
  width: 100%;
  white-space: nowrap;
  display: flex;
  flex-wrap: nowrap;
  gap: 8px;
  justify-content: space-between;
  padding-bottom: 14px;
  height: 75px;
  &::-webkit-scrollbar {
    height: 6px;
  }
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  &::-webkit-scrollbar-thumb {
    background: $primary-color;
  }
  &-item {
    border: 1.5px solid $primary-color;
    border-radius: 5px;
    padding: 8px;
    display: flex;
    flex-direction: column;
    user-select: none;
    gap: 5px;
    justify-content: center;
    text-align: center;
    width: 100%;
    cursor: pointer;
    &.active {
      background-color: #fff4ef;
      pointer-events: none;
    }
    span {
      font-size: 14px;
      font-weight: 400;
      color: #9a9898;
    }
    b {
      color: $primary-color;
      font-weight: 600;
    }
  }
  .dropdown-guide-select {
    width: 140px;
    flex-shrink: 0;
  }
}

.tour-detail-services {
  .services-item {
    padding: 8px 10px;
    border-radius: 6px;
    border: 1px solid $primary-color;
    cursor: pointer;
    &.active {
      background-color: #fff4ef;
      pointer-events: none;
    }
  }
  .services-item-content {
    display: flex;
    flex-direction: column;
    gap: 4px;
    h4 {
      font-size: 14px;
      font-weight: 600;
      color: $primary-text;
      line-height: 1.57;
    }
    span {
      font-size: 14px;
      font-weight: 400;
      color: #babab9;
      line-height: 1.57;
    }
  }
  .services-control {
    display: none;
    &-item {
      display: flex;
      justify-content: space-between;
      gap: 20px;
      align-items: center;
      &:not(:last-child) {
        border-bottom: 1px dashed $primary-color;
      }
      &-price {
        display: flex;
        flex-direction: column;
        gap: 20px;
        span {
          font-weight: 400;
          font-size: 16px;
          line-height: 19px;
          color: $primary-text;
        }
        b {
          text-transform: uppercase;
          color: $primary-color;
          font-weight: 600;
          line-height: 24px;
          font-size: clamp(1.063rem, 0.83rem + 0.875vw, 1.25rem);
        }
      }
    }
  }
  .filter-room-item-quantity {
    height: 40px;
    input {
      width: 55px;
      height: 40px;
      font-size: 20px;
    }
    button {
      width: 40px;
      height: 100%;
    }
  }
  @include maxWidth(599px) {
    &-price {
      gap: 10px;
    }
    .filter-room-item-quantity {
      width: auto;
      height: 30px;
      input {
        width: 40px;
        height: 100%;
        font-size: 14px;
      }
      button {
        width: 30px;
      }
    }
    b {
      font-size: 18px;
    }
  }
}

.tour-detail {
  &-price {
    font-weight: 700;
    font-size: clamp(1.25rem, 0.94rem + 1.166vw, 1.5rem);
    line-height: 29px;
    color: #ff0000;
  }
  &-cart {
    display: flex;
    gap: 12px;
    max-width: 340px;
    width: 100%;
    &-add,
    &-buy {
      border-radius: 6px;
      border: 1px solid $primary-color;
      padding: 8px 12px;
      font-weight: 700;
      font-size: 14px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      transition: opacity 0.2s ease;
      width: 100%;
      &:hover {
        opacity: 0.8;
      }
    }
    &-add {
      color: $primary-color;
    }
    &-buy {
      color: $light-color;
      background-color: $primary-color;
    }
  }
  @include maxWidth(424px) {
    &-cart {
      flex-direction: column;
    }
  }
}

.tour-detail-services {
  .tag {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: $primary-color;
    border: 1px solid $primary-color;
    border-radius: 5px;
    padding: 8px;
    width: fit-content;
  }
  .line {
    width: 100%;
    height: 1px;
    background-color: rgba(108, 117, 124, 0.2);
    margin: 20px 0;
  }
  .feature {
    font-weight: 400;
    font-size: 14px;
    color: #424242;
    border-radius: 5px;
    padding: 5px;
    width: fit-content;
    background-color: rgb(108, 117, 124, 0.2);
  }
}
