.pagination {
   display: flex;
   justify-content: center;
   align-items: center;
   margin: 24px 0 32px 0;
   gap: 8px;
   a {
     padding: 10px 16px;
     border: 1px solid #ddd;
     border-radius: 5px;
     text-decoration: none;
     color: #333;
     display: inline-block;
     font-size: 14px;
     transition: background-color 0.2s linear;
     &.active {
       background-color: $primary-color;
       color: $light-color;
       border-color: $primary-color;
     }
     @media (hover: hover) {
       &:hover:not(.active) {
         background-color: #f5f5f5;
       }
     }
   }
   @include maxWidth(424px) {
     & a {
       padding: 8px 14px;
       font-size: 12px;
     }
   }
   @include maxWidth(374px) {
     & a {
       padding: 5px 10px;
     }
   }
 }