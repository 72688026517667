.review {
  &-card {
    background-color: $light-color;
    border: 1.5px solid $primary-color;
    border-radius: 12px;
    padding: 20px 30px;
  }
  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    margin-bottom: 12px;
    padding-bottom: 20px;
    border-bottom: 1px solid #dbdbdb;
  }
  &-info {
    display: flex;
    align-items: center;
    gap: 15px;
  }
  &-avatar {
    border-radius: 100rem;
    overflow: hidden;
    @include size(56px);
    img {
      @include size(100%);
      object-fit: cover;
    }
  }
  &-name {
    font-size: 16px;
    line-height: 1.5;
    color: #111928;
    font-weight: 400;
  }
  &-date {
    font-size: 14px;
    line-height: 1.57;
    color: #637381;
    font-weight: 400;
  }
  &-rating {
    .rating-value {
      font-size: 16px;
      line-height: 1.5;
      color: #111928;
      font-weight: 500;
      font-weight: bold;
    }
  }
  &-rating {
    display: flex;
    align-items: center;
    gap: 10px;
    &-list {
      display: flex;
      align-items: center;
      gap: 5px;
      color: #f59e0b;
    }
  }
  &-content {
    margin-top: 10px;
    h3 {
      font-size: 20px;
      line-height: 1.3;
      color: #111928;
      font-weight: 500;
    }
    p {
      font-size: 16px;
      line-height: 1.5;
      color: #637381;
      font-weight: 400;
      margin-top: 10px;
    }
  }
  @include maxWidth(599px) {
    &-header {
      flex-direction: column;
    }
    &-card {
      padding: 18px;
    }
    &-content {
      text-align: center;
      p {
        font-size: 14.5px;
      }
    }
  }
}
