#back-to-top {
  --size-button: clamp(3rem, 2.285rem + 1.749vw, 3.125rem);
  position: fixed;
  bottom: clamp(0.688rem, -0.009rem + 2.624vw, 1.25rem);
  right: clamp(0.688rem, -0.009rem + 2.624vw, 1.25rem);
  z-index: 60;
  background-color: $primary-color;
  color: $light-color;
  cursor: pointer;
  @include size(var(--size-button));
  line-height: var(--size-button);
  text-align: center;
  font-size: clamp(1.125rem, 0.97rem + 0.583vw, 1.25rem);
  border-radius: 5px;
  transition: transform 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  display: none;
  &:hover {
    opacity: 0.8;
  }
}
