.travel {
  margin-bottom: 40px;
  &-item {
    height: 0;
    position: relative;
    padding-bottom: 123.55%;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    overflow: hidden;
    @media (hover: hover) and (pointer: fine) {
      &:hover &-bg {
        transform: scale(1.1);
        opacity: 0.8;
      }
    }
    &-bg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: -2;
      transition: transform 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    &-title {
      position: absolute;
      bottom: 0;
      left: 0;
      font-size: 20px;
      color: $light-color;
      font-weight: bold;
      padding: 16px;
      width: 100%;
      text-transform: capitalize;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    }
  }
}
