.filter {
  border-radius: 10px;
  background-color: #ffffff;
  max-width: 980px;
  width: 100%;
  padding: 25px 20px;
  border: 1.5px solid $primary-color;
  z-index: 15;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  &-tab {
    display: flex;
    align-items: center;
    text-align: center;
    gap: 15px;
    overflow-x: auto;
    white-space: nowrap;
    scrollbar-width: thin;
    scrollbar-color: #ccc9c9 #e0e0e0;
    margin-bottom: 10px;
    padding-bottom: 14px;
    &-item {
      padding: 0 15px;
      cursor: pointer;
      transition: color 0.2s linear;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 8px;
      position: relative;
      padding-bottom: 8px;
      user-select: none;
      &.active {
        pointer-events: none;
        &::after {
          position: absolute;
          bottom: 0;
          left: 0;
          content: "";
          width: 100%;
          height: 2px;
          background-color: $primary-color;
          transition: width 0.2s linear;
        }
        span {
          color: $primary-color;
        }
      }
      img {
        @include size(25px);
      }
      span {
        font-size: 14px;
        line-height: 1.69;
        color: #1a2b47;
        font-weight: 500;
      }
    }
  }
  &-pane {
    display: none;
    &.active {
      display: block;
    }
  }
  &-select,
  &-box {
    background-color: #f5f5f5;
    padding: 8.5px 16px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 4px;
    span,
    .daterange-dichVu,
    .daterange-tourDuLich,
    .daterange-suKien {
      font-size: 14px;
      line-height: 1.57;
      color: $primary-color;
      font-weight: 400;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      word-break: break-word;
      border: none;
      outline: none;
      background-color: transparent;
    }
  }
  &-select {
    position: relative;
    cursor: pointer;
    user-select: none;
  }
  &-search {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    border-radius: 5px;
    padding: 8.5px 10px;
    background-color: $primary-color;
    color: $light-color;
    font-size: 20px;
    line-height: 1.1;
    width: 100%;
    transition: background-color 0.2s linear;
    @media (hover: hover) {
      &:hover {
        background-color: #f19866;
      }
    }
  }
  &-dropdown,
  &-room {
    position: absolute;
    top: calc(100% + 6px);
    left: 0;
    width: 100%;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border: 1.8px solid $primary-color;
    background-color: $light-color;
    border-radius: 5px;
    overflow: hidden;
    z-index: 20;
    display: none;
  }
  &-dropdown {
    &.active {
      display: block;
    }
    input {
      width: 100%;
      padding: 8.5px 16px;
      border: none;
      outline: none;
      font-family: Open Sans;
      font-size: 16px;
      color: $primary-text;
      border-bottom: 1px solid #ccc;
    }
    ul {
      li {
        font-size: 16px;
        line-height: 1.5;
        color: $primary-text;
        padding: 8.5px 16px;
        text-transform: capitalize;
        transition: all 0.2s ease;
        width: 100%;
        &:not(:first-child) {
          border-top: 1px solid rgb(108 117 124 / 20%);
        }
        &:hover {
          background-color: $primary-color;
          color: $light-color;
        }
      }
    }
    &-main {
      max-height: 210px;
      overflow-y: auto;
      overscroll-behavior: none;
      &::-webkit-scrollbar {
        width: 8px;
      }
      &::-webkit-scrollbar-track {
        border-radius: 8px;
        background: rgb(108 117 124 / 20%);
      }
      &::-webkit-scrollbar-thumb {
        background-color: $primary-color;
        border-radius: 8px;
      }
    }
  }
  &-room {
    flex-direction: column;
    padding: 15px 24px;
    gap: 24px;
    user-select: none;
    cursor: auto;
    &.active {
      display: flex;
    }
    &-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 10px;
      &-quantity {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 10px;
        height: 25px;
        button {
          border-radius: 5px;
          background-color: #f9f9f9;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 25px;
          height: 100%;
          user-select: none;
          img {
            width: 12px;
          }
          &:active {
            opacity: 0.6;
          }
        }
        input {
          width: 30px;
          text-align: center;
          height: 100%;
          border-radius: 5px;
          border: 1px solid rgb(0 0 0 / 30%);
          outline-color: #ff6a00;
          font-size: 14px;
        }
      }
    }
  }
  &-box {
    input {
      @include size(100%);
      font-family: inherit;
      border: none;
      outline: none;
      background-color: transparent;
      font-size: 16px;
      line-height: 1.57;
      &::placeholder {
        color: #d9d9d9;
      }
    }
  }
  @include maxWidth(767px) {
    border-width: 2px;
    position: unset;
    transform: unset;
    padding: 20px 12px 30px 12px;
    margin-bottom: 30px;
    &-search {
      padding-block: 11px;
      font-size: 18px;
    }
  }
}
