.banner-promo {
  margin-top: 36px;
  &-bg {
    padding: 24px;
    border-radius: 10px;
    color: $light-color;
    @include background-image("../images/subscribe-bg.png", "../../../../assets/images/subscribe-bg.png");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  h1 {
    font-size: clamp(1.25rem, 0.807rem + 1.669vw, 1.875rem);
    font-weight: 700;
  }
  &-button {
    font-family: Inter;
    font-size: 16px;
    line-height: 1.5;
    color: $primary-color;
    font-weight: 400;
    border-radius: 4px;
    background-color: $light-color;
    padding: 6.5px 15px;
    transition: all 0.2s ease;
    &:hover {
      background-color: #f7c2a3;
      color: $light-color;
    }
  }
  p {
    font-size: 20px;
    a {
      color: $light-color;
      transition: color 0.2s ease;
      &:hover {
        color: #f7c2a3;
      }
    }
  }
}
