.we-guide {
  &-card {
    border-radius: 5px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    &,
    &-image {
      overflow: hidden;
    }
    &-image {
      height: 238px;
      img {
        @include size(100%);
        object-fit: cover;
        transition: all 0.2s ease;
      }
      @media (hover: hover) {
        &:hover img {
          transform: scale(1.1);
          opacity: 0.8;
        }
      }
    }
    &-content {
      padding: 20px 16px;
    }
    &-title {
      font-size: 20px;
      color: $primary-color;
      font-weight: 700;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      word-break: break-word;
    }
    &-label {
      font-size: 14px;
      color: #000;
      text-transform: capitalize;
      border-radius: 5px;
      width: fit-content;
      padding: 5px 8px;
      background-color: #e2dbd8;
      color: rgba(0, 0, 0, 0.5);
      font-weight: 500;
      margin: 8px 0;
      display: inline-block;
    }
    &-button {
      font-size: 16px;
      line-height: 1.5;
      color: $light-color;
      font-weight: 400;
      background-color: $primary-color;
      border-radius: 5px;
      padding: 8px 15px;
      background-color: $primary-color;
      display: inline-block;
      text-align: center;
      width: 100%;
      transition: opacity 0.2s ease;
      margin-top: 8px;
      &.readmore {
        max-width: 270px;
        margin: 24px auto 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
      }
      &:hover {
        opacity: 0.8;
      }
    }
  }
}
