.tab {
  margin-bottom: 40px;
  &-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    margin-bottom: 24px;
  }
  &-list {
    width: 62%;
    display: flex !important;
    text-align: center;
    flex-wrap: nowrap;
    .owl-nav {
      button {
        margin-top: unset !important;
        margin: unset !important;
      }
      .disabled {
        display: none !important;
      }
    }
    &-item {
      padding: 8px 16px;
      display: inline-block;
      border-radius: 100rem;
      border: 1px solid transparent;
      line-height: calc(22 / 14);
      font-size: 14px;
      color: $primary-text;
      font-weight: 600;
      text-transform: capitalize;
      transition: color 0.2s linear;
      user-select: none;
      white-space: nowrap;
      &:hover:not(.active) {
        color: $primary-color;
      }
      &.active {
        pointer-events: none;
        color: $primary-color;
        border-color: $primary-color;
      }
    }
  }
  &-pane {
    display: none;
    &.active {
      display: block;
    }
  }
  @include maxWidth(1023px) {
    &-wrapper {
      flex-direction: column-reverse;
      margin-bottom: 20px;
      gap: 15px;
    }
    &-list {
      gap: 5px 2px;
      width: 80%;
      justify-content: center;
    }
  }
  @include maxWidth(767px) {
    &-list {
      width: 100%;
    }
  }
}
