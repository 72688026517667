.info-hotel {
  position: fixed;
  top: 0;
  right: 0;
  height: 94vh;
  z-index: 200;
  max-width: 326px;
  max-height: 858px;
  display: none;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 15px;
  padding: 24px 20px;
  border: 2px solid $primary-color;
  background-color: $light-color;
  animation: roll-in-right 0.6s ease-out both;
  &.show {
    display: block;
  }
  @keyframes roll-in-right {
    0% {
      transform: translateX(-980px) rotate(540deg);
      opacity: 0;
    }
    100% {
      transform: translateX(0) rotate(0deg);
      opacity: 1;
    }
  }
  &-wrapper {
    height: 100%;
    overscroll-behavior: none;
    margin-right: -0.7rem;
    padding-right: 0.5rem;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 5px;
    }
    &::-webkit-scrollbar-track {
      border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: $primary-color;
      border-radius: 10px;
    }
  }
  h1 {
    font-size: 18px;
    color: $primary-text;
    font-weight: bold;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
    margin: 12px 0;
    text-transform: capitalize;
  }
  &-list {
    margin: 16px 0;
    display: flex;
    flex-direction: column;
    gap: 16px;
    border-bottom: 1px solid #ccc;
    padding-bottom: 12px;
    h4 {
      font-size: 16px;
      color: $primary-text;
      font-weight: bold;
    }
    ul {
      display: flex;
      flex-direction: column;
      gap: 12px;
      li {
        display: flex;
        gap: 12px;
        font-size: 14px;
        color: $primary-text;
      }
    }
  }
  &-footer {
    display: flex;
    flex-direction: column;
    gap: 10px;
    span {
      font-size: 14px;
      color: $primary-text;
    }
    b {
      color: $primary-color;
      font-size: 24px;
      font-weight: 700;
      text-transform: uppercase;
    }
    a {
      display: inline-flex;
      padding: 8px;
      width: 100%;
      border-radius: 100rem;
      border: 1px solid $primary-color;
      align-items: center;
      justify-content: center;
      font-weight: bold;
      color: $primary-color;
      transition: all 0.2s ease;
      &:hover {
        background-color: $primary-color;
        color: $light-color;
      }
    }
  }
}
