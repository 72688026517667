.featured {
  &-item {
    border-radius: 6px;
    overflow: hidden;
    background-color: #fff4ef;
  }
  &-image {
    padding-bottom: 54.65%;
    height: 0;
    position: relative;
    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &-avatar {
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    padding-left: 24px;
    img {
      @include size(70px !important);
      border-radius: 50%;
      object-fit: cover;
      &:not(:first-child) {
        margin-left: -42px;
      }
    }
    &-more {
      font-size: 14px;
      margin-left: -11px;
      color: $light-color;
      background-color: $primary-color;
      border-radius: 5px;
      padding: 5px;
      text-transform: capitalize;
    }
  }
  &-content {
    margin-top: -1rem;
    padding: 0 24px 24px 24px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    &-title {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      word-break: break-word;
      font-size: 20px;
      color: $primary-text;
      font-weight: bold;
    }
    &-label {
      font-size: 14px;
      color: $primary-text;
      text-transform: capitalize;
      border-radius: 5px;
      width: fit-content;
      padding: 5px 8px;
      background-color: #e2dbd8;
      color: rgb(0 0 0 / 50%);
      font-weight: 500;
    }
  }
}
