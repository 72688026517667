.rating-ranking {
  margin: 32px 0 24px 0;
  &-header {
    color: $primary-color;
    font-size: 20px;
    font-weight: bold;
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px dashed $primary-text;
  }
  &-subheader {
    font-size: 20px;
    color: $primary-text;
    font-weight: 700;
    margin-bottom: 24px;
  }
  &-wrapper {
    display: flex;
    justify-content: space-between;
    gap: 0 50px;
    align-items: center;
  }
  &-score {
    color: $primary-color;
    font-weight: 700;
    display: flex;
    flex-direction: column;
    gap: 10px 0;
    line-height: normal;
    text-align: center;
    padding: 35px;
    .score {
      font-size: 60px;
    }
    .text {
      font-size: 20px;
    }
  }
  &-details {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px 0;
  }
  &-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .label {
      flex: 0.5;
      font-size: 20px;
      color: $primary-text;
      font-weight: 500;
    }
    .bar {
      flex: 2;
      margin: 0 10px;
      height: 10px;
      background: rgb(217 217 217 / 60%);
      position: relative;
      border-radius: 100rem;
      span {
        border-radius: 100rem;
        display: block;
        height: 100%;
        background: $primary-color;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
    .value {
      flex: 0 0 30px;
      text-align: right;
      font-size: 14px;
      color: $primary-text;
      font-weight: 500;
    }
  }
  @include maxWidth(1200px) {
    &-item .label {
      font-size: 16px;
    }
  }
  @include maxWidth(767px) {
    &-subheader {
      text-align: center;
      margin-bottom: 10px;
    }
    &-wrapper {
      flex-direction: column;
      gap: 15px 0;
    }
    &-score {
      padding: unset;
      .score {
        font-size: 50px;
      }
    }
  }
  @include maxWidth(599px) {
    &-item .label {
      flex: 1;
    }
  }
}
