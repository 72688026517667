.ads {
  margin-bottom: 40px;
  &-image {
    width: 100%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}