.footer {
  margin-top: clamp(2.5rem, -3.125rem + 11.719vw, 4.375rem);
  padding-top: clamp(1.75rem, -3.5rem + 10.938vw, 3.5rem);
  border-top: 1px solid $border-footer;
  &-title {
    font-size: 20px;
    font-weight: 500;
    line-height: 24.2px;
    color: $primary-text;
    text-transform: uppercase;
    width: fit-content;
    &::after {
      content: "";
      display: block;
      height: 1.5px;
      width: 80%;
      background-color: $border-footer;
      margin: 24px 0 34px 0;
    }
  }
  &-line {
    padding-left: 26px;
    border-left: 3px solid $primary-color;
  }
  &-company {
    margin-top: 30px;
    h1 {
      font-size: 20px;
      font-weight: 600;
      line-height: 24.2px;
      color: $primary-color;
      text-transform: uppercase;
      margin-bottom: 14px;
    }
    ul {
      display: flex;
      gap: clamp(0.875rem, -0.25rem + 2.344vw, 1.25rem);
      flex-direction: column;
      li {
        font-size: 18px;
        line-height: calc(20 / 18);
        b {
          color: $primary-text;
          font-weight: 600;
        }
        span {
          color: rgba(0, 0, 0, 0.46);
          font-weight: 400;
        }
        a {
          color: rgba(0, 0, 0, 0.46);
          transition: color 0.2s linear;
          @media (hover: hover) {
            &:hover {
              color: $primary-color;
            }
          }
        }
      }
    }
  }
  &-list {
    display: flex;
    flex-direction: column;
    gap: clamp(0.875rem, -1.75rem + 5.469vw, 1.75rem);
    li a {
      font-size: 16px;
      font-weight: 500;
      line-height: calc(24 / 16);
      color: #403e3e;
      transition: color 0.2s linear;
      @media (hover: hover) {
        &:hover {
          color: $primary-color;
        }
      }
    }
  }
  &-payment {
    h3 {
      font-size: 18px;
      font-weight: 500;
      line-height: calc(24 / 18);
      margin: 18px 0 16px 0;
    }
  }
  &-social {
    margin: 16px 0;
    span {
      font-size: 18px;
      font-weight: 500;
      line-height: calc(24 / 18);
      color: #403e3e;
    }
    ul {
      margin-top: 18px;
      display: flex;
      align-items: center;
      gap: 24px;
    }
  }
  &-copyright {
    border-top: 1.5px solid $border-footer;
    padding: 24px 0 69px 0;
    margin-top: clamp(1.563rem, -3.125rem + 9.766vw, 3.125rem);
    span {
      font-size: clamp(1.125rem, 0.75rem + 0.781vw, 1.25rem);
      font-weight: 400;
      line-height: 24.2px;
      color: $primary-text;
    }
  }
  @include maxWidth(1023px) {
    &-title {
      &::after {
        margin: 10px 0;
      }
    }
    &-company {
      margin-top: 15px;
    }
  }
}
