.category-guide {
  .home-title {
    margin-bottom: unset;
  }
  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    margin-bottom: 24px;
  }
  .controls {
    display: flex;
    gap: 8px;
    button {
      background-color: $primary-color;
      @include size(40px);
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 3px;
      outline: none;
      color: $light-color;
      transition: all 0.2s ease-in;
      &:hover {
        background-color: rgb(255 91 0 / 50%);
        color: $primary-color;
      }
    }
  }
  .card-item {
    position: relative;
    border-radius: 5px;
    overflow: hidden;
    &:before {
      content: "";
      @include blockCenter("left");
      @include size(100%);
      background-color: rgba(0, 0, 0, 0.5);
    }

    &-content {
      @include blockCenter("both");
      max-width: 220px;
      width: 100%;
      h1 a {
        font-size: clamp(1.125rem, 0.919rem + 0.774vw, 1.5rem);
        line-height: 1.33;
        color: $light-color;
        font-weight: bold;
        text-align: center;
        padding-bottom: 16px;
        position: relative;
        text-transform: uppercase;
        display: inline-block;
        &:after {
          content: "";
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
          width: 100px;
          background-color: $primary-color;
          height: 5px;
        }
      }
    }
  }
  @include maxWidth(575px) {
    &-header {
      justify-content: center;
    }
    .controls {
      display: none;
    }
  }
}
