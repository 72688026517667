.banner {
  &-item {
    height: clamp(25rem, 16.587rem + 9.615vw, 28.125rem);
    &-image {
      height: 100%;
      object-fit: cover;
    }
    &-content {
      color: $light-color;
      text-align: center;
      top: 59px;
      max-width: 980px;
      padding: 10px;
      width: 100%;
      @include blockCenter("left");
      h1 {
        font-size: clamp(1.25rem, 0.75rem + 2.5vw, 3.75rem);
        line-height: clamp(1.25rem, 0.75rem + 2.5vw, 3.75rem);
        font-weight: 500;
        text-transform: uppercase;
        margin-bottom: 24px;
      }
      p {
        font-size: clamp(0.875rem, 0.768rem + 0.401vw, 1.25rem);
        font-weight: 400;
        line-height: 24.2px;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-word;
      }
    }
  }
  @include maxWidth(767px) {
    &-item {
      height: clamp(12.5rem, 1.071rem + 57.143vw, 16.25rem);
      &-content {
        @include blockCenter("both");
      }
    }
  }
}
