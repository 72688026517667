.hotel-list {
  &-resortTab {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    gap: clamp(0.313rem, -0.53rem + 3.172vw, 1.5rem);
    max-width: 100%;
    width: 100%;
    overflow-x: auto;
    padding-bottom: 15px;
    &-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 8px;
      padding: 8px 21px;
      cursor: pointer;
      user-select: none;
      font-size: clamp(0.938rem, 0.757rem + 0.77vw, 1.25rem);
      font-weight: bold;
      color: $primary-text;
      &.active {
        pointer-events: none;
        border-bottom: 2px solid $primary-color;
        color: $primary-color;
      }
      img {
         max-width: clamp(2.188rem, 1.522rem + 2.504vw, 3.125rem);
      }
    }
  }
  &-resortPane {
    margin-top: 15px;
    display: none;
    &.active {
      display: block;
    }
  }
}

.top-destinations {
  &-title {
    font-size: 20px;
    color: $primary-color;
    font-weight: 700;
  }
  &-subtitle {
    font-size: 16px;
    color: #909197;
    font-weight: 700;
    margin: 16px 0 30px 0;
  }
  &-item {
    &-image {
      border-radius: 6px;
      overflow: hidden;
      height: 0;
      position: relative;
      padding-bottom: 81.55%;
      @media (hover: hover) {
        &:hover img {
          transform: scale(1.1);
          opacity: 0.8;
        }
      }
    }
    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: all 0.3s ease;
    }
    &-name {
      font-size: 20px;
      color: $primary-text;
      font-weight: 700;
      display: inline-block;
      width: fit-content;
      transition: color 0.2s ease;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      word-break: break-word;
      &:hover {
        color: $primary-color;
      }
    }
    &-info {
      font-size: 16px;
      color: #909197;
      font-weight: 400;
    }
  }
}
