.menu-mobile {
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  max-width: 300px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  z-index: 200;
  overflow-y: auto;
  transition: transform 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
  background-color: $light-color;
  transform: translateX(100%);
  &.active {
    transform: translate(0);
  }
  &-header {
    padding: 18px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    color: $light-color;
    background-color: $primary-color;
    font-size: 15px;
  }
  &-icon {
    font-size: 16.8px;
    border-radius: 100rem;
    border: 1px solid $light-color;
    @include size(42px);
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
  }
  &-auth {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    button {
      color: inherit;
      color: $light-color;
    }
  }
  &-close {
    color: $light-color;
    font-size: 32px;
  }
  &-nav {
    padding: 18px 20px;
    display: flex;
    flex-direction: column;
    & > li > a {
      padding: 12px 0;
    }
    li {
      a {
        display: inline-flex;
        align-items: center;
        justify-content: space-between;
        text-decoration: none;
        color: $primary-color;
        width: 100%;
        font-weight: 600;
        font-size: 15px;
        text-align: left;
      }
    }
    &:not(:last-child) {
      border-bottom: 1.5px solid rgb(204 204 204 / 60%);
    }
    .submenu {
      display: none;
      margin-left: 15px;
      background-color: #f3f3f3;
      li a {
        padding: 15px;
      }
    }
  }
  &-contact {
    padding: 18px 20px;
    li a {
      display: inline-block;
      text-decoration: none;
      color: $primary-color;
      width: 100%;
      font-weight: 600;
      font-size: 15px;
      text-align: left;
      padding: 6.8px;
      &:before {
        content: "-";
        color: $primary-color;
        margin-right: 8px;
      }
    }
  }
  @include maxWidth(1023px) {
    display: block;
  }
}
