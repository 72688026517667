* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
body {
  font-family: "Inter", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  font-size: 14px;
  color: $primary-text;
}
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol,
li,
button,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
  padding: 0;
}
button {
  border: none;
  background-color: transparent;
  cursor: pointer;
}
ul {
  list-style: none;
}
a {
  text-decoration: none;
}
main {
  margin-top: -3.2rem;
  @include maxWidth(767px) {
    margin-top: 0;
  }
}
::selection {
  background-color: $primary-color;
  color: $light-color;
}
.no-scroll {
  overflow-y: hidden;
}
.disabled-click {
  pointer-events: none;
}
.overlay-form,
.overlay-hotel,
.overlay-menu,
.overlay-searchPage,
.overlay-servicesDetail {
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  display: none;
}
.text-color-primary {
  color: $primary-color;
}
.close-btn {
  position: absolute;
  top: 1rem;
  right: 0.8rem;
  cursor: pointer;
  z-index: 85;
  color: $light-color;
  font-size: 18px;
  text-transform: uppercase;
  @include maxWidth(374px) {
    display: none;
  }
}
.logo {
  overflow: hidden;
  position: relative;
  text-decoration: none;
  display: inline-block;
  max-width: clamp(9rem, 4.821rem + 13.393vw, 16.875rem);
  img {
    width: 100%;
    height: auto;
  }
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: -100%;
    @include size(100%);
    background: linear-gradient(
      90deg,
      transparent,
      rgba(255, 255, 255, 0.7),
      transparent
    );
    transform: skewX(-25deg);
    animation: lightRay 5s infinite;
  }
  @keyframes lightRay {
    0% {
      left: -100%;
    }
    100% {
      left: 100%;
    }
  }
  @include maxWidth(767px) {
    max-width: 144px !important;
  }
}
.home-title {
  margin-bottom: 24px;
  color: $primary-color;
  font-size: 20px;
  font-weight: bold;
  text-transform: uppercase;
  @include maxWidth(1023px) {
    text-align: center;
    margin-bottom: 18px;
  }
}
.title {
  font-weight: 700;
  color: $primary-color;
  text-transform: uppercase;
  @include maxWidth(599px) {
    font-size: 18px !important;
  }
}
.read-more {
  font-size: 16px;
  line-height: 1.5;
  color: $primary-color;
  font-weight: bold;
  transition: color 0.2s linear;
  width: fit-content;
  flex-shrink: 0;
  &:hover {
    color: #ca6c3a;
  }
}
.price {
  font-size: 14px;
  font-weight: bold;
  width: fit-content;
  margin-left: auto;
  color: $primary-color;
}
.rating {
  .fa-star {
    font-size: 16px;
  }
  .fa-solid {
    color: #ffc240;
  }
  .fa-regular {
    color: #807a78;
  }
}
.location-info {
  display: flex;
  align-items: center;
  &-item {
    display: flex;
    align-items: center;
    gap: 8px;
    img {
      @include size(18px !important);
    }
    span {
      line-height: 1.57;
      font-size: 14px;
      color: $primary-color;
      font-weight: 400;
    }
  }
  &-separator {
    height: 15px;
    background-color: $primary-color;
    width: 1px;
    margin: 0 10px;
  }
}
.container {
  max-width: 1164px;
  padding: 0 12px;
  margin: 0 auto;
}
.google-map {
  position: relative;
  padding-bottom: 51%;
  height: 0;
  overflow: hidden;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
  }
}
.introDuction {
  background-color: #f9f9f9;
  border-left: 4px solid $primary-color;
  p {
    padding: 24px;
    font-size: clamp(1rem, 0.646rem + 0.943vw, 1.25rem);
    color: $primary-text;
    font-weight: 400;
  }
}
.readmore-button {
  font-size: 16px;
  line-height: 1.5;
  color: $light-color;
  font-weight: 400;
  background-color: $primary-color;
  border-radius: 5px;
  padding: 8px 15px;
  text-align: center;
  transition: opacity 0.2s ease;
  width: 100%;
  max-width: 270px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  @media (hover: hover) {
    &:hover {
      opacity: 0.8;
    }
  }
}
.owl-carousel {
  .owl-dot {
    margin-top: 0.5rem !important;
    &.active span {
      background-color: $primary-color !important;
    }
  }
  .owl-nav button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: $light-color !important;
    @include size(40px);
    border-radius: 100rem !important;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border: none;
    outline: none;
    display: inline-flex !important;
    justify-content: center;
    align-items: center;
    &:active {
      opacity: 0.6;
    }
    &:hover,
    &:hover i {
      transition: all 0.2s ease;
      background-color: $primary-color !important;
      color: $light-color !important;
    }
  }
  .owl-nav .owl-prev {
    left: -55px;
  }
  .owl-nav .owl-next {
    right: -55px;
  }
  .owl-nav i {
    color: #333;
    font-size: 18px;
  }
}
// Custom date picker
.daterangepicker {
  .calendar-table th {
    font-size: 14.5px !important;
    color: $primary-color;
  }
  .drp-buttons .btn {
    padding-block: 5px !important;
    font-size: 14px !important;
  }
  .drp-selected {
    font-size: 15px !important;
    font-weight: bold;
    color: $primary-color;
  }
  .calendar-table td {
    font-size: 15.8px !important;
    width: 35px !important;
    height: 28px !important;
  }
  td.active,
  td.active:hover,
  .ranges li.active,
  .ranges li.active:hover {
    background-color: $primary-color !important;
    color: $light-color !important;
  }
  td.in-range {
    background-color: rgba(254, 91, 0, 0.2) !important;
    color: $primary-text !important;
  }
  .drp-buttons .applyBtn {
    background-color: $primary-color !important;
    border-color: $primary-color !important;
    color: $light-color !important;
  }
  .drp-buttons .applyBtn:hover {
    background-color: #ff9a64 !important;
    color: $light-color !important;
  }
  @media (max-width: 599px) {
    .drp-calendar.left {
      display: block !important;
      float: none !important;
      width: 100% !important;
    }
    .drp-calendar.right {
      display: none !important;
    }
  }
}

.carousel-button-prev,
.carousel-button-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  background-color: $light-color;
  @include size(45px);
  border-radius: 100rem;
  border: none;
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.2s ease;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  &:active {
    opacity: 0.6 !important;
  }
  &:hover {
    background-color: #e7e7e7;
  }
  i {
    font-size: 16.5px;
  }
}
.carousel-button-prev {
  left: 10px;
}
.carousel-button-next {
  right: 10px;
}
/* Custom CSS for thumbnails */
.f-carousel__thumbs {
  --f-thumb-width: 150px !important;
  --f-thumb-height: 90px !important;
}

.f-carousel__slide img {
  height: clamp(15rem, 5.434rem + 40.816vw, 25rem) !important;
}
.line-section {
  border-top: 1.5px solid #d9d9d9;
}
.explore-more {
  &-title {
    font-weight: 700;
    color: $primary-color;
    line-height: 24.2px;
    font-size: 20px;
    margin-top: 30px;
    margin-bottom: 20px;
  }
  &-tag {
    border: 1.5px solid $primary-color;
    border-radius: 5px;
    padding: 8px;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: $primary-text;
    transition: all 0.2s ease;
    &:hover {
      background-color: $primary-color;
      color: $light-color;
    }
  }
}
.readmore-all {
  width: 100%;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  border: 1px solid #383737;
  color: #383737;
  border-radius: 5px;
  padding: 14px 20px;
  text-align: center;
  transition: all 0.2s ease;
  &:hover {
    background-color: $primary-color;
    border-color: $primary-color;
    color: $light-color;
  }
}
.ticket {
  &-list {
    display: flex;
    flex-direction: column;
    gap: 16px;
    .filter-room-item-quantity {
      height: 40px;
      input {
        width: 55px;
        height: 40px;
        font-size: 20px;
      }
      button {
        width: 40px;
        height: 100%;
      }
      @include maxWidth(599px) {
        width: auto;
        height: 30px;
        input {
          width: 40px;
          height: 100%;
          font-size: 14px;
        }
        button {
          width: 30px;
        }
      }
      b {
        font-size: 18px;
      }
    }
  }
  &-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 15px;
    border: 1px solid #ccc;
    border-radius: 6px;
    &-title {
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      color: $primary-text;
    }
    &-price {
      color: $primary-color;
      font-weight: 600;
      font-size: clamp(1.063rem, 0.83rem + 0.875vw, 1.25rem);
      line-height: 24.2px;
    }
    &-desc {
      font-weight: 400;
      font-size: 16px;
      line-height: 19.36px;
      color: #b6b6b6;
    }
  }
}
